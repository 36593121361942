import React, { useEffect, useState } from "react";
import { Col, Row, Typography, MenuProps } from "antd";
import "./../../../styles/staff/profile.css";
import { ButtonProps } from "../../../helpers/constants";
// import useMultiNotification from "../../../hooks/useNotification";
import useAxiosPost from "../../../hooks/useAxiosPost";
import TailSpinLoader from "../../Items/Loaders/TailSpinLoader";
import UpdateDealerPackagePriceModal from "../../Modals/UpdateDealerPackagePriceModal";
import AssignNewPackageToDealerModalComponent from "../../Modals/AssignNewPackageToDealerModal";
import MeshBillButton from "../../Items/Button/Index";
import DealerAssignedPackagesTable from "../../Tables/DealerAssignedPackagesTable";

// const TailSpinLoader = lazy(() => import("../../Items/Loaders/TailSpinLoader"));
// const MeshBillButton = lazy(() => import("../../Items/Button/Index"));
// const DealerAssignedPackagesTable = lazy(
//   () => import("../../Tables/DealerAssignedPackagesTable")
// );
// const AssignNewPackageToDealerModal = lazy(
//   () => import("../../Modals/AssignNewPackageToDealerModal")
// );
// const UpdateDealerPackagePriceModal = lazy(
//   () => import("../../Modals/UpdateDealerPackagePriceModal")
// );

const ViewDealerPackages: React.FC<DealerProfileComponent> = ({
  intPkHeadID,
}) => {
  // const { openNotification, contextHolder } = useMultiNotification();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<SingleDealerProfileApiProp>("dealers/getMyDealerPackages");
  const [packages, setPackages] = useState<DealerAssignedPackage[]>([]);
  const [activeRow, setActiveRow] = useState<DealerAssignedPackage | null>(
    null
  );
  const [showAssign, setShowAssign] = useState<boolean>(false);
  const [showPriceUpdate, setShowUpdatePrice] = useState<boolean>(false);
  useEffect(() => {
    postApiData({ intPkHeadID });
  }, []);

  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setPackages(data || []);
    }
  }, [apiResponse]);
  if (apiLoading) {
    return <TailSpinLoader />;
  }

  const handleClose = (isReload: boolean) => {
    setShowAssign(false);
    setShowUpdatePrice(false);
    setActiveRow(null);
    if (isReload) {
      postApiData({ intPkHeadID });
    }
  };

  const handleUpdatePriceModal = (activePackage: DealerAssignedPackage) => {
    setActiveRow(activePackage);
    setShowUpdatePrice(true);
  };

  return (
    <>
      {/* {contextHolder} */}
      {showPriceUpdate === true && activeRow !== null && (
        <UpdateDealerPackagePriceModal
          show={showPriceUpdate}
          handleClose={handleClose}
          activePackage={activeRow}
        />
      )}
      {showAssign === true && (
        <AssignNewPackageToDealerModalComponent
          show={showAssign}
          handleClose={handleClose}
          intPkHeadID={intPkHeadID}
        />
      )}
      <Row className="full-width-row">
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
            marginTop: "20px",
          }}
          span={24}
        >
          <MeshBillButton
            backgroundColor={ButtonProps.background.STANDARD}
            height={ButtonProps.height.STANDARD}
            width={ButtonProps.width.STANDARD}
            textColor={ButtonProps.color.STANDARD}
            onClick={() => {
              setShowAssign(true);
            }}
            text="Assign Package"
          />
        </Col>
        <Col className="mt-20" span={24}>
          <DealerAssignedPackagesTable
            isLoading={apiLoading}
            packages={packages}
            handleUpdatePriceModal={handleUpdatePriceModal}
          />
        </Col>
      </Row>
    </>
  );
};
export default ViewDealerPackages;
