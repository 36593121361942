import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ViewDealerProfileComponent from "../components/dealers/Single/ViewDealerProfile";

const ViewDealerProfilePage = () => {
  const { setTitle } = useContext(TitleContext);
  useEffect(() => {
    setTitle("Dealer Profile");
  }, [setTitle]);
  return (
    <>
      <ViewDealerProfileComponent />
    </>
  );
};
export default ViewDealerProfilePage;
