import { useEffect, useState } from "react";
import { Row } from "antd";

import { useNavigate, useSearchParams } from "react-router-dom";
import ImportMikrotikSecrets from "./ImportMikrotikSecrets";
import ImportUsersThroughFile from "./ImportUsersThroughFile";
import MeshBillTabs from "../Items/Tabs";

const ImportSecretsComponent = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const _activeTab = searchParams.get("activeTab") || 1;
  const [activeTab, setActiveTab] = useState<number>(Number(_activeTab));
  const [tabs, setTabs] = useState<MeshBillTabItemsProp[]>([
    {
      text: "Through Mikrotik",
      isActive: true,
      id: 1,
    },

    {
      text: "Through File",
      isActive: false,
      id: 2,
    },
  ]);
  const handleTabSwitch = (tabId: number) => {
    navigate(`/import-users?activeTab=${tabId}`);
  };

  useEffect(() => {
    setActiveTab(Number(_activeTab));
    const updatedItems = tabs.map((item) => ({
      ...item,
      isActive: item.id === Number(_activeTab),
    }));
    setTabs(updatedItems);
  }, [activeTab, _activeTab]);

  const showActiveComponent = (activeTab: number) => {
    switch (activeTab) {
      case 1:
        return <ImportMikrotikSecrets />;
      case 2:
        return <ImportUsersThroughFile />;
      default:
        return <ImportMikrotikSecrets />;
    }
  };
  return (
    <>
      <Row className="full-width-row mb-20">
        <MeshBillTabs
          colSpan={12}
          tabs={tabs}
          handleTabSwitch={handleTabSwitch}
        />
      </Row>
      <Row className="full-width-row" gutter={[16, 16]}>
        {showActiveComponent(activeTab)}
      </Row>
    </>
  );
};
export default ImportSecretsComponent;
