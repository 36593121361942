import { useContext, useEffect, lazy } from "react";
import TitleContext from "../context/TitleContext";
import ChangePasswordComponent from "../components/auth/ChangePasswordComponent";
// const ChangePasswordComponent = lazy(
//   () => import("../components/auth/ChangePasswordComponent")
// );
const ChangePasswordPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Change Password");
  }, [setTitle]);
  return (
    <>
      <ChangePasswordComponent />
    </>
  );
};
export default ChangePasswordPage;
