import { useEffect, useState } from "react";
import { Col, Row, Form, Input, DatePicker } from "antd";
import { ButtonProps } from "../../../helpers/constants";
import { FiPlusCircle } from "react-icons/fi";
import moment from "moment";
import dayjs from "dayjs";
import useMultiNotification from "../../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
import useAxiosPost from "../../../hooks/useAxiosPost";
import TailSpinLoader from "../../Items/Loaders/TailSpinLoader";
import CollectOpenInvoiceModalComponent from "../../Modals/CollectOpenInvoiceModal";
import OfferDiscountOnInvoiceModalComponent from "../../Modals/OfferDiscountOnInvoiceModal";
import WaveOfInvoiceModalComponent from "../../Modals/WaveOfInvoiceModal";
import ViewEndUserInvoicesTable from "../../Tables/ViewEndUserInvoicesTable";
// const ViewEndUserInvoicesTable = lazy(
//   () => import("../../Tables/ViewEndUserInvoicesTable")
// );
// const MeshBillSelect = lazy(() => import("../../Items/Select/Index"));
// const MeshBillButton = lazy(() => import("../../Items/Button/Index"));
// const CollectOpenInvoiceModalComponent = lazy(
//   () => import("../../Modals/CollectOpenInvoiceModal")
// );

// const OfferDiscountOnInvoiceModal = lazy(
//   () => import("../../Modals/OfferDiscountOnInvoiceModal")
// );
// const WaveOfInvoiceModal = lazy(
//   () => import("../../Modals/WaveOfInvoiceModal")
// );

const ViewUserInvoicesComponent: React.FC<EndUserProfileComponent> = ({
  intPkEndUserID,
}) => {
  const { openNotification, contextHolder } = useMultiNotification();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<SingleEndUserProfileApiProp>(
    "endUsers/getInvoicesByEndUser"
  );
  const [invoices, setInvoices] = useState<EndUserInvoice[]>([]);
  const [pagination, setPagination] = useState<PaginationProp>({
    totalPages: 0,
    currentPage: 1,
    previousPage: null,
    nextPage: null,
    totalCount: 0,
    pageSize: 50,
  });
  const [activeInvoice, setActiveInvoice] = useState<EndUserInvoice | null>(
    null
  );
  const [showCollectPayment, setShowCollectPayment] = useState<boolean>(false);
  const [showDiscount, setShowDiscount] = useState<boolean>(false);
  const [showWaveOff, setShowWaveOff] = useState<boolean>(false);
  useEffect(() => {
    postApiData({ intPkEndUserID, page: 1 });
  }, []);

  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;

      const {
        currentPage,
        nextPage,
        pageSize,
        previousPage,
        totalCount,
        totalPages,
        invoices,
      } = data;
      setPagination({
        currentPage,
        totalPages,
        nextPage,
        previousPage,
        totalCount,
        pageSize,
      });
      setInvoices(invoices || []);
    }
  }, [apiResponse]);
  if (apiLoading) {
    return <TailSpinLoader />;
  }
  const handlePageSwitch = (pageNumber: number) => {
    postApiData({ page: pageNumber, intPkEndUserID });
  };
  const handleCollectPayment = (activeRow: EndUserInvoice) => {
    if (activeRow.invoiceStatus !== "OPEN") {
      return;
    }
    setActiveInvoice(activeRow);
    setShowCollectPayment(true);
  };
  const handleDiscount = (activeRow: EndUserInvoice) => {
    if (activeRow.invoiceStatus !== "OPEN") {
      return;
    }
    setActiveInvoice(activeRow);
    setShowDiscount(true);
  };
  const handleWaveOff = (activeRow: EndUserInvoice) => {
    if (activeRow.invoiceStatus !== "OPEN") {
      return;
    }
    setActiveInvoice(activeRow);
    setShowWaveOff(true);
  };
  const handleClose = (isReload: boolean) => {
    setActiveInvoice(null);
    setShowCollectPayment(false);
    setShowDiscount(false);
    setShowWaveOff(false);
    if (isReload) {
      postApiData({ intPkEndUserID, page: pagination.currentPage });
    }
  };
  return (
    <>
      {contextHolder}
      {showCollectPayment === true && activeInvoice !== null && (
        <CollectOpenInvoiceModalComponent
          show={showCollectPayment}
          handleClose={handleClose}
          invoice={activeInvoice}
        />
      )}
      {showDiscount === true && activeInvoice !== null && (
        <OfferDiscountOnInvoiceModalComponent
          show={showDiscount}
          handleClose={handleClose}
          invoice={activeInvoice}
        />
      )}
      {showWaveOff === true && activeInvoice !== null && (
        <WaveOfInvoiceModalComponent
          show={showWaveOff}
          handleClose={handleClose}
          invoice={activeInvoice}
        />
      )}
      <Col span={24} style={{ marginTop: "20px" }}>
        {" "}
      </Col>
      <ViewEndUserInvoicesTable
        isLoading={apiLoading}
        handlePageSwitch={handlePageSwitch}
        pagination={pagination}
        invoices={invoices}
        handleCollectPayment={handleCollectPayment}
        handleDiscount={handleDiscount}
        handleWaveOff={handleWaveOff}
      />
    </>
  );
};
export default ViewUserInvoicesComponent;
