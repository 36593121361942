import { useEffect, useState } from "react";
import useAxiosPost from "../../hooks/useAxiosPost";
import { Col, Dropdown, MenuProps, Row, Switch, Tag, Typography } from "antd";
import { ButtonProps } from "../../helpers/constants";
import { FiPlusCircle } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { getUserType } from "../../helpers/userDetails";
import "./../../styles/staff/profile.css";
import { formatDateForJustMonthDayAndYear } from "../../helpers/date";

import { MdContentCopy } from "react-icons/md";
import CopyToClipboard from "react-copy-to-clipboard";
import MeshBillButton from "../Items/Button/Index";
import useMultiNotification from "../../hooks/useNotification";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";
import { MenuOutlined } from "@ant-design/icons";
import UpdateRouterModalComponent from "../Modals/UpdateRouterModal";
import UpdateNasStatusModalComponent from "../Modals/UpdateNasStatusModal";
import UpdateMikroNocProbeModal from "../Modals/UpdateMikroNocProbeModal";
import UpdateRouterRadiusPortsModal from "../Modals/UpdateRouterRadiusPortsModal";

// const UpdateNasStatusModalComponent = lazy(
//   () => import("../Modals/UpdateNasStatusModal")
// );
// const UpdateRouterRadiusPortsModal = lazy(
//   () => import("../Modals/UpdateRouterRadiusPortsModal")
// );

// const UpdateMikroNocProbeModal = lazy(
//   () => import("../Modals/UpdateMikroNocProbeModal")
// );

interface RouterSingleRowSimpleProp {
  label: string;
  value: string;
  isBorderBottom: boolean;
}
const RouterSingleRowSimple: React.FC<RouterSingleRowSimpleProp> = ({
  label,
  value,
  isBorderBottom = true,
}) => {
  return (
    <Col
      span={24}
      className={`profile-card-single-row ${
        isBorderBottom === true ? "border-bottom-1px" : ""
      }`}
    >
      <div className="values-container">
        <Typography className="user-label-primary">{label}</Typography>
        <Typography className="user-value"> {value}</Typography>
      </div>
    </Col>
  );
};
const ViewMyRouterDetailsComponent = () => {
  const { intPkNasDeviceID } = useParams();
  const { openNotification, contextHolder } = useMultiNotification();

  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ActivateRouterApiProp>("routers/getNasDeviceByIdByCompany");

  const [router, setRouter] = useState<SingleRouterDetails | null>(null);

  const role = getUserType();

  useEffect(() => {
    postApiData({
      intPkNasDeviceID: Number(intPkNasDeviceID),
    });
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      console.log({ data });
      setRouter(data || null);
    }
  }, [apiResponse]);

  if (apiLoading) {
    return <TailSpinLoader />;
  }

  const getProbeData = () => {
    if (!router) {
      return {
        intPkMeshBillRadiusProbeID: null,
        childProbeAccountingID: null,
        childProbeAuthID: null,
      };
    }
    let intPkMeshBillRadiusProbeID = null;
    let childProbeAccountingID = null;
    let childProbeAuthID = null;
    const childProbes = router.childPortsData;
    if (childProbes.length < 1) {
      return {
        intPkMeshBillRadiusProbeID,
        childProbeAccountingID,
        childProbeAuthID,
      };
    }
    const acctProbeObj = childProbes.find(
      (item) => item.portType === "ACCOUNTING"
    );
    if (acctProbeObj) {
      childProbeAccountingID = acctProbeObj.intPkMeshBillRadiusProbeChildID;
      intPkMeshBillRadiusProbeID = acctProbeObj.intPkMeshBillRadiusProbeID;
    }
    const authProbeObj = childProbes.find((item) => item.portType === "AUTH");
    if (authProbeObj) {
      childProbeAuthID = authProbeObj.intPkMeshBillRadiusProbeChildID;
      intPkMeshBillRadiusProbeID = authProbeObj.intPkMeshBillRadiusProbeID;
    }
    return {
      intPkMeshBillRadiusProbeID,
      childProbeAccountingID,
      childProbeAuthID,
    };
  };
  return (
    <>
      {contextHolder}
      {router && (
        <>
          <Row gutter={[16, 16]} className="full-width-row">
            <Col lg={12} xl={12} xxl={12} sm={24} md={12}>
              <Row className="staff-profile-card-container">
                <Col
                  span={24}
                  className="staff-details-title-label-container border-bottom-1px"
                >
                  <Typography className="staff-profile-card-title">
                    Router Profile
                  </Typography>
                </Col>
                <RouterSingleRowSimple
                  label="Router Name"
                  value={`${router.nasDeviceName}`}
                  isBorderBottom={true}
                />
                <RouterSingleRowSimple
                  label="Router IP"
                  value={`${router.nasDeviceIP}`}
                  isBorderBottom={true}
                />
                <RouterSingleRowSimple
                  label="Router Api UserName"
                  value={`${
                    router.nasDeviceApiUserName
                      ? router.nasDeviceApiUserName
                      : "---"
                  }`}
                  isBorderBottom={true}
                />
                <RouterSingleRowSimple
                  label="Router API Password"
                  value={`${
                    router.nasDeviceApiPassword
                      ? router.nasDeviceApiPassword
                      : "---"
                  }`}
                  isBorderBottom={true}
                />
                <RouterSingleRowSimple
                  label="Router PORT"
                  value={`${
                    router.nasDeviceApiPort ? router.nasDeviceApiPort : "---"
                  }`}
                  isBorderBottom={true}
                />
                <RouterSingleRowSimple
                  label="COA PORT"
                  value={`${router.coaPort ? router.coaPort : "---"}`}
                  isBorderBottom={true}
                />

                <Col
                  span={24}
                  className={`profile-card-single-row border-bottom-1px`}
                >
                  <div className="values-container">
                    <Typography className="user-label-primary">
                      Authority Reachable
                    </Typography>
                    <Typography className="user-value">
                      {router.authorityStatus === null || false ? (
                        <Tag color="error">Un Reachable</Tag>
                      ) : (
                        <Tag color="success">Reachable</Tag>
                      )}
                    </Typography>
                  </div>
                </Col>

                <Col
                  span={24}
                  className={`profile-card-single-row border-bottom-1px`}
                >
                  <div className="values-container">
                    <Typography className="user-label-primary">
                      COA Enabled?
                    </Typography>
                    <Typography className="user-value">
                      {Number(router.isCoaEnabled) === 1 ? (
                        <Tag color="success">Enable</Tag>
                      ) : (
                        <Tag color="warning">Disabled</Tag>
                      )}
                    </Typography>
                  </div>
                </Col>

                <Col span={24} className={`profile-card-single-row`}>
                  <div className="values-container">
                    <Typography className="user-label-primary">
                      API Reachable?
                    </Typography>
                    <Typography className="user-value">
                      {router.apiStatus === null || false ? (
                        <Tag color="error">Un Reachable</Tag>
                      ) : (
                        <Tag color="success">Reachable</Tag>
                      )}
                    </Typography>
                  </div>
                </Col>

                <Col
                  span={24}
                  className={`profile-card-single-row border-bottom-1px`}
                >
                  <div className="values-container">
                    <Typography className="user-label-primary">
                      API Enabled?
                    </Typography>
                    <Typography className="user-value">
                      {Number(router.isApiEnabled) === 1 ? (
                        <Tag color="success">Enable</Tag>
                      ) : (
                        <Tag color="warning">Disabled</Tag>
                      )}
                    </Typography>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={12} xl={12} xxl={12} sm={24} md={12}>
              <Row className="staff-profile-card-container">
                <RouterSingleRowSimple
                  label="Router Type"
                  value={`${router.nasDeviceType}`}
                  isBorderBottom={true}
                />
                <RouterSingleRowSimple
                  label="MikroNoc Probe"
                  value={`${
                    router.mikroNocProbeIP ? router.mikroNocProbeIP : "---"
                  }`}
                  isBorderBottom={true}
                />
                <RouterSingleRowSimple
                  label="Radius IP"
                  value={`${
                    router.childPortsData.length > 0
                      ? router.childPortsData[0].radiusProbeIP
                      : "---"
                  }`}
                  isBorderBottom={true}
                />
                <RouterSingleRowSimple
                  label="Auth Ports"
                  value={`${
                    router.childPortsData.length > 0
                      ? router.childPortsData
                          .filter((item) => item.portType === "AUTH")
                          .map((item) => {
                            return `${item.port}  `;
                          })
                      : "No Ports Assigned"
                  }`}
                  isBorderBottom={true}
                />

                <RouterSingleRowSimple
                  label="Accounting Port"
                  value={`${
                    router.childPortsData.length > 0
                      ? router.childPortsData
                          .filter((item) => item.portType === "ACCOUNTING")
                          .map((item) => {
                            return `${item.port} `;
                          })
                      : "No Ports Assigned"
                  }`}
                  isBorderBottom={true}
                />

                <RouterSingleRowSimple
                  label="Activation Date"
                  value={`${
                    router.isNasDevicePreTested === 1 &&
                    router.preTestSuccessDate
                      ? formatDateForJustMonthDayAndYear(
                          router.preTestSuccessDate
                        )
                      : "Not Activated"
                  }`}
                  isBorderBottom={true}
                />
                <Col
                  span={24}
                  className={`profile-card-single-row border-bottom-1px`}
                >
                  <div className="values-container">
                    <Typography className="user-label-primary">
                      Router Token
                    </Typography>
                    <Typography className="">
                      <CopyToClipboard
                        text={router.routerToken || ""}
                        onCopy={() => {}}
                      >
                        <MdContentCopy
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                          className="cursor-pointer "
                        />
                      </CopyToClipboard>
                    </Typography>
                  </div>
                </Col>
                <Col span={24} className={`profile-card-single-row `}>
                  <div className="values-container">
                    <Typography className="user-label-primary">
                      Radius Secret
                    </Typography>
                    <Typography className="">
                      <CopyToClipboard
                        text={router.radiusSecret || ""}
                        onCopy={() => {}}
                      >
                        <MdContentCopy
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                          className="cursor-pointer "
                        />
                      </CopyToClipboard>
                    </Typography>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>{" "}
        </>
      )}
    </>
  );
};
export default ViewMyRouterDetailsComponent;
