import {
  Col,
  Dropdown,
  MenuProps,
  Row,
  Typography,
  Switch,
  Button,
} from "antd";
import { MdEdit } from "react-icons/md";

import { formatDateForJustMonthDayAndYear } from "../../helpers/date";
import { MenuOutlined } from "@ant-design/icons";
interface StaffSingleRowSimpleProp {
  label: string;
  value: string;
  isBorderBottom: boolean;
}
const SingleRowSimple: React.FC<StaffSingleRowSimpleProp> = ({
  label,
  value,
  isBorderBottom = true,
}) => {
  return (
    <Col
      span={24}
      className={`profile-card-single-row ${
        isBorderBottom === true ? "border-bottom-1px" : ""
      }`}
    >
      <div className="values-container">
        <Typography className="user-label-primary">{label}</Typography>
        <Typography className="user-value"> {value}</Typography>
      </div>
    </Col>
  );
};
const ViewMyProfileCardProp: React.FC<MyProfileCardProp> = ({ staff }) => {
  return (
    <>
      <Row gutter={[16, 16]} className="full-width-row">
        {/* LEFT CARD */}
        <Col lg={12} xl={12} xxl={12} sm={24} md={12}>
          <Row className="staff-profile-card-container">
            <SingleRowSimple
              label="Full Name"
              value={`${staff.firstName} ${staff.lastName}`}
              isBorderBottom={true}
            />
            <SingleRowSimple
              label="Address"
              value={`${staff.address}`}
              isBorderBottom={true}
            />

            <SingleRowSimple
              label="Created"
              value={formatDateForJustMonthDayAndYear(
                new Date(staff.dateCreated)
              )}
              isBorderBottom={false}
            />
          </Row>
        </Col>
        {/* Right Card */}
        <Col lg={12} xl={12} xxl={12} sm={24} md={12}>
          <Row className="staff-profile-card-container">
            <SingleRowSimple
              label="UserName"
              value={`${staff.staffUserName}`}
              isBorderBottom={true}
            />
            <SingleRowSimple
              label="Email"
              value={`${staff.email ? staff.email : "--"}`}
              isBorderBottom={true}
            />
            <SingleRowSimple
              label="Phone Number"
              value={`${staff.phoneNumber ? staff.phoneNumber : "--"}`}
              isBorderBottom={false}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default ViewMyProfileCardProp;
