import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import "./../../styles/dashboard.css";

import DashboardCardsWrapper from "./cards/DashboardCardsWrapper";
import DashboardStatsWrapper from "./stats/StatsWrapper";

const DashboardComponent = () => {
  return (
    <>
      <DashboardCardsWrapper />
      <Row style={{ width: "100%", marginBottom: "30px" }}></Row>
      <DashboardStatsWrapper />
    </>
  );
};
export default DashboardComponent;
