import React, { useEffect, useState } from "react";

import { MdPersonOff } from "react-icons/md"; //for recent expired
import useAxiosPost from "../../../hooks/useAxiosPost";
import SingleCard from "./SingleCard";

const RecentExpiredUsersCard = ({}) => {
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>(
    "reports/dashboard/getRecentExpiredUsersCountByOwner"
  );
  const [value, setValue] = useState<string>("0");
  useEffect(() => {
    postApiData({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      const recentExpiredUsers = data.recentExpiredUsers;
      console.log({ recentExpiredUsers });
      setValue(recentExpiredUsers);
    }
  }, [apiResponse]);
  return (
    <SingleCard
      label="Recent Expired"
      value={value}
      CardIcon={MdPersonOff}
      iconColor="#ff6347"
      isLoading={apiLoading}
      isLink
      route="/pppoe-users?activeTab=expired"
    />
  );
};

export default RecentExpiredUsersCard;
