import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import UserProfileComponent from "../components/PPPoEUsers/Single/index";
// const UserProfileComponent = lazy(
//   () => import("../components/PPPoEUsers/Single/index")
// );

const PPPoEUserProfilePage = () => {
  const { setTitle } = useContext(TitleContext);
  useEffect(() => {
    setTitle("PPPoE User Profile");
  }, [setTitle]);
  return (
    <>
      <UserProfileComponent />
    </>
  );
};
export default PPPoEUserProfilePage;
