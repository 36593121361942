import { useEffect, useState } from "react";
import useAxiosPost from "../../hooks/useAxiosPost";
import { Col, Row } from "antd";
import { ButtonProps } from "../../helpers/constants";
import { FiPlusCircle } from "react-icons/fi";
import CreateDealerModalComponent from "../Modals/CreateNewDealerModal";
import UpdateStaffDetailsModalComponent from "../Modals/UpdateStaffDetailsModal";
import UpdateStaffPasswordModalComponent from "../Modals/UpdateStaffPasswordModal";
import ViewDealersTable from "../Tables/ViewDealersTable";
import MeshBillButton from "../Items/Button/Index";
// const MeshBillSelect = lazy(() => import("../Items/Select/Index"));
// const MeshBillButton = lazy(() => import("../Items/Button/Index"));
// const ViewDealersTable = lazy(() => import("../Tables/ViewDealersTable"));
// const UpdateStaffDetailsModalComponent = lazy(
//   () => import("../Modals/UpdateStaffDetailsModal")
// );
// const UpdateStaffPasswordModalComponent = lazy(
//   () => import("../Modals/UpdateStaffPasswordModal")
// );
// const CreateNewDealerModalComponent = lazy(
//   () => import("../Modals/CreateNewDealerModal")
// );
const ViewDealersComponent = () => {
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("dealers/getMyDealers");
  const [dealers, setDealers] = useState<Dealer[]>([]);

  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [showUpdate, setShowUpdate] = useState<boolean>(false);
  const [showPasswordUpdate, setShowPasswordUpdate] = useState<boolean>(false);
  const [activeRow, setActiveRow] = useState<Dealer | null>(null);
  useEffect(() => {
    postApiData({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      console.log({ data });
      setDealers(data || []);
    }
  }, [apiResponse]);
  const handleClose = (isReload: boolean) => {
    setShowCreate(false);
    setShowUpdate(false);
    setShowPasswordUpdate(false);
    setActiveRow(null);
    if (isReload) {
      postApiData({});
    }
  };
  const handleStaffProfileUpdate = (activeStaff: Dealer) => {
    setActiveRow(activeStaff);
    setShowUpdate(true);
  };
  const handleStaffPasswordUpdate = (activeStaff: Dealer) => {
    setActiveRow(activeStaff);
    setShowPasswordUpdate(true);
  };

  return (
    <>
      {showCreate === true && (
        <CreateDealerModalComponent
          show={showCreate}
          handleClose={handleClose}
        />
      )}
      {showUpdate === true && activeRow !== null && (
        <UpdateStaffDetailsModalComponent
          activeStaff={activeRow}
          show={showUpdate}
          handleClose={handleClose}
          apiUrl="updateMyStaffProfile"
        />
      )}
      {showPasswordUpdate === true && activeRow !== null && (
        <UpdateStaffPasswordModalComponent
          activeStaff={activeRow}
          show={showPasswordUpdate}
          handleClose={handleClose}
          apiUrl={null}
        />
      )}
      <Row className="full-width-row">
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
          }}
          lg={24}
          xl={24}
          xxl={24}
          md={12}
          sm={24}
          span={24}
        >
          <MeshBillButton
            backgroundColor={ButtonProps.background.STANDARD}
            height={ButtonProps.height.STANDARD}
            width={ButtonProps.width.STANDARD}
            textColor={ButtonProps.color.STANDARD}
            onClick={() => {
              setShowCreate(true);
            }}
            text="Create Dealer"
            icon={<FiPlusCircle />}
          />
        </Col>
        <Col span={24}>
          <ViewDealersTable
            handleStaffProfileUpdate={handleStaffProfileUpdate}
            handleStaffPasswordUpdate={handleStaffPasswordUpdate}
            isLoading={apiLoading}
            dealers={dealers}
          />
        </Col>
      </Row>
    </>
  );
};
export default ViewDealersComponent;
