import { useContext, useEffect, lazy } from "react";
import TitleContext from "../context/TitleContext";
import DealerPaymentsReport from "../components/Reports/DealerPaymentsReport";
// const DealerPaymentsReport = lazy(
//   () => import("../components/Reports/DealerPaymentsReport")
// );
const DealerPaymentsReportPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Dealer Payment Reports");
  }, [setTitle]);
  return (
    <>
      <DealerPaymentsReport />
    </>
  );
};
export default DealerPaymentsReportPage;
