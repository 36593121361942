import { useEffect, useState } from "react";
import { Col, Row, Form, Input, DatePicker } from "antd";
import { ButtonProps } from "../../../helpers/constants";
import { FiPlusCircle } from "react-icons/fi";
import moment from "moment";
import dayjs from "dayjs";
import useMultiNotification from "../../../hooks/useNotification";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useAxiosPost from "../../../hooks/useAxiosPost";
import ViewDealerProfile from "./DealerProfile";
import ViewDealerPackages from "./DealerPackages";
import ViewDealerTransactions from "./DealerTransactions";
import MeshBillTabs from "../../Items/Tabs";

// const DealerProfileComponent = lazy(() => import("./DealerProfile"));
// const DealerPackagesComponent = lazy(() => import("./DealerPackages"));
// const DealerTransactionsComponent = lazy(() => import("./DealerTransactions"));
const ViewDealerProfileComponent = () => {
  const navigate = useNavigate();
  const { intPkHeadID } = useParams();
  const [searchParams] = useSearchParams();
  const _activeTab = searchParams.get("activeTab");
  const fullName = searchParams.get("fullName");
  const { openNotification, contextHolder } = useMultiNotification();

  const [activeTab, setActiveTab] = useState<number>(Number(_activeTab));
  const [tabs, setTabs] = useState<MeshBillTabItemsProp[]>([
    {
      text: "Profile",
      isActive: true,
      id: 1,
    },

    {
      text: "Packages",
      isActive: false,
      id: 2,
    },
    {
      text: "Transactions",
      isActive: false,
      id: 3,
    },
  ]);

  const handleTabSwitch = (tabId: number) => {
    navigate(
      `/dealer-profile/${intPkHeadID}?activeTab=${tabId}&fullName=${fullName}`
    );
  };

  useEffect(() => {
    setActiveTab(Number(_activeTab));
    const updatedItems = tabs.map((item) => ({
      ...item,
      isActive: item.id === Number(_activeTab),
    }));
    setTabs(updatedItems);
  }, [activeTab, _activeTab]);
  const showActiveComponent = (activeTab: number) => {
    switch (activeTab) {
      case 1:
        return <ViewDealerProfile intPkHeadID={Number(intPkHeadID)} />;
      case 2:
        return <ViewDealerPackages intPkHeadID={Number(intPkHeadID)} />;
      case 3:
        return <ViewDealerTransactions intPkHeadID={Number(intPkHeadID)} />;
      default:
        return <ViewDealerProfile intPkHeadID={Number(intPkHeadID)} />;
    }
  };
  return (
    <>
      {contextHolder}
      <Row className="full-width-row">
        <MeshBillTabs
          colSpan={8}
          tabs={tabs}
          handleTabSwitch={handleTabSwitch}
        />
      </Row>
      <Row className="full-width-row" gutter={[16, 16]}>
        {showActiveComponent(activeTab)}
      </Row>
    </>
  );
};
export default ViewDealerProfileComponent;
