import { useContext, useEffect, lazy } from "react";
import TitleContext from "../context/TitleContext";
import DealerLedgerReport from "../components/Reports/DealerLedgerReport";
// const DealerLedgerReport = lazy(
//   () => import("../components/Reports/DealerLedgerReport")
// );
const DealerLedgerReportPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Dealer Ledger Report");
  }, [setTitle]);
  return (
    <>
      <DealerLedgerReport />
    </>
  );
};
export default DealerLedgerReportPage;
