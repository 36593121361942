import React, { useEffect, useState, useCallback } from "react";
import { Button, Col, Row, Table, Typography, Input, Checkbox } from "antd";
import MeshBillSelect from "../Items/Select/Index";
import MeshBillButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import useMultiNotification from "../../hooks/useNotification";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";
import { VariableSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

// Custom row renderer for react-window
const RowRenderer: React.FC<any> = ({ index, style, data }) => {
  let _data = data.data;
  const row = _data[index];
  // console.log({ data, row });
  const handleInputChange = data.handleInputChange;

  return (
    <div style={style}>
      <Row gutter={[16, 16]}>
        <Col span={2}>
          <Checkbox
            checked={row.isReadyToCreate}
            onChange={(e: any) => {
              handleInputChange(e.target.checked, "isReadyToCreate", index);
            }}
          >
            <span className="create-user-form-label">Select</span>
          </Checkbox>
        </Col>
        <Col span={3}>
          <Typography>{`${row.username}`}</Typography>
          {row.isAlreadyCreated && (
            <Typography style={{ color: "red", textDecoration: "none" }}>
              Already Created
            </Typography>
          )}
        </Col>
        <Col span={3}>
          <span>{`${row.password}`}</span>
        </Col>
        <Col span={3}>
          <Input
            style={{ height: "35px" }}
            disabled={row.isAlreadyCreated || !row.isReadyToCreate}
            value={row.firstName || ""}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.value, "firstName", index)
            }
          />
        </Col>
        <Col span={3}>
          <Input
            style={{ height: "35px" }}
            disabled={row.isAlreadyCreated || !row.isReadyToCreate}
            value={row.lastName || ""}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.value, "lastName", index)
            }
          />
        </Col>
        <Col span={3}>
          <Input
            style={{ height: "35px" }}
            disabled={row.isAlreadyCreated || !row.isReadyToCreate}
            value={row.address || ""}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.value, "address", index)
            }
          />
        </Col>
        <Col span={3}>
          <Input
            style={{ height: "35px" }}
            disabled={row.isAlreadyCreated || !row.isReadyToCreate}
            value={row.phoneNumber || ""}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.value, "phoneNumber", index)
            }
          />
        </Col>
        <Col span={4}>
          <MeshBillSelect
            disabled={row.isAlreadyCreated || !row.isReadyToCreate}
            size="large"
            options={data.packageOptions}
            value={row.intPkPackagePriceID}
            height="35px"
            onChange={(e) => handleInputChange(e, "intPkPackagePriceID", index)}
          />
        </Col>
        {/* <Col span={2}>
          <Button
            style={{ height: "35px" }}
            type="primary"
            danger
            onClick={() => data.handleUserSkip(row.username)}
          >
            Skip
          </Button>
        </Col> */}
      </Row>
    </div>
  );
};

const ImportSecretsTable: React.FC<ImportSecretsTableProp> = ({
  secrets,
  isLoading,
  packageOptions,
  handleUploadUsers,
  isUploading,
}) => {
  const { openNotification, contextHolder } = useMultiNotification();
  const [data, setData] = useState<SecretFromRouter[]>([]);

  useEffect(() => {
    setData(secrets);
  }, [secrets]);

  const handleInputChange = useCallback(
    (value: string, key: string, index: number) => {
      setData((prevData) =>
        prevData.map((item, i) =>
          i === index ? { ...item, [key]: value } : item
        )
      );
    },
    []
  );
  const validateData = (data: SecretFromRouter[]) => {
    return data.filter((row) => {
      // Skip rows where `isAlreadyCreated` is true
      if (row.isAlreadyCreated) return true;
      if (!row.isReadyToCreate) return true;

      // Check if all required fields are filled
      return (
        row.username &&
        row.password &&
        row.firstName &&
        row.lastName &&
        row.address &&
        row.phoneNumber &&
        row.intPkPackagePriceID
      );
    });
  };
  const handleUserSkip = useCallback((username: string) => {
    setData((prevData) =>
      prevData.filter((user) => user.username !== username)
    );
  }, []);

  const handleUpload = () => {
    console.log({ data });
    // Validate data and handle upload
    // Implement the upload logic here
    const validData = validateData(data);
    console.log({ validData, data });
    if (validData.length < data.length) {
      // Notify user about incomplete rows
      openNotification(
        `error`,
        "Operation Failed",
        "Please fill in all required fields for each user."
      );
    } else {
      // Proceed with upload
      const dataToUpload = validData.filter((item) => item.isReadyToCreate);
      console.log({ dataToUpload, validData });
      handleUploadUsers(dataToUpload);
    }
  };

  return (
    <div className="general-table-container">
      {contextHolder}
      {isLoading ? (
        <TailSpinLoader />
      ) : (
        <Row>
          <Col span={24}>
            <div className="import-table-header-container">
              <Typography className="import-table-header">Secrets</Typography>
            </div>
            <Row
              className="full-width-row import-custom-titles-container"
              gutter={[16, 16]}
            >
              <Col span={2}>
                <Typography className="import-custom-titles-label">
                  Action
                </Typography>
              </Col>
              <Col span={3}>
                <Typography className="import-custom-titles-label">
                  UserName
                </Typography>
              </Col>
              <Col span={3}>
                <Typography className="import-custom-titles-label">
                  Password
                </Typography>
              </Col>
              <Col span={3}>
                <Typography className="import-custom-titles-label">
                  First Name
                </Typography>
              </Col>
              <Col span={3}>
                <Typography className="import-custom-titles-label">
                  Last Name
                </Typography>
              </Col>
              <Col span={3}>
                <Typography className="import-custom-titles-label">
                  Address
                </Typography>
              </Col>
              <Col span={3}>
                <Typography className="import-custom-titles-label">
                  Phone Number
                </Typography>
              </Col>
              <Col span={4}>
                <Typography className="import-custom-titles-label">
                  Package
                </Typography>
              </Col>
            </Row>
            <AutoSizer disableHeight>
              {({ width }: any) => (
                <List
                  height={500}
                  itemCount={data.length}
                  itemSize={() => 50} // Adjust the row height as needed
                  width={width}
                  itemData={{
                    data,
                    handleInputChange,
                    handleUserSkip,
                    packageOptions,
                  }}
                >
                  {RowRenderer}
                </List>
              )}
            </AutoSizer>
          </Col>
          <Col
            span={24}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "20px",
            }}
            className="mt-20 mb-20"
          >
            {data.length > 0 && (
              <MeshBillButton
                htmlType="button"
                backgroundColor={ButtonProps.background.STANDARD}
                height={ButtonProps.height.STANDARD}
                width={ButtonProps.width.STANDARD}
                textColor={ButtonProps.color.STANDARD}
                onClick={handleUpload}
                text="Upload Users"
                disabled={isUploading}
              />
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ImportSecretsTable;
