import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ViewStaffComponent from "../components/staff/ViewStaff";
// const ViewStaffComponent = lazy(() => import("../components/staff/ViewStaff"));
const ViewStaffPage = () => {
  const { setTitle } = useContext(TitleContext);
  useEffect(() => {
    setTitle("Staff");
  }, [setTitle]);
  return (
    <>
      <ViewStaffComponent />
    </>
  );
};
export default ViewStaffPage;
