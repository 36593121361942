import { useContext, useEffect, lazy } from "react";
import TitleContext from "../context/TitleContext";
import ViewSoaOFInvoiceComponent from "../components/soa/ViewSoaOFInvoice";
// const ViewSoaOFInvoice = lazy(
//   () => import("../components/soa/ViewSoaOFInvoice")
// );
const ViewEndUserInvoiceSOAPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Invoice SOA");
  }, [setTitle]);
  return (
    <>
      <ViewSoaOFInvoiceComponent />
    </>
  );
};
export default ViewEndUserInvoiceSOAPage;
