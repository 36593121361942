import { useEffect, useState } from "react";
import useAxiosPost from "../../hooks/useAxiosPost";
import { Col, Row } from "antd";
import MeshBillSelect from "../Items/Select/Index";
import MeshBillButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import useMultiNotification from "../../hooks/useNotification";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";
import ViewConnectedUserGraphModalComponent from "../Modals/ViewConnectedUserGraphModal";
import DisconnectApiConnectedUserModal from "../Modals/DisconnectApiConnectedUserModal";
import APIConnectedPPPoEUsersTable from "../Tables/ApiConnectedPPPoEUsersTable";
// const ApiConnectedPPPoEUsersTable = lazy(
//   () => import("./../Tables/ApiConnectedPPPoEUsersTable")
// );
// const ViewConnectedUserGraphModal = lazy(
//   () => import("../Modals/ViewConnectedUserGraphModal")
// );

// const DisconnectApiConnectedUserModal = lazy(
//   () => import("../Modals/DisconnectApiConnectedUserModal")
// );

const ViewApiConnectedPPPoeUsers = () => {
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ActivateRouterApiProp>(
    "mikronoc/getConnectedPPPoEUsersByNasID"
  );
  const { openNotification, contextHolder } = useMultiNotification();

  const [
    requestToGetConnectedUsersApi,
    requestToGetConnectedUsersApiResponse,
    requestToGetConnectedUsersApiError,
    requestToGetConnectedUsersApiLoading,
    requestToGetConnectedUsersApiMessage,
    requestToGetConnectedUsersApiErrorCount,
  ] = useAxiosPost<ActivateRouterApiProp>(
    "mikronoc/requestToGetAllConnectedPPPoEUsers"
  );

  const [routersApi, routersApiResponse] = useAxiosPost<ResponseProp>(
    "routers/getNasDevicesByCompany"
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [routers, setRouters] = useState<MeshBillSelectOptions[]>([]);
  const [selectedRouter, setSelectedRouter] = useState<any>();
  const [apiConnectedUsers, setApiConnectedUsers] = useState<
    ApiConnectedUser[]
  >([]);
  const [actualApiConnectedUsers, setActualApiConnectedUsers] = useState<
    ApiConnectedUser[]
  >([]);
  const [activeUser, setActiveUser] = useState<ApiConnectedUser | null>(null);
  const [showGraph, setShowGraph] = useState<boolean>(false);
  const [showDisconnect, setShowDisconnect] = useState<boolean>(false);
  useEffect(() => {
    routersApi({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const responseData: any = apiResponse.responseData;
      const { data = [] } = responseData;
      if (apiConnectedUsers.length < 1) {
        setApiConnectedUsers(data);
        setActualApiConnectedUsers(data);
        setTimeout(() => {
          setIsLoading(false);
        }, 5000);
      }
    }
  }, [apiResponse]);

  useEffect(() => {
    if (routersApiResponse !== null) {
      const data: any = routersApiResponse.responseData || [];
      const _routers = data.map((item: any): MeshBillSelectOptions => {
        return {
          value: item.intPkNasDeviceID,
          label: item.nasDeviceName,
          disabled: Number(item.isApiEnabled) === 1 ? false : true,
        };
      });
      setRouters(_routers);
    }
  }, [routersApiResponse]);
  useEffect(() => {
    if (
      requestToGetConnectedUsersApiError !== null &&
      requestToGetConnectedUsersApiErrorCount !== 0
    ) {
      openNotification(
        `error`,
        "Error While Getting Users",
        requestToGetConnectedUsersApiError
      );
    }
  }, [
    requestToGetConnectedUsersApiError,
    requestToGetConnectedUsersApiErrorCount,
  ]);

  useEffect(() => {
    if (requestToGetConnectedUsersApiResponse !== null) {
      const data: any = requestToGetConnectedUsersApiResponse.responseData;
      startIntervalHandler();
    }
  }, [requestToGetConnectedUsersApiResponse]);
  const startIntervalHandler = () => {
    const interval = setInterval(() => {
      postApiData({ intPkNasDeviceID: Number(selectedRouter) });
    }, 3000);
    setTimeout(() => {
      clearInterval(interval);
    }, 30000);
  };
  const handleViewGraph = (user: ApiConnectedUser) => {
    setActiveUser(user);
    setShowGraph(true);
  };
  const handleClose = (isReload: boolean) => {
    setShowGraph(false);
    setShowDisconnect(false);
    if (activeUser) {
      const _updatedConnectedUsers = apiConnectedUsers.filter(
        (item) => item.username !== activeUser.username
      );
      setApiConnectedUsers(_updatedConnectedUsers);
      setActiveUser(null);
    }
    if (isReload === true && selectedRouter) {
      setApiConnectedUsers([]);
      setIsLoading(true);
      requestToGetConnectedUsersApi({
        intPkNasDeviceID: Number(selectedRouter),
      });
    }
  };
  const handleDisconnectUser = (user: ApiConnectedUser) => {
    setActiveUser(user);
    setShowDisconnect(true);
  };

  if (isLoading) {
    return <TailSpinLoader />;
  }
  const handleSearch = (searchString: string) => {
    //let's filter things out.
    if (searchString.length > 0) {
      searchString = searchString.toLowerCase();
      const filteredUsers = actualApiConnectedUsers.filter((user) => {
        return (
          user.username.toLowerCase().includes(searchString) ||
          user.macAddress.toLowerCase().includes(searchString) ||
          user.ipAddress.toLowerCase().includes(searchString)
        );
      });
      setApiConnectedUsers(filteredUsers);
    } else {
      setApiConnectedUsers(actualApiConnectedUsers);
    }
  };
  return (
    <>
      {contextHolder}
      {showGraph === true && activeUser && selectedRouter && (
        <ViewConnectedUserGraphModalComponent
          intPkNasDeviceID={Number(selectedRouter)}
          show={showGraph}
          user={activeUser}
          handleClose={handleClose}
        />
      )}
      {showDisconnect === true && activeUser && selectedRouter && (
        <DisconnectApiConnectedUserModal
          intPkNasDeviceID={Number(selectedRouter)}
          show={showDisconnect}
          user={activeUser}
          handleClose={handleClose}
        />
      )}
      <Row className="full-width-row mb-20">
        <Col
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
            alignItems: "center",
          }}
          span={8}
        >
          <MeshBillSelect
            size="large"
            height="45px"
            value={selectedRouter}
            onChange={(e) => setSelectedRouter(e)}
            options={routers}
          />
          <MeshBillButton
            backgroundColor={ButtonProps.background.STANDARD}
            height={ButtonProps.height.STANDARD}
            width={`200px`}
            textColor={ButtonProps.color.STANDARD}
            onClick={() => {
              if (selectedRouter) {
                setApiConnectedUsers([]);
                requestToGetConnectedUsersApi({
                  intPkNasDeviceID: Number(selectedRouter),
                });
                setIsLoading(true);
              }
            }}
            // disabled={!selectedRouter || isDisabled}
            text="Fetch Users"
          />
        </Col>
      </Row>

      <Row className="full-width-row ">
        <Col span={24}>
          <APIConnectedPPPoEUsersTable
            users={apiConnectedUsers}
            isLoading={requestToGetConnectedUsersApiLoading || isLoading}
            handleViewGraph={handleViewGraph}
            handleDisconnectUser={handleDisconnectUser}
            handleSearch={handleSearch}
          />
        </Col>
      </Row>
    </>
  );
};
export default ViewApiConnectedPPPoeUsers;
