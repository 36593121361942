import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ViewRadiusChildProbesComponent from "../components/probes/ViewRadiusChildProbes";
// const ViewRadiusChildProbes = lazy(
//   () => import("../components/probes/ViewRadiusChildProbes")
// );
const ViewRadiusChildProbesPage = () => {
  const { setTitle } = useContext(TitleContext);
  useEffect(() => {
    setTitle("Radius Child Details");
  }, [setTitle]);
  return (
    <>
      <ViewRadiusChildProbesComponent />
    </>
  );
};
export default ViewRadiusChildProbesPage;
