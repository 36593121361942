import { useContext, useEffect, lazy } from "react";
import TitleContext from "../context/TitleContext";
import ViewMyRouterDetailsComponent from "../components/routers/ViewMyRouterDetails";

const MyRouterDetailsPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Router Details");
  }, [setTitle]);
  return (
    <>
      <ViewMyRouterDetailsComponent />
    </>
  );
};
export default MyRouterDetailsPage;
