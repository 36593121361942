import { useEffect, useState } from "react";
import useAxiosPost from "../../hooks/useAxiosPost";
import { Col, Row } from "antd";
import { ButtonProps } from "../../helpers/constants";
import { IoMdPersonAdd } from "react-icons/io";
import { useParams } from "react-router-dom";
import "./../../styles/staff/profile.css";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";
import UpdateStaffPasswordModalComponent from "../Modals/UpdateStaffPasswordModal";
import UpdateStaffDetailsModalComponent from "../Modals/UpdateStaffDetailsModal";
import UpdateStaffStatusModalComponent from "../Modals/UpdateStaffStatusModal";
import UpdateStaffWalletDaysModalComponent from "../Modals/UpdateStaffWalletDaysModal";
import UpdateStaffWalletLimitComponent from "../Modals/UpdateStaffWalletLimitModal";
import ResetWalletDepositDateModalComponent from "../Modals/ResetWalletDepositDateModal";
import CollectStaffPaymentModalComponent from "../Modals/CollectStaffPaymentModal";
import MeshBillButton from "../Items/Button/Index";
import ViewStaffDetailsCard from "./ViewStaffDetailsCard";
import ViewStaffTransactionsTable from "../Tables/ViewStaffTransactionsTable";
// const MeshBillButton = lazy(() => import("../Items/Button/Index"));
// const ViewStaffDetailsCard = lazy(() => import("./ViewStaffDetailsCard"));
// const UpdateStaffDetailsModalComponent = lazy(
//   () => import("../Modals/UpdateStaffDetailsModal")
// );
// const UpdateStaffPasswordModalComponent = lazy(
//   () => import("../Modals/UpdateStaffPasswordModal")
// );
// const UpdateStaffStatusModalComponent = lazy(
//   () => import("../Modals/UpdateStaffStatusModal")
// );
// const UpdateStaffWalletDaysModalComponent = lazy(
//   () => import("../Modals/UpdateStaffWalletDaysModal")
// );
// const UpdateStaffWalletLimitModalComponent = lazy(
//   () => import("../Modals/UpdateStaffWalletLimitModal")
// );
// const ResetWalletDepositDateModalComponent = lazy(
//   () => import("../Modals/ResetWalletDepositDateModal")
// );

// const CollectStaffPaymentModalComponent = lazy(
//   () => import("../Modals/CollectStaffPaymentModal")
// );
// const ViewStaffTransactionsTableComponent = lazy(
//   () => import("../Tables/ViewStaffTransactionsTable")
// );

const ViewStaffProfileComponent = () => {
  const { intPkHeadID } = useParams();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<StaffSingleProfileApi>("auth/getStaffProfile");

  const [
    getStaffTransactionsApi,
    staffTransactionsApiResponse,
    staffTransactionsApiError,
    staffTransactionsApiLoading,
    staffTransactionsApiMessage,
    staffTransactionsApiErrorCount,
  ] = useAxiosPost<StaffSingleProfileApi>(
    "staffTransactions/getMyStaffTransactions"
  );
  const [transactionsPagination, setTransactionsPagination] =
    useState<PaginationProp>({
      totalPages: 0,
      currentPage: 1,
      previousPage: null,
      nextPage: null,
      totalCount: 0,
      pageSize: 50,
    });
  const [staff, setStaff] = useState<StaffListType | null>(null);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [showUpdate, setShowUpdate] = useState<boolean>(false);
  const [showStatusUpdate, setShowStatusUpdate] = useState<boolean>(false);
  const [showWalletDaysUpdate, setShowWalletDaysUpdate] =
    useState<boolean>(false);
  const [showWalletLimitUpdate, setShowWalletLimitUpdate] =
    useState<boolean>(false);
  const [showPasswordUpdate, setShowPasswordUpdate] = useState<boolean>(false);
  const [showResetWallet, setShowResetWallet] = useState<boolean>(false);
  const [showCollectPayment, setShowCollectPayment] = useState<boolean>(false);

  useEffect(() => {
    postApiData({ intPkHeadID: Number(intPkHeadID) });
    getStaffTransactionsApi({ intPkHeadID: Number(intPkHeadID) });
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      console.log({ data });
      setStaff(data || null);
    }
  }, [apiResponse]);
  useEffect(() => {
    if (staffTransactionsApiResponse !== null) {
      const data: any = staffTransactionsApiResponse.responseData;
      const {
        currentPage,
        nextPage,
        pageSize,
        previousPage,
        totalCount,
        totalPages,
        transactions,
      } = data;
      console.log({ data });
      setTransactionsPagination({
        currentPage,
        totalPages,
        nextPage,
        previousPage,
        totalCount,
        pageSize,
      });
      setTransactions(transactions || []);
    }
  }, [staffTransactionsApiResponse]);
  if (apiLoading || staffTransactionsApiLoading) {
    return <TailSpinLoader />;
  }
  const handleClose = (isReload: boolean) => {
    setShowUpdate(false);
    setShowPasswordUpdate(false);
    setShowStatusUpdate(false);
    setShowWalletDaysUpdate(false);
    setShowWalletLimitUpdate(false);
    setShowResetWallet(false);
    setShowCollectPayment(false);
    if (isReload) {
      postApiData({ intPkHeadID: Number(intPkHeadID) });
      getStaffTransactionsApi({ intPkHeadID: Number(intPkHeadID) });
    }
  };
  const handleStaffProfileUpdate = () => {
    setShowUpdate(true);
  };
  const handleStaffPasswordUpdate = () => {
    setShowPasswordUpdate(true);
  };
  const handleStatusUpdate = (status: boolean) => {
    setShowStatusUpdate(true);
  };

  const handleWalletSDaysUpdate = () => {
    setShowWalletDaysUpdate(true);
  };
  const handleWalletLimitUpdate = () => {
    setShowWalletLimitUpdate(true);
  };
  const handleWalletDaysReset = () => {
    setShowResetWallet(true);
  };
  return (
    <>
      {showUpdate === true && staff !== null && (
        <UpdateStaffDetailsModalComponent
          activeStaff={staff}
          show={showUpdate}
          handleClose={handleClose}
          // apiUrl=""
          apiUrl="updateMyStaffProfile"
        />
      )}
      {showPasswordUpdate === true && staff !== null && (
        <UpdateStaffPasswordModalComponent
          activeStaff={staff}
          show={showPasswordUpdate}
          handleClose={handleClose}
          apiUrl={null}
        />
      )}
      {showStatusUpdate === true && staff !== null && (
        <UpdateStaffStatusModalComponent
          activeStaff={staff}
          show={showStatusUpdate}
          handleClose={handleClose}
          apiUrl={null}
        />
      )}
      {showWalletDaysUpdate === true && staff !== null && (
        <UpdateStaffWalletDaysModalComponent
          activeStaff={staff}
          show={showWalletDaysUpdate}
          handleClose={handleClose}
          apiUrl={null}
        />
      )}
      {showWalletLimitUpdate === true && staff !== null && (
        <UpdateStaffWalletLimitComponent
          activeStaff={staff}
          show={showWalletLimitUpdate}
          handleClose={handleClose}
          apiUrl={null}
        />
      )}
      {showResetWallet === true && staff !== null && (
        <ResetWalletDepositDateModalComponent
          activeStaff={staff}
          show={showResetWallet}
          handleClose={handleClose}
          apiUrl={null}
        />
      )}
      {showCollectPayment === true && staff !== null && (
        <CollectStaffPaymentModalComponent
          activeStaff={staff}
          show={showCollectPayment}
          handleClose={handleClose}
          apiUrl={null}
        />
      )}

      <Row className="full-width-row">
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
          }}
          span={24}
        >
          <MeshBillButton
            backgroundColor={ButtonProps.background.STANDARD}
            height={ButtonProps.height.STANDARD}
            width={ButtonProps.width.STANDARD}
            textColor={ButtonProps.color.STANDARD}
            onClick={() => {
              setShowCollectPayment(true);
            }}
            text="Collect Payment"
          />
        </Col>
        <Col span={24}>
          {staff && (
            <ViewStaffDetailsCard
              handleStaffProfileUpdate={handleStaffProfileUpdate}
              handleStaffPasswordUpdate={handleStaffPasswordUpdate}
              handleStatusUpdate={handleStatusUpdate}
              handleWalletSDaysUpdate={handleWalletSDaysUpdate}
              handleWalletLimitUpdate={handleWalletLimitUpdate}
              handleWalletDaysReset={handleWalletDaysReset}
              staff={staff}
            />
          )}
        </Col>

        {staff && (
          <Col className="mt-20" span={24}>
            <ViewStaffTransactionsTable
              tableTitle="Staff Transactions"
              isLoading={staffTransactionsApiLoading}
              transactions={transactions}
              role={staff.staffRole}
            />
          </Col>
        )}
      </Row>
    </>
  );
};
export default ViewStaffProfileComponent;
