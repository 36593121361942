import React from "react";
import { Button } from "antd";

interface ClickParam {
  [key: string]: any;
}

interface MeshBillButtonProps {
  borderColor?: string;
  backgroundColor?: string;
  height?: string;
  width?: string;
  textColor?: string;
  onClick: (param?: ClickParam) => void;
  text: string;
  fontSize?: string;
  fontWeight?: string;
  icon?: React.ReactNode;
  htmlType?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
}

const MeshBillButton: React.FC<MeshBillButtonProps> = ({
  backgroundColor = "linear-gradient(90deg, #2E3138 0%, #5A5D63 100%)",
  height = "40px",
  width = "100px",
  textColor = "white",
  onClick,
  text,
  borderColor = "#2E3138",
  fontSize = "16px",
  fontWeight = "400",
  icon = null,
  htmlType = "button",
  disabled = false,
}) => {
  const buttonStyle: React.CSSProperties = {
    background: backgroundColor,
    color: textColor,
    height,
    width,
    borderColor,
    fontSize,
    fontWeight,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
  };
  const iconStyle: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  };

  return (
    <Button
      disabled={disabled}
      htmlType={htmlType}
      onClick={onClick}
      style={buttonStyle}
    >
      {icon && <span style={iconStyle}>{icon}</span>}
      {text}
    </Button>
  );
};

export default MeshBillButton;
