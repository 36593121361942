import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input } from "antd";
import { ButtonProps } from "../../helpers/constants";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import "./../../styles/modal.css";
import MeshBillModal from "../Items/Modal/Index";
import MeshBillSelect from "../Items/Select/Index";
import MeshBillButton from "../Items/Button/Index";

// const MeshBillModal = lazy(() => import("../Items/Modal/Index"));
// const MeshBillButton = lazy(() => import("../Items/Button/Index"));
// const MeshBillSelect = lazy(() => import("../Items/Select/Index"));

const UpdateMikroNocProbeModal: React.FC<
  UpdateRouterMikroNocProbesModalProp
> = ({ show, handleClose, intPkMikroNOCProbeID, intPkNasDeviceID }) => {
  const { openNotification, contextHolder } = useMultiNotification();
  const [form] = Form.useForm();
  const [selectedProbe, setSelectedProbe] = useState();
  const [probes, setProbes] = useState<MeshBillSelectOptions[]>([]);

  const [radiusProbesApi, radiusProbesResponse] = useAxiosPost<ResponseProp>(
    "routers/getMikronocProbes"
  );
  useEffect(() => {
    if (radiusProbesResponse !== null) {
      const data: any = radiusProbesResponse.responseData;
      const probes = data.probes || [];
      const mikronocProbes = probes.map((item: any): MeshBillSelectOptions => {
        return {
          value: item.intPkMikroNOCProbeID,
          label: item.mikroNocProbeIP,
        };
      });
      setProbes(mikronocProbes);
    }
  }, [radiusProbesResponse]);
  useEffect(() => {
    radiusProbesApi({});
  }, []);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<UpdateMikroNocProbeApiProp>(
    "routers/updateMikroNocProbe",
    true
  );

  const onFinish = (values: any) => {
    const { intPkMikroNOCProbeID } = values;
    const foundObj = probes.find(
      (item) => Number(item.value) === Number(intPkMikroNOCProbeID)
    );
    if (!foundObj) {
      return;
    }
    const dataToSend: UpdateMikroNocProbeApiProp = {
      intPkMikroNOCProbeID: intPkMikroNOCProbeID,
      intPkNasDeviceID: intPkNasDeviceID,
      mikroNocProbeIP: foundObj.label,
    };
    postApiData(dataToSend);
  };
  useEffect(() => {
    if (apiResponse !== null) {
      const { message } = apiResponse;
      if (message) {
        openNotification("success", "Success", message);
        setTimeout(() => {
          handleClose(true);
        }, 2500);
      }
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification("error", "Error", apiError);
    }
  }, [apiError, apiErrorCount]);
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <MeshBillModal
        title="Update Router MikroNoc Probes"
        headerStyle={{
          backgroundColor: "var(--primary-background-color)",
          color: "var(--primary-text-color)",
          fontSize: "16px",
          fontWeight: "var(--fw-700)",
          height: "50px",
        }}
        open={show}
        onCancel={() => {
          if (apiLoading) {
            return;
          }
          handleClose(false);
        }}
      >
        {contextHolder}

        <Row>
          <Col
            span={24}
            className="meshbill-create-record-modal-form-container"
          >
            <Form
              style={{ width: "100%", marginTop: "50px" }}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              name="updateMikroNocProbes"
              form={form}
            >
              <Form.Item
                label={
                  <span className="create-user-form-label">
                    Choose MikroNoc Probe
                  </span>
                }
                name="intPkMikroNOCProbeID"
                rules={[
                  {
                    required: true,
                    message: "Please Select Probe",
                  },
                ]}
              >
                <MeshBillSelect
                  value={selectedProbe}
                  size="large"
                  height="45px"
                  options={probes}
                />
              </Form.Item>

              <Form.Item>
                <MeshBillButton
                  backgroundColor={ButtonProps.background.STANDARD}
                  height={ButtonProps.height.STANDARD}
                  width={`100%`}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {}}
                  htmlType="submit"
                  text="Submit"
                  disabled={apiLoading}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </MeshBillModal>
    </>
  );
};
export default UpdateMikroNocProbeModal;
