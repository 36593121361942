import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input, Checkbox, Typography } from "antd";
import { ButtonProps, getWalletDays } from "../../helpers/constants";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import "./../../styles/modal.css";
import { FiPlusCircle } from "react-icons/fi";
import SplineAreaChart from "../Charts/SplineAreaChart";
import MeshBillModal from "../Items/Modal/Index";
import MeshBillButton from "../Items/Button/Index";
import MeshBillSelect from "../Items/Select/Index";
// const MeshBillSelect = lazy(() => import("../Items/Select/Index"));
// const MeshBillModal = lazy(() => import("../Items/Modal/Index"));
// const MeshBillButton = lazy(() => import("../Items/Button/Index"));

const ViewConnectedUserGraphModalComponent: React.FC<
  ConnectUserGraphModalProp
> = ({ show, handleClose, user, intPkNasDeviceID }) => {
  const { openNotification, contextHolder } = useMultiNotification();
  const [form] = Form.useForm();
  const [streamData, setStreamData] = useState<DataPoint[]>([]);
  const [streamDuration, setStreamDuration] = useState();
  const [graphLoaded, setGraphLoaded] = useState<boolean>(false);
  const [flag, setFlag] = useState<number>(0);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<RequestOrResponseStreamApiProp>(
    "mikronoc/requestToGetStreamOfPPPoEUser",
    true
  );

  const [streamDataApi, streamDataApiResponse] =
    useAxiosPost<RequestOrResponseStreamApiProp>(
      "mikronoc/getConnectedPPPoEUserStreamResponse",
      true
    );

  useEffect(() => {
    if (streamDataApiResponse !== null) {
      const responseData: any = streamDataApiResponse.responseData;
      const { data, streamCompleted } = responseData;

      // if (_streamCompleted !== true && streamCompleted !== true) {
      //   _setStreamCompleted(streamCompleted);
      //   // we will keep getting the data.
      //   setStreamData(data || []);
      // }
      if (streamCompleted !== true) {
        //we will get the data.
        // if flag =1
        if (flag !== 1) {
          setStreamData(data || []);
        }
      } else {
        // true
        //flag =1
        setFlag(1);
      }
    }
  }, [streamDataApiResponse]);
  useEffect(() => {
    if (apiResponse !== null) {
      startIntervalHandler(Number(Number(streamDuration) * 1000));
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification("error", "Error", apiError);
    }
  }, [apiError, apiErrorCount]);
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const startIntervalHandler = (timeout: number) => {
    const interval = setInterval(() => {
      streamDataApi({
        intPkNasDeviceID: intPkNasDeviceID,
        streamLifeSeconds: 60,
        username: user.username,
        id: user.interfaceObj.id,
      });
    }, 3000);
    setTimeout(() => {
      clearInterval(interval);
    }, timeout);
  };
  return (
    <>
      <MeshBillModal
        title="Speed Graph of Connected PPPoE User"
        headerStyle={{
          backgroundColor: "var(--primary-background-color)",
          color: "var(--primary-text-color)",
          fontSize: "16px",
          fontWeight: "var(--fw-700)",
          height: "50px",
        }}
        open={show}
        onCancel={() => {
          if (apiLoading) {
            return;
          }
          handleClose(false);
        }}
      >
        {contextHolder}

        <Row>
          <Col
            span={24}
            className="meshbill-create-record-modal-form-container"
          >
            <Row className="full-width-row mb-20 mt-20">
              <Col span={18} offset={3}>
                <Typography
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    marginBottom: "20px",
                  }}
                >
                  Choose Stream Duration
                </Typography>
              </Col>
              <Col
                span={18}
                offset={3}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <MeshBillSelect
                  size="large"
                  height="45px"
                  value={streamDuration}
                  disabled={graphLoaded}
                  onChange={(e) => setStreamDuration(e)}
                  options={[
                    { label: "1 Minute", value: "60" },
                    { label: "3 Minutes", value: "180" },
                    { label: "5 Minutes", value: "300" },
                  ]}
                />
                <MeshBillButton
                  htmlType="submit"
                  backgroundColor={ButtonProps.background.STANDARD}
                  height={ButtonProps.height.STANDARD}
                  width={`70px`}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {
                    if (streamDuration) {
                      setGraphLoaded(true);
                      postApiData({
                        intPkNasDeviceID: intPkNasDeviceID,
                        streamLifeSeconds: Number(streamDuration),
                        username: user.username,
                        id: user.interfaceObj.id,
                      });
                    }
                  }}
                  text="Fetch"
                  disabled={!streamDuration || graphLoaded}
                />
              </Col>
            </Row>
            {graphLoaded === true && <SplineAreaChart data={streamData} />}
          </Col>
        </Row>
      </MeshBillModal>
    </>
  );
};
export default ViewConnectedUserGraphModalComponent;
