import { useEffect, useState } from "react";
import useAxiosPost from "../../hooks/useAxiosPost";
import { Col, Row, Typography } from "antd";
import MeshBillSelect from "../Items/Select/Index";
import MeshBillButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import useMultiNotification from "../../hooks/useNotification";
import { socket } from "../../socketService";
import { useNavigate } from "react-router-dom";
import ImportPackagesTable from "../Tables/ImportPackagesTable";

const ImportPackagesComponent = () => {
  const navigate = useNavigate();

  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ActivateRouterApiProp>("mikronoc/getPPPProfilesByNasID");

  const [
    postBulkApi,
    bulkApiResponse,
    bulkApiError,
    bulkApiLoading,
    bulkApiMessage,
    bulkApiErrorCount,
  ] = useAxiosPost<BulkPackagesCreationApi>("packages/createPackagesInBulk");

  const { openNotification, contextHolder } = useMultiNotification();

  const [
    requestToGetConnectedUsersApi,
    requestToGetConnectedUsersApiResponse,
    requestToGetConnectedUsersApiError,
    requestToGetConnectedUsersApiLoading,
    requestToGetConnectedUsersApiMessage,
    requestToGetConnectedUsersApiErrorCount,
  ] = useAxiosPost<ActivateRouterApiProp>(
    "mikronoc/requestToGetAllPPPProfiles"
  );

  const [routersApi, routersApiResponse] = useAxiosPost<ResponseProp>(
    "routers/getNasDevicesByCompany"
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [routers, setRouters] = useState<MeshBillSelectOptions[]>([]);
  const [selectedRouter, setSelectedRouter] = useState<any>();
  const [profiles, setProfiles] = useState<PPPProfile[]>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [totalPackagesFetched, setTotalPackagesFetched] = useState<number>(0);
  const [newPackages, setNewPackages] = useState<number>(0);
  useEffect(() => {
    routersApi({});

    socket.emit(
      "join",
      {
        userName: localStorage.getItem("username") || "client",
        client: "web",
      },
      () => {}
    );
  }, []);
  function extractSpeedInfo(speedString: string) {
    // Split the string by '/' to separate the two values
    if (!speedString) {
      return {
        speedUnit: "",
        speedAbbr: null,
      };
    }
    const [unitPart, abbrPart] = speedString.split("/");

    // Extract the numeric value from the first part (speedUnit)
    const unitMatch = unitPart.match(/\d+/);
    const speedUnit = unitMatch ? parseInt(unitMatch[0], 10) : null;

    // Extract the first letter from the second part (speedAbbr)
    const abbrMatch = abbrPart.match(/[a-zA-Z]/);
    const speedAbbr = abbrMatch ? abbrMatch[0] : null;

    return {
      speedUnit,
      speedAbbr,
    };
  }
  useEffect(() => {
    if (apiResponse !== null) {
      const responseData: any = apiResponse.responseData;
      const { data = [] } = responseData;
      if (profiles.length < 1) {
        const _profiles =
          data
            ?.filter((item: any) => item.default !== true)
            ?.map((item: any) => {
              const { speedAbbr, speedUnit } = extractSpeedInfo(item.rateLimit);
              console.log({ speedAbbr, speedUnit });
              return {
                ...item,
                packageName: item.profileName,
                packagePrice: 0,
                speedUnit: speedUnit,
                speedAbbrv: speedAbbr?.toUpperCase(),
                isVariableRechargeAllowed: 1,
                packageDuration: "THIRTY_DAYS",
                isCreateDynamicQueue: item.rateLimit ? 1 : 0,
                poolName: null,
              };
            }) || [];

        setProfiles(_profiles);
        setIsLoading(false);
      }
    }
  }, [apiResponse]);

  useEffect(() => {
    if (routersApiResponse !== null) {
      const data: any = routersApiResponse.responseData || [];
      const _routers = data.map((item: any): MeshBillSelectOptions => {
        return {
          value: item.intPkNasDeviceID,
          label: item.nasDeviceName,
        };
      });
      setRouters(_routers);
    }
  }, [routersApiResponse]);
  useEffect(() => {
    if (
      requestToGetConnectedUsersApiError !== null &&
      requestToGetConnectedUsersApiErrorCount !== 0
    ) {
      openNotification(
        `error`,
        "API Failed",
        requestToGetConnectedUsersApiError
      );
    }
  }, [
    requestToGetConnectedUsersApiError,
    requestToGetConnectedUsersApiErrorCount,
  ]);

  useEffect(() => {
    if (requestToGetConnectedUsersApiResponse !== null) {
      const data: any = requestToGetConnectedUsersApiResponse.responseData;
      startIntervalHandler();
    }
  }, [requestToGetConnectedUsersApiResponse]);
  const startIntervalHandler = () => {
    const interval = setInterval(() => {
      postApiData({ intPkNasDeviceID: Number(selectedRouter) });
    }, 3000);
    setTimeout(() => {
      clearInterval(interval);
    }, 30000);
  };
  const handleUploadPackages = (_profiles: PPPProfile[]) => {
    if (newPackages < 1) {
      openNotification(`error`, "Error", `No New Profiles Found`);
      return;
    }
    setIsUploading(true);
    setIsLoading(true);
    const packages: BulkPackageRecord[] = _profiles.map((item) => {
      return {
        salePrice: Number(item.packagePrice),
        packageName: item.packageName,
        poolName: null,
        speedUp: Number(item.speedUnit),
        speedDown: Number(item.speedUnit),
        speedAbbreviation: item.speedAbbrv,
        packageDuration: item.packageDuration,
        isVariableRechargeAllowed: item.isVariableRechargeAllowed,
        profileName: item.profileName,
        isCreateDynamicQueue: item.isCreateDynamicQueue,
        isAlreadyCreated: item.isAlreadyCreated,
      };
    });
    postBulkApi({ packages: packages });
  };

  useEffect(() => {
    if (bulkApiResponse !== null) {
      const { message } = bulkApiResponse;
      if (message) {
        openNotification(`success`, "Success", message);
        setTimeout(() => {
          navigate(`/packages`);
        }, 4000);
      }
    }
  }, [bulkApiResponse]);
  useEffect(() => {
    if (bulkApiError !== null && bulkApiErrorCount !== 0) {
      openNotification(`error`, "API Failed", bulkApiError);
      setIsUploading(false);
      setIsLoading(false);
    }
  }, [bulkApiError, bulkApiErrorCount]);
  useEffect(() => {
    setTotalPackagesFetched(profiles.length);
    setNewPackages(
      profiles.filter((item) => item.isAlreadyCreated !== true).length
    );
  }, [profiles]);
  return (
    <>
      {contextHolder}

      <Row className="full-width-row mb-20">
        <Col
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
            alignItems: "center",
          }}
          span={8}
        >
          <MeshBillSelect
            size="large"
            height="45px"
            value={selectedRouter}
            onChange={(e) => setSelectedRouter(e)}
            options={routers}
          />
          <MeshBillButton
            backgroundColor={ButtonProps.background.STANDARD}
            height={ButtonProps.height.STANDARD}
            width={`200px`}
            textColor={ButtonProps.color.STANDARD}
            onClick={() => {
              setProfiles([]);
              if (selectedRouter) {
                requestToGetConnectedUsersApi({
                  intPkNasDeviceID: Number(selectedRouter),
                });
                setIsLoading(true);
              }
            }}
            text="Fetch Profiles"
          />
        </Col>
      </Row>
      <Row className="full-width-row mb-20 mt-20">
        {selectedRouter && (
          <div>
            <Typography>Total Profiles : {totalPackagesFetched}</Typography>
            <Typography>New Profiles : {newPackages}</Typography>
          </div>
        )}
      </Row>
      <Row className="full-width-row ">
        {selectedRouter && (
          <Col span={24}>
            <ImportPackagesTable
              profiles={profiles}
              isLoading={isLoading}
              handleUploadPackages={handleUploadPackages}
              isUploading={isUploading}
            />
          </Col>
        )}
      </Row>
    </>
  );
};
export default ImportPackagesComponent;
