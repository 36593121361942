import { Col, Row, Typography, Radio } from "antd";
import React, { useEffect, useState } from "react";
import useAxiosPost from "../../../hooks/useAxiosPost";
import dayjs, { Dayjs } from "dayjs";
import MonthlyARPUCharts from "../../Charts/MonthlyARPUCharts";

function AverageRevenuePerUserStats() {
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<DashboardStatsForOwnerApiProp>(
    "reports/dashboard/getAverageRevenuePerUserMonthlyByOwner"
  );

  const [dates, setDates] = useState<[Dayjs | null, Dayjs | null]>([
    dayjs().subtract(1, "year"),
    dayjs(),
  ]);
  const [data, setData] = useState<ARPURecord[]>([]);

  const [view, setView] = useState("yearly");

  const handleToggleChange = (e: any) => {
    setView(e.target.value);
  };

  useEffect(() => {
    const endDate = dayjs().format("YYYY-MM-DD");
    const startDate = dayjs().subtract(1, "year").format("YYYY-MM-DD");
    console.log({ startDate, endDate });
    postApiData({ startDate, endDate });
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      console.log({ data });
      setData(data || []);
    }
  }, [apiResponse]);
  return (
    <Row className="chart-container">
      <div className="chart-container-header">
        <Typography className="chart-container-heading">
          {/* {view === "monthly" ? "Month Wise" : "Year Wise"} Billed And Payments{" "} */}
          Billed Customers & Average Revenue Per User{" "}
        </Typography>
      </div>
      <Col span={24}>
        <MonthlyARPUCharts data={data} />
      </Col>
    </Row>
  );
}

export default AverageRevenuePerUserStats;
