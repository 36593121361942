import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ViewAuditLogsComponent from "../components/AuditLogs/ViewAuditLogsComponent";
// const ViewAuditLogsComponent = lazy(
//   () => import("../components/AuditLogs/ViewAuditLogsComponent")
// );
const ViewAuditLogsPage = () => {
  const { setTitle } = useContext(TitleContext);
  useEffect(() => {
    setTitle("Audit Logs");
  }, [setTitle]);

  return (
    <>
      <ViewAuditLogsComponent />
    </>
  );
};
export default ViewAuditLogsPage;
