import { useEffect, useState } from "react";
import useAxiosPost from "../../hooks/useAxiosPost";
import { Col, Row } from "antd";
import { getUserType } from "../../helpers/userDetails";
import { useParams } from "react-router-dom";
import InvoiceSOATable from "../Tables/InvoiceSOATable";
// const InvoiceSOATable = lazy(() => import("../Tables/InvoiceSOATable"));
const ViewSoaOFInvoiceComponent = () => {
  const role = getUserType();
  const { intPkEndUserID, intPkEndUserInvoiceID } = useParams();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("endUsers/getSOAOfInvoice");
  const [invoice, setInvoice] = useState<InvoiceWithSOA | null>(null);
  useEffect(() => {
    postApiData({ intPkEndUserID, intPkEndUserInvoiceID });
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      console.log({ data });
      setInvoice(data || null);
    }
  }, [apiResponse]);

  return (
    <>
      <Row className="full-width-row">
        <Col span={24}>
          {invoice && (
            <InvoiceSOATable inovice={invoice} isLoading={apiLoading} />
          )}
        </Col>
      </Row>
    </>
  );
};
export default ViewSoaOFInvoiceComponent;
