import React, { useState } from "react";
import { Col, Dropdown, Row, Table, Tag, Typography } from "antd";
import type { MenuProps, TableProps } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import "./../../styles/tables.css";

import { useNavigate } from "react-router-dom";
import { formatDateForJustMonthDayAndYear } from "../../helpers/date";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";

const DealerAssignedPackagesTable: React.FC<
  DealerAssignedPackagesTableProp
> = ({
  packages,
  isLoading,
  handleUpdatePriceModal,
  // handleUpdateDetailsModal,
}) => {
  const [activeRow, setActiveRow] = useState<DealerAssignedPackage | null>(
    null
  );

  const packageOptions: MenuProps["items"] = [
    {
      key: "UPDATE_PRICE",
      label: (
        <Typography className="general-label-class">Update Price</Typography>
      ),
    },

    // {
    //   key: "UPDATE_DETAILS",
    //   label: (
    //     <Typography className="general-label-class">Update Details</Typography>
    //   ),
    // },
  ];

  const handleMenuClick = (e: any) => {
    if (!activeRow) {
      return;
    }
    switch (e.key) {
      case "UPDATE_PRICE":
        handleUpdatePriceModal(activeRow);
        break;
    }
  };

  const navigate = useNavigate();
  const columns: TableProps<DealerAssignedPackage>["columns"] = [
    {
      title: "Name",
      dataIndex: "packageName",
      key: "packageName",
    },
    {
      title: "Sale Price",
      render: (row) => <span>{row.salePriceForDealer.toLocaleString()}</span>,
    },
    {
      title: "Duration",
      dataIndex: "packageDuration",
      key: "packageDuration",
    },
    {
      title: "Pool",
      render: (row) => <span>{row.poolName ? row.poolName : "-"}</span>,
    },
    {
      title: "Profile",
      render: (row) => <span>{row.profileName}</span>,
    },
    {
      title: "Speed",
      render: (row) => (
        <span>
          {row.speedUp && row.speedDown && row.speedAbbreviation
            ? `${row.speedUp}/${row.speedDown} ${row.speedAbbreviation}`
            : "-"}
        </span>
      ),
    },
    {
      title: "Variable Recharge?",
      render: (row) => (
        <span>
          {Number(row.isVariableRechargeAllowed) === 1 ? `YES` : "NO"}
        </span>
      ),
    },
    {
      title: "Assigned",
      dataIndex: "dealerAssignedDate",
      render: (text) => (
        <span>{formatDateForJustMonthDayAndYear(new Date(text))}</span>
      ),
    },

    {
      title: "Action",
      render: (row) => (
        <Dropdown
          menu={{
            items: packageOptions,
            onClick: handleMenuClick,
          }}
          placement="bottomRight"
        >
          <MenuOutlined
            onMouseEnter={(e) => {
              setActiveRow(row);
            }}
            className="cursor-pointer"
          />
        </Dropdown>
      ),
    },
  ];

  return (
    <div className="general-table-container">
      {isLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row>
          <Col span={24}>
            <Typography className="general-table-heading">
              Assigned Packages
            </Typography>
            <Table
              className="striped-table"
              columns={columns}
              dataSource={packages}
              onRow={(record, rowIndex) => {
                return {};
              }}
              pagination={false}
            />
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
              marginBottom: "20px",
            }}
            span={24}
          ></Col>
        </Row>
      )}
    </div>
  );
};
export default DealerAssignedPackagesTable;
