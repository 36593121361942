import React, { useState } from "react";
import { Button, Col, Dropdown, Row, Table, Tag, Typography } from "antd";
import type { MenuProps, TableProps } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import "./../../styles/tables.css";

import { useNavigate } from "react-router-dom";
import { formatDate } from "../../helpers/date";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";
// const MeshBillPagination = lazy(
//   () => import("../Items/Pagination/MeshbillPagination")
// );

// const TailSpinLoader = lazy(() => import("../Items/Loaders/TailSpinLoader"));

const UserCreditHistoryTable: React.FC<CreditHistoryTableProp> = ({
  notes,
  isLoading,
}) => {
  const [activeRow, setActiveRow] = useState<CreditNote | null>(null);

  const columns: TableProps<CreditNote>["columns"] = [
    {
      title: "Credit Amount",
      render: (row) => <span>{`${row.amount}`}</span>,
    },
    {
      title: "Consumed Amount",
      render: (row) => <span>{`${row.consumedAmount}`}</span>,
    },
    {
      title: "Description",
      render: (row) => <span>{`${row.description}`}</span>,
    },
    {
      title: "Created",
      dataIndex: "creditNoteCreated",
      render: (text) => <span>{formatDate(new Date(text))}</span>,
    },
    {
      title: "Consumed Date",
      render: (row) => (
        <span>
          {row.dateConsumed ? formatDate(new Date(row.dateConsumed)) : "-"}
        </span>
      ),
    },
  ];

  return (
    <div className="general-table-container">
      {isLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row>
          <Col span={24}>
            <Typography className="general-table-heading">
              Credit History
            </Typography>
            <Table
              className="striped-table"
              columns={columns}
              dataSource={notes}
              pagination={false}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};
export default UserCreditHistoryTable;
