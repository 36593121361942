import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const DailyUserCreationChart: React.FC<UserCreationDailyStatsChartProps> = ({
  data,
}) => {
  const categories = data.map((item) => item.date_name);
  const seriesData = data.map((item) => item.totalNewUsers);
  const options: ApexOptions = {
    series: [
      {
        name: "Total New Users",
        data: seriesData,
      },
    ],
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },

    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // alternating row colors
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: categories,
      labels: {
        rotate: -75, // Rotate the labels to display them vertically
        rotateAlways: true, // Ensure rotation is applied

        style: {
          fontSize: "12px",
        },
      },
    },
  };

  return (
    <div>
      <ReactApexChart
        options={options}
        series={options.series}
        type="line"
        height={400}
      />
    </div>
  );
};

export default DailyUserCreationChart;
