import React, { useState } from "react";
import { Col, Dropdown, Row, Table, Tag, Typography } from "antd";
import type { MenuProps, TableProps } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import "./../../styles/tables.css";

import { useNavigate } from "react-router-dom";
import { formatDateForJustMonthDayAndYear } from "../../helpers/date";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";

// const TailSpinLoader = lazy(() => import("../Items/Loaders/TailSpinLoader"));

const ViewRadiusChildProbesTable: React.FC<RadiusChildProbesTableProp> = ({
  probes,
  isLoading,
}) => {
  const [activeRow, setActiveRow] = useState<RadiusChildProbe | null>(null);

  const packageOptions: MenuProps["items"] = [
    {
      key: "VIEW_DETAILS",
      label: (
        <Typography className="general-label-class">View Details</Typography>
      ),
    },
  ];

  const handleMenuClick = (e: any) => {
    if (!activeRow) {
      return;
    }
    //path: ":",

    switch (e.key) {
      case "VIEW_DETAILS":
        navigate(`/child-probes/${activeRow.intPkMeshBillRadiusProbeID}`);
        break;
    }
  };
  const navigate = useNavigate();
  const columns: TableProps<RadiusChildProbe>["columns"] = [
    {
      title: "Port",
      dataIndex: "port",
      key: "port",
    },
    {
      title: "Port Type",
      dataIndex: "portType",
      key: "portType",
    },
    {
      title: "Created",
      dataIndex: "portCreated",
      render: (text) => (
        <span>{formatDateForJustMonthDayAndYear(new Date(text))}</span>
      ),
    },
    // {
    //   title: "Action",
    //   render: (row) => (
    //     <Dropdown
    //       menu={{
    //         items: packageOptions,
    //         onClick: handleMenuClick,
    //       }}
    //       placement="bottomRight"
    //     >
    //       <MenuOutlined
    //         onMouseEnter={(e) => {
    //           setActiveRow(row);
    //         }}
    //         className="cursor-pointer"
    //       />
    //     </Dropdown>
    //   ),
    // },
  ];

  return (
    <div className="general-table-container">
      {isLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row>
          <Col span={24}>
            <Typography className="general-table-heading">
              Radius Child Probes
            </Typography>
            <Table
              className="striped-table"
              columns={columns}
              dataSource={probes}
              onRow={(record, rowIndex) => {
                return {};
              }}
              pagination={false}
            />
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
              marginBottom: "20px",
            }}
            span={24}
          ></Col>
        </Row>
      )}
    </div>
  );
};
export default ViewRadiusChildProbesTable;
