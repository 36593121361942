import React, { useEffect, useState } from "react";
import { Col, Row, Typography, MenuProps, Dropdown } from "antd";
import "./../../../styles/staff/profile.css";
import useMultiNotification from "../../../hooks/useNotification";
import useAxiosPost from "../../../hooks/useAxiosPost";
import { MenuOutlined } from "@ant-design/icons";
import TailSpinLoader from "../../Items/Loaders/TailSpinLoader";
import UpdateCreditLimitModalComponent from "../../Modals/UpdateDealerCreditLimit";
import UpdateStaffDetailsModalComponent from "../../Modals/UpdateStaffDetailsModal";
import TopUpDealerModalComponent from "../../Modals/TopUpDealerModal";
// const TailSpinLoader = lazy(() => import("../../Items/Loaders/TailSpinLoader"));
// const TopUpDealerModalComponent = lazy(
//   () => import("../../Modals/TopUpDealerModal")
// );
// const UpdateStaffDetailsModalComponent = lazy(
//   () => import("../../Modals/UpdateStaffDetailsModal")
// );
// const UpdateDealerCreditLimitComponent = lazy(
//   () => import("../../Modals/UpdateDealerCreditLimit")
// );

interface UserSingleRowSimpleProp {
  label: string;
  value: string;
  isBorderBottom: boolean;
}
const UserSingleRowSimple: React.FC<UserSingleRowSimpleProp> = ({
  label,
  value,
  isBorderBottom = true,
}) => {
  return (
    <Col
      span={24}
      className={`profile-card-single-row ${
        isBorderBottom === true ? "border-bottom-1px" : ""
      }`}
    >
      <div className="values-container">
        <Typography className="user-label-primary">{label}</Typography>
        <Typography className="user-value"> {value}</Typography>
      </div>
    </Col>
  );
};
const ViewDealerProfile: React.FC<DealerProfileComponent> = ({
  intPkHeadID,
}) => {
  const { openNotification, contextHolder } = useMultiNotification();
  const [dealer, setDealer] = useState<Dealer | null>(null);
  const [showRecharge, setShowRecharge] = useState<boolean>(false);
  const [showUpdate, setShowUpdate] = useState<boolean>(false);
  const [showUpdateCreditLimit, setShowUpdateCreditLimit] =
    useState<boolean>(false);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<SingleDealerProfileApiProp>("dealers/getDealerProfile");

  useEffect(() => {
    postApiData({ intPkHeadID });
  }, []);

  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setDealer(data || null);
    }
  }, [apiResponse]);
  if (apiLoading) {
    return <TailSpinLoader />;
  }

  const handleClose = (isReload: boolean) => {
    setShowUpdate(false);
    setShowRecharge(false);
    setShowUpdateCreditLimit(false);

    if (isReload) {
      postApiData({ intPkHeadID });
    }
  };
  const userProfileOptions: MenuProps["items"] = [
    {
      key: "RECHARGE",
      label: <Typography className="general-label-class">Top Up</Typography>,
    },
    {
      key: "UPDATE_PROFILE",
      label: (
        <Typography className="general-label-class">Update Profile</Typography>
      ),
    },
    {
      key: "UPDATE_CREDIT_LIMIT",
      label: (
        <Typography className="general-label-class">
          Update Credit Limit
        </Typography>
      ),
    },
  ];
  const handleMenuClick = (e: any) => {
    switch (e.key) {
      case "RECHARGE":
        setShowRecharge(true);
        break;
      case "UPDATE_PROFILE":
        setShowUpdate(true);
        break;
      case "UPDATE_CREDIT_LIMIT":
        setShowUpdateCreditLimit(true);
        break;
    }
  };

  return (
    <>
      {contextHolder}
      {dealer && (
        <>
          {showUpdateCreditLimit === true && (
            <UpdateCreditLimitModalComponent
              activeDealer={dealer}
              show={showUpdateCreditLimit}
              handleClose={handleClose}
            />
          )}
          {showUpdate === true && (
            <UpdateStaffDetailsModalComponent
              activeStaff={dealer}
              show={showUpdate}
              handleClose={handleClose}
              apiUrl="updateMyStaffProfile"
            />
          )}

          {showRecharge === true && (
            <TopUpDealerModalComponent
              show={showRecharge}
              handleClose={handleClose}
              activeDealer={dealer}
            />
          )}

          <Col lg={12} xl={12} xxl={12} sm={24} md={12}>
            <Row className="staff-profile-card-container">
              <Col
                span={24}
                className="staff-details-title-label-container border-bottom-1px"
              >
                <Typography className="staff-profile-card-title">
                  Dealer Profile
                </Typography>
                <Dropdown
                  menu={{
                    items: userProfileOptions,
                    onClick: handleMenuClick,
                  }}
                  placement="bottomRight"
                >
                  <MenuOutlined className="cursor-pointer" />
                </Dropdown>
              </Col>
              <UserSingleRowSimple
                label="Full Name"
                value={`${dealer.firstName} ${dealer.lastName}`}
                isBorderBottom={true}
              />
              <UserSingleRowSimple
                label="Address"
                value={`${dealer.address} `}
                isBorderBottom={true}
              />

              <UserSingleRowSimple
                label="Phone Number"
                value={`${dealer.phoneNumber ? dealer.phoneNumber : "---"}`}
                isBorderBottom={true}
              />
              <UserSingleRowSimple
                label="Email"
                value={`${dealer.email ? dealer.email : "---"}`}
                isBorderBottom={true}
              />
              <UserSingleRowSimple
                label="UserName"
                value={`${dealer.staffUserName}`}
                isBorderBottom={false}
              />
            </Row>
          </Col>
          <Col lg={12} xl={12} xxl={12} sm={24} md={12}>
            <Row className="staff-profile-card-container">
              <UserSingleRowSimple
                label="Balance"
                value={`${dealer?.userBalance?.cashAvailable?.toLocaleString()} PKR`}
                isBorderBottom={true}
              />
              <UserSingleRowSimple
                label="Credit Allowed"
                value={`${Number(dealer.isCreditAllowed) === 1 ? "Yes" : "No"}`}
                isBorderBottom={true}
              />

              <UserSingleRowSimple
                label="Credit Limit"
                value={`${dealer.creditLimit.toLocaleString()}`}
                isBorderBottom={false}
              />
            </Row>
          </Col>
        </>
      )}
    </>
  );
};
export default ViewDealerProfile;
