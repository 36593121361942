import React, { useState } from "react";
import { Col, Row, Table, Typography } from "antd";
import type { TableProps } from "antd";

import "./../../styles/tables.css";

import { useNavigate } from "react-router-dom";
import { formatDate } from "../../helpers/date";
import {
  formatMacAddress,
  getFailedAuthLogShortTitle,
} from "../../helpers/constants";
import MeshBillPagination from "../Items/Pagination/MeshbillPagination";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";

const FailedAuthLogsTable: React.FC<FailedAuthLogsTableProp> = ({
  logs,
  isLoading,
  pagination,
  handlePageSwitch,
}) => {
  const [activeRow, setActiveRow] = useState<any | null>(null);
  const navigate = useNavigate();

  const columns: TableProps<any>["columns"] = [
    {
      title: "Session ID",
      render: (row) => <span>{`${row.acctSessionID}`}</span>,
    },
    {
      title: "Type",
      render: (row) => (
        <strong>{`${getFailedAuthLogShortTitle(row.rejectErrorEnum)}`}</strong>
      ),
    },
    {
      title: "UserName",
      render: (row) => <span>{`${row.username}`}</span>,
    },
    {
      title: "NAS Device",
      render: (row) => <span>{`${row?.nasDeviceName}`}</span>,
    },
    {
      title: "Mac Address",
      dataIndex: "cpeMAC",
      render: (text) => <span>{formatMacAddress(text)}</span>,
    },
    {
      title: "Vlan",
      dataIndex: "routerInterfaceID",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      render: (text) => <span>{formatDate(new Date(text))}</span>,
    },
  ];

  return (
    <div className="general-table-container">
      {isLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row>
          <Col span={24}>
            <Typography className="general-table-heading">
              Failed Auth Logs
            </Typography>
            <Table
              className="striped-table"
              columns={columns}
              dataSource={logs}
              onRow={(record, rowIndex) => {
                return {};
              }}
              pagination={false}
            />
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
              marginBottom: "20px",
            }}
            span={24}
          >
            <MeshBillPagination
              pagination={pagination}
              handlePageSwitch={handlePageSwitch}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};
export default FailedAuthLogsTable;
