import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Row,
  Table,
  Tag,
  Typography,
  Input,
} from "antd";
import type { MenuProps, TableProps } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import "./../../styles/tables.css";

import { useNavigate } from "react-router-dom";
import {
  formatDate,
  formatDateForJustMonthDayAndYear,
  readableUpTime,
} from "../../helpers/date";
import MeshBillPagination from "../Items/Pagination/MeshbillPagination";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";
import { convertBytesToKMGBytes } from "../../helpers/unitConvertor";
import { SearchProps } from "antd/es/input";
const { Search } = Input;

const ConnectedPPPoEUsersTable: React.FC<ConnectedPPPoEUsersTableProp> = ({
  users,
  isLoading,
  pagination,
  handlePageSwitch,
  handleDisconnectUser,
  handleSearch,
}) => {
  const [activeRow, setActiveRow] = useState<ConnectedUser | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const navigate = useNavigate();

  const columns: TableProps<ConnectedUser>["columns"] = [
    {
      title: "UserName",
      render: (row) => <span>{`${row?.authObj?.username}`}</span>,
    },
    {
      title: "NAS",
      render: (row) => <span>{`${row?.acctObj?.nasDeviceName}`}</span>,
    },
    {
      title: "Profile Name",
      render: (row) => <span>{`${row?.authObj?.profileName}`}</span>,
    },
    {
      title: "Pool Name",
      render: (row) => <span>{`${row?.authObj?.ipPoolName}`}</span>,
    },
    {
      title: "MAC Address",
      render: (row) => <span>{`${row?.acctObj?.cpeMAC}`}</span>,
    },
    {
      title: "IP Address",
      render: (row) => <span>{`${row?.acctObj?.cpeIPAddress}`}</span>,
    },

    {
      title: "Speed",
      render: (row) => (
        <span>
          {row?.authObj?.speedCtrlObj
            ? `${row?.authObj?.speedCtrlObj?.uploadSpeed}/${row?.authObj?.speedCtrlObj?.downloadSpeed} ${row?.authObj?.speedCtrlObj?.rxTxSpeedUnit}`
            : "-"}
        </span>
      ),
    },
    {
      title: "Upload",
      render: (row) => (
        <span>{`${convertBytesToKMGBytes(
          row?.acctObj?.sessionTotalUploadedBytes || 0
        )}`}</span>
      ),
    },
    {
      title: "Download",
      render: (row) => (
        <span>{`${convertBytesToKMGBytes(
          row?.acctObj?.sessionTotalDownloadedBytes || 0
        )}`}</span>
      ),
    },
    {
      title: "Vlan",
      render: (row) => <span>{`${row?.acctObj?.routerInterfaceID}`}</span>,
    },
    {
      title: "Session Started",
      dataIndex: "createdAt",
      render: (text) => <span>{formatDate(new Date(text))}</span>,
    },
    {
      title: "Up time",
      dataIndex: "createdAt",
      render: (text) => <span>{readableUpTime(text)}</span>,
    },
    {
      title: "Disconnect",
      render: (row) => (
        <Button
          type="primary"
          danger
          onClick={(e) => {
            handleDisconnectUser(row);
          }}
        >
          Disconnect
        </Button>
      ),
    },
  ];

  const onSearch: SearchProps["onSearch"] = (value, _e, info) => {
    console.log(info?.source, value);
    if (value.length < 3) {
      return;
    }
    handleSearch(value);
  };

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      handleSearch(searchTerm);
    }, 500);

    return () => clearTimeout(delaySearch); // Cleanup the timeout on component unmount
  }, [searchTerm]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };
  return (
    <div className="general-table-container">
      {isLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row>
          <Col span={24}>
            <div className="users-search-container">
              <Typography className="general-table-heading">
                Radius Connected Users
              </Typography>
              <Search
                placeholder=" Username / IP / MAC"
                onSearch={onSearch}
                value={searchTerm}
                allowClear
                onChange={handleChange}
                style={{ width: 300 }}
              />
            </div>
            <Table
              className="striped-table"
              columns={columns}
              dataSource={users}
              pagination={false}
              scroll={{ y: 700, x: 1300 }}
            />
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
              marginBottom: "20px",
            }}
            span={24}
          >
            <MeshBillPagination
              pagination={pagination}
              handlePageSwitch={handlePageSwitch}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};
export default ConnectedPPPoEUsersTable;
