import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ViewPackagesComponent from "../components/packages/ViewPackages";
// const ViewPackagesComponent = lazy(
//   () => import("../components/packages/ViewPackages")
// );
const ViewPackagesPage = () => {
  const { setTitle } = useContext(TitleContext);
  useEffect(() => {
    setTitle("Packages");
  }, [setTitle]);
  return (
    <>
      <ViewPackagesComponent />
    </>
  );
};
export default ViewPackagesPage;
