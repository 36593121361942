import { AuditLogEnums, authRejectReasonEnums } from "mbcommons";
export const ButtonProps = {
  background: {
    STANDARD: "linear-gradient(90deg, #2E3138 0%, #5A5D63 100%)",
    YELLOW: "linear-gradient(97.05deg, #DB7C38 1.74%, #FFA726 97.54%)",
    DANGER: "linear-gradient(96.95deg, #AB1B1A 1.36%, #DF3131 96.17%)",
    SECONDARY: "#D7D7D7",
  },
  width: {
    STANDARD: "262px",
    ONE24: "124px",
  },
  height: {
    STANDARD: "40px",
  },
  color: {
    STANDARD: "#FFFFFF",
    BLACK: "#545454",
  },
};
export const getWalletDays = () => {
  const days = 7;
  const options = [];
  for (let i = 1; i <= days; i++) {
    options.push({ label: i.toString(), value: i });
  }
  return options;
};

export const getAmountClass = (role: string, row: Transaction): string => {
  if (role === "ADMIN") {
    return "balance-available-label";
  }
  return row.row_enum === "STAFF_PAYMENT"
    ? "balance-minus-label"
    : "balance-available-label";
};
export const getAmountSign = (role: string, row: Transaction) => {
  if (role === "ADMIN") {
    return "+";
  }
  return row.row_enum === "STAFF_PAYMENT" ? "-" : "+";
};

export const getStaffFullName = (role: string, row: Transaction) => {
  if (role === "ADMIN") {
    return row.givenStaffFullName;
  }
  return row.row_enum === "STAFF_PAYMENT" ? row.receivedStaffFullName : "---";
};

export const getAuditLogShortTitle = (actionType: string) => {
  const items: any = AuditLogEnums;
  const item = items[actionType];
  console.log({ item });
  if (!item) {
    return "---";
  }
  return item.shortTitle || "---";
};
export const getFailedAuthLogShortTitle = (actionType: string) => {
  const items: any = authRejectReasonEnums;
  const item = items[actionType];
  console.log({ item });
  if (!item) {
    return "---";
  }
  return item.shortTitle || "---";
};
export const formatStaffLogs = (logs: any[]) => {
  const formattedLogs: any[] = [];
  //userDetails, wallet details, enableDisableObj,walletObj,isWalletReset,updatedBy
  for (const log of logs) {
    if (
      log.actionType === AuditLogEnums.NEW_STAFF_CREATED.type ||
      log.actionType === AuditLogEnums.STAFF_PROFILE_UPDATE.type
    ) {
      // get staff from current.
      formattedLogs.push({
        userDetails: log.currentStaffDetails,
        updatedBy: log.updatedBy,
        createdAt: log.createdAt,
        actionType: log.actionType,
        groupID: log.groupID,
        enableDisableObj: null,
        walletDetails: null,
        isWalletReset: false,
        beforeStaffDetails: log.beforeStaffDetails,
      });
    } else if (log.actionType === AuditLogEnums.STAFF_STATUS_CHANGED.type) {
      //beforeStaffDetails
      //status details will be from current
      formattedLogs.push({
        userDetails: log.beforeStaffDetails,
        updatedBy: log.updatedBy,
        createdAt: log.createdAt,
        actionType: log.actionType,
        groupID: log.groupID,
        enableDisableObj: log.currentStaffDetails,
        walletDetails: null,
        isWalletReset: false,
      });
    } else if (log.actionType === AuditLogEnums.STAFF_PASSWORD_UPDATED.type) {
      //beforeStaffDetails
      formattedLogs.push({
        userDetails: log.beforeStaffDetails,
        updatedBy: log.updatedBy,
        createdAt: log.createdAt,
        actionType: log.actionType,
        groupID: log.groupID,
        enableDisableObj: null,
        walletDetails: null,
        isWalletReset: false,
      });
    } else if (log.actionType === AuditLogEnums.UPDATE_STAFF_WALLET.type) {
      //beforeStaffDetails
      //wallet will be from current staff.
      formattedLogs.push({
        userDetails: log.beforeStaffDetails,
        updatedBy: log.updatedBy,
        createdAt: log.createdAt,
        actionType: log.actionType,
        groupID: log.groupID,
        enableDisableObj: null,
        walletDetails: log.currentStaffDetails,
        isWalletReset: false,
      });
    } else if (log.actionType === AuditLogEnums.STAFF_DEPOSIT_RESET.type) {
      //beforeStaffDetails
      //Make a flag if date reset or not.
      formattedLogs.push({
        userDetails: log.beforeStaffDetails,
        updatedBy: log.updatedBy,
        createdAt: log.createdAt,
        actionType: log.actionType,
        groupID: log.groupID,
        enableDisableObj: null,
        walletDetails: null,
        isWalletReset: true,
      });
    } else if (log.actionType === AuditLogEnums.STAFF_PAYMENT_COLLECTION.type) {
      //staffPaymentCollectionDetails
      //we will take everything from here.
      //Make a flag if date reset or not.
      formattedLogs.push({
        userDetails: log.staffPaymentCollectionDetails,
        updatedBy: log.updatedBy,
        createdAt: log.createdAt,
        actionType: log.actionType,
        groupID: log.groupID,
        enableDisableObj: null,
        walletDetails: null,
        isWalletReset: false,
      });
    }
  }
  return formattedLogs;
};

export const formatPackagesLogs = (logs: any[]) => {
  const formattedLogs: any[] = [];
  //userDetails, wallet details, enableDisableObj,walletObj,isWalletReset,updatedBy

  for (const log of logs) {
    if (log.actionType === AuditLogEnums.CREATE_NEW_PACKAGE.type) {
      //currentPackageDetails
      formattedLogs.push({
        packageDetails: log.currentPackageDetails,
        updatedBy: log.updatedBy,
        createdAt: log.createdAt,
        actionType: log.actionType,
        groupID: log.groupID,
        beforePackageDetails: log.beforePackageDetails,
        isPriceUpdated: true,
      });
    } else if (log.actionType === AuditLogEnums.UPDATE_PACKAGE_DETAILS.type) {
      //currentPackageDetails
      formattedLogs.push({
        packageDetails: log.currentPackageDetails,
        updatedBy: log.updatedBy,
        createdAt: log.createdAt,
        actionType: log.actionType,
        groupID: log.groupID,
        beforePackageDetails: log.beforePackageDetails,
        isPriceUpdated: false,
      });
    } else if (log.actionType === AuditLogEnums.UPDATE_PACKAGE_PRICE.type) {
      //currentPackageDetails
      const packageDetails = {
        ...log.beforePackageDetails,
        salePrice: log.currentPackageDetails.salePrice,
      };
      formattedLogs.push({
        packageDetails: packageDetails,
        updatedBy: log.updatedBy,
        createdAt: log.createdAt,
        actionType: log.actionType,
        groupID: log.groupID,
        beforePackageDetails: log.beforePackageDetails,
        isPriceUpdated: true,
      });
    }
  }
  return formattedLogs;
};
export const formatEndUserLogs = (logs: any[]) => {
  const formattedLogs: any[] = [];
  //,,ontObj
  for (const log of logs) {
    if (
      log.actionType === AuditLogEnums.CREATE_NEW_END_USER.type ||
      log.actionType === AuditLogEnums.UPDATE_END_USER.type
    ) {
      //currentEndUserDetails
      formattedLogs.push({
        endUserDetails: log.currentEndUserDetails,
        endUserTransactionLog: log.endUserTransactionLog,
        updatedBy: log.updatedBy,
        createdAt: log.createdAt,
        actionType: log.actionType,
        groupID: log.groupID,
        discountObj: null,
        ontObj: null,
      });
    } else if (log.actionType === AuditLogEnums.END_USER_DISCOUNT_UPDATE.type) {
      formattedLogs.push({
        endUserDetails: log.beforeEndUserDetails,
        endUserTransactionLog: log.endUserTransactionLog,
        updatedBy: log.updatedBy,
        createdAt: log.createdAt,
        actionType: log.actionType,
        groupID: log.groupID,
        discountObj: log.currentEndUserDetails,
        ontObj: null,
      });
    } else if (log.actionType === AuditLogEnums.END_USER_ONT_ID_UPDATE.type) {
      formattedLogs.push({
        endUserDetails: log.beforeEndUserDetails,
        endUserTransactionLog: log.endUserTransactionLog,
        updatedBy: log.updatedBy,
        createdAt: log.createdAt,
        actionType: log.actionType,
        groupID: log.groupID,
        ontObj: log.currentEndUserDetails,
        discountObj: null,
      });
    } else if (
      log.actionType === AuditLogEnums.SINGLE_RECHARGE_END_USER.type ||
      log.actionType === AuditLogEnums.VARIABLE_RECHARGE_END_USER.type ||
      log.actionType === AuditLogEnums.CHANGE_PACKAGE_END_USER.type ||
      log.actionType ===
        AuditLogEnums.PAY_OPEN_CONNECTION_INVOICE_TO_END_USER.type
    ) {
      formattedLogs.push({
        endUserDetails: log.currentEndUserDetails,
        endUserTransactionLog: log.endUserTransactionLog,
        updatedBy: log.updatedBy,
        createdAt: log.createdAt,
        actionType: log.actionType,
        groupID: log.groupID,
        ontObj: null,
        discountObj: null,
      });
    }
  }
  return formattedLogs;
};
export const formatMacAddress = (mac: string) => {
  if (!mac) {
    return "";
  }

  // Insert colons every 2 characters
  return mac.match(/.{1,2}/g)?.join(":") || "";
};
