import React, { useState } from "react";
import { Col, Dropdown, Row, Table, Tag, Typography } from "antd";
import type { MenuProps, TableProps } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import "./../../styles/tables.css";

import { useNavigate } from "react-router-dom";
import { formatDateForJustMonthDayAndYear } from "../../helpers/date";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";

// const TailSpinLoader = lazy(() => import("../Items/Loaders/TailSpinLoader"));

const ViewCompaniesTable: React.FC<CompaniesTableProp> = ({
  companies,
  isLoading,
}) => {
  const [activeRow, setActiveRow] = useState<Company | null>(null);

  const staffOptions: MenuProps["items"] = [
    {
      key: "VIEW_STAFF",
      label: (
        <Typography className="general-label-class">View Staff</Typography>
      ),
    },
    {
      key: "VIEW_ROUTERS",
      label: (
        <Typography className="general-label-class">View Routers</Typography>
      ),
    },
  ];

  const handleMenuClick = (e: any) => {
    if (!activeRow) {
      return;
    }

    switch (e.key) {
      case "VIEW_STAFF":
        return navigate(
          `/super-admin/company-staff-details/${activeRow.intPkCompanyID}`
        );
        break;
      case "VIEW_ROUTERS":
        return navigate(
          `/super-admin/company-routers-details/${activeRow.intPkCompanyID}`
        );
        break;
    }
  };

  const navigate = useNavigate();
  const columns: TableProps<Company>["columns"] = [
    {
      title: "Code",
      render: (row) => <span>{`${row.intPkCompanyID}`}</span>,
    },
    {
      title: "Name",
      render: (row) => <span>{`${row.companyName}`}</span>,
    },

    {
      title: "Created",
      dataIndex: "dateCreated",
      render: (text) => (
        <span>{formatDateForJustMonthDayAndYear(new Date(text))}</span>
      ),
    },

    {
      title: "Action",
      render: (row) => (
        <Dropdown
          menu={{
            items: staffOptions,
            onClick: handleMenuClick,
          }}
          placement="bottomRight"
        >
          <MenuOutlined
            onMouseEnter={(e) => {
              setActiveRow(row);
            }}
            className="cursor-pointer"
          />
        </Dropdown>
      ),
    },
  ];

  return (
    <div className="general-table-container">
      {isLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row>
          <Col span={24}>
            <Typography className="general-table-heading">
              Companies List
            </Typography>
            <Table
              className=" striped-table"
              columns={columns}
              dataSource={companies}
              onRow={(record, rowIndex) => {
                return {};
              }}
              pagination={false}
            />
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
              marginBottom: "20px",
            }}
            span={24}
          ></Col>
        </Row>
      )}
    </div>
  );
};
export default ViewCompaniesTable;
