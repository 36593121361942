import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ViewRoutersComponent from "../components/companies/ViewCompanyRouters";

const ViewCompanyRoutersBySuperAdminPage = () => {
  const { setTitle } = useContext(TitleContext);
  useEffect(() => {
    setTitle("Company Routers");
  }, [setTitle]);
  return (
    <>
      <ViewRoutersComponent />
    </>
  );
};
export default ViewCompanyRoutersBySuperAdminPage;
