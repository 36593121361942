import { useEffect, useState } from "react";
import useAxiosPost from "../../hooks/useAxiosPost";
import { Col, Row } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import MeshBillTabs from "../Items/Tabs";
import ViewRadiusConnectedPPPoeUsers from "./RadiusConnected";
import ViewApiConnectedPPPoeUsers from "./ApiConnected";

// const ApiConnected = lazy(() => import("./ApiConnected"));
const ViewConnectedPPPoeUsersComponent = () => {
  const [searchParams] = useSearchParams();
  const _activeTab = searchParams.get("activeTab") || 1;
  const [activeTab, setActiveTab] = useState<number>(Number(_activeTab));
  const [tabs, setTabs] = useState<MeshBillTabItemsProp[]>([
    {
      text: "Radius",
      isActive: true,
      id: 1,
    },

    {
      text: "API",
      isActive: false,
      id: 2,
    },
  ]);
  const navigate = useNavigate();
  const handleTabSwitch = (tabId: number) => {
    navigate(`/pppoe-users/connected-users?activeTab=${tabId}`);
  };
  useEffect(() => {
    setActiveTab(Number(_activeTab));
    const updatedItems = tabs.map((item) => ({
      ...item,
      isActive: item.id === Number(_activeTab),
    }));
    setTabs(updatedItems);
  }, [activeTab, _activeTab]);
  const showActiveComponent = (activeTab: number) => {
    switch (activeTab) {
      case 1:
        return <ViewRadiusConnectedPPPoeUsers />;
      case 2:
        return <ViewApiConnectedPPPoeUsers />;
      default:
        return <ViewRadiusConnectedPPPoeUsers />;
    }
  };
  return (
    <>
      <Row className="full-width-row">
        <MeshBillTabs
          colSpan={12}
          tabs={tabs}
          handleTabSwitch={handleTabSwitch}
        />
      </Row>
      <Row className="full-width-row mt-20" gutter={[16, 16]}>
        {showActiveComponent(activeTab)}
      </Row>
    </>
  );
};
export default ViewConnectedPPPoeUsersComponent;
