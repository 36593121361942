import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ViewAllCompaniesComponent from "../components/companies/ViewAllCompanies";
// const ViewAllCompaniesComponent = lazy(
//   () => import("../components/companies/ViewAllCompanies")
// );

const ViewAllCompaniesPage = () => {
  const { setTitle } = useContext(TitleContext);
  useEffect(() => {
    setTitle("Companies");
  }, [setTitle]);
  return (
    <>
      <ViewAllCompaniesComponent />
    </>
  );
};
export default ViewAllCompaniesPage;
