import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ViewPPPoEUsersComponent from "../components/PPPoEUsers/ViewUsersComponent";
// const ViewUsersComponent = lazy(
//   () => import("../components/PPPoEUsers/ViewUsersComponent")
// );

const PPPoEUsersPage = () => {
  const { setTitle } = useContext(TitleContext);
  useEffect(() => {
    setTitle("PPPoE Users");
  }, [setTitle]);
  return (
    <>
      <ViewPPPoEUsersComponent />
    </>
  );
};
export default PPPoEUsersPage;
