import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const MonthlyBilledAndPaymentsChart: React.FC<
  BilledAndPaymentsStatsChartProps
> = ({ data }) => {
  const categories = data.map((item) => item.month_name);
  const totalBilled = data.map((item) => item.total_billed);
  const totalReceived = data.map((item) => item.total_received);

  const chartOptions: ApexOptions = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        //  endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: categories,
      labels: {
        rotate: -75, // Rotate the labels to display them vertically
        rotateAlways: true, // Ensure rotation is applied

        style: {
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      title: {
        text: "Amount (PKR)",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: (val) => "PKR " + val,
      },
    },
  };

  const series = [
    {
      name: "Total Billed",
      data: totalBilled,
    },
    {
      name: "Total Received",
      data: totalReceived,
    },
  ];

  return (
    <div>
      <ReactApexChart
        options={chartOptions}
        series={series}
        type="bar"
        height={400}
      />
    </div>
  );
};

export default MonthlyBilledAndPaymentsChart;
