import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import "./../../../styles/reports.css";
// const MeshBillTabs = lazy(() => import("../../Items/Tabs"));
// const AllPackagesReports = lazy(() => import("./AllPackagesReports"));
// const PackageWiseReport = lazy(() => import("./PackageWiseReport"));
// const GeneralSalesReport = lazy(() => import("./GeneralSalesReport"));
import { useNavigate, useSearchParams } from "react-router-dom";
import GeneralSalesReport from "./GeneralSalesReport";
import AllPackagesRetailReports from "./AllPackagesReports";
import PackageWiseReport from "./PackageWiseReport";
import MeshBillTabs from "../../Items/Tabs";

const CompanyRetailsSalesChartsComponent = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const _activeTab = searchParams.get("activeTab") || 1;
  const [activeTab, setActiveTab] = useState<number>(Number(_activeTab));
  const [tabs, setTabs] = useState<MeshBillTabItemsProp[]>([
    {
      text: "General",
      isActive: true,
      id: 1,
    },
    {
      text: "All Packages",
      isActive: true,
      id: 2,
    },

    {
      text: "Package Wise",
      isActive: false,
      id: 3,
    },
  ]);
  const handleTabSwitch = (tabId: number) => {
    navigate(`/reports/company-retails-sales?activeTab=${tabId}`);
  };

  useEffect(() => {
    setActiveTab(Number(_activeTab));
    const updatedItems = tabs.map((item) => ({
      ...item,
      isActive: item.id === Number(_activeTab),
    }));
    setTabs(updatedItems);
  }, [activeTab, _activeTab]);

  const showActiveComponent = (activeTab: number) => {
    switch (activeTab) {
      case 1:
        return <GeneralSalesReport />;
      case 2:
        return <AllPackagesRetailReports />;
      case 3:
        return <PackageWiseReport />;
      default:
        return <GeneralSalesReport />;
    }
  };
  return (
    <>
      <Row className="full-width-row mb-20">
        <MeshBillTabs
          colSpan={8}
          tabs={tabs}
          handleTabSwitch={handleTabSwitch}
        />
      </Row>
      <Row className="full-width-row" gutter={[16, 16]}>
        {showActiveComponent(activeTab)}
      </Row>
    </>
  );
};
export default CompanyRetailsSalesChartsComponent;
