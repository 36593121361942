import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import TotalUsersCard from "./TotalUsersCard";
import ActiveUsersCard from "./ActiveUsersCard";
import OnlineUsersCard from "./OnlineUsersCard";
import RecentExpiredUsersCard from "./RecentExpiredUsersCard";
import ExpiredUsersCard from "./ExpiredUsersCard";
import DisabledUsersCard from "./DisabledUsersCard";
import ExpiringUsersCard from "./ExpiringUsersCard";
import CashRecoveryCard from "./CashRecoveryCard";
import OfflineUsersCard from "./OfflineUsersCard";

const DashboardCardsWrapper = () => {
  return (
    <>
      <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
        <Col md={6} lg={4} xl={4} xxl={4} sm={24} xs={24}>
          <TotalUsersCard />
        </Col>
        <Col md={6} lg={4} xl={4} xxl={4} sm={24} xs={24}>
          <ActiveUsersCard />
        </Col>
        <Col md={6} lg={4} xl={4} xxl={4} sm={24} xs={24}>
          <OnlineUsersCard />
        </Col>
        <Col md={6} lg={4} xl={4} xxl={4} sm={24} xs={24}>
          <OfflineUsersCard />
        </Col>
        <Col md={6} lg={4} xl={4} xxl={4} sm={24} xs={24}>
          <RecentExpiredUsersCard />
        </Col>
        <Col md={6} lg={4} xl={4} xxl={4} sm={24} xs={24}>
          <ExpiredUsersCard />
        </Col>
        <Col md={6} lg={4} xl={4} xxl={4} sm={24} xs={24}>
          <DisabledUsersCard />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
        <ExpiringUsersCard />
      </Row>
      <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
        <CashRecoveryCard />
      </Row>
    </>
  );
};
export default DashboardCardsWrapper;
