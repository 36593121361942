import { useEffect, useState } from "react";
import useAxiosPost from "../../hooks/useAxiosPost";
import { Col, Row } from "antd";
import { ButtonProps } from "../../helpers/constants";
import { FiPlusCircle } from "react-icons/fi";
import CreateNewRadiusProbeModalComponent from "../Modals/CreateNewRadiusProbe";
import MeshBillButton from "../Items/Button/Index";
import ViewRadiusProbesTable from "../Tables/ViewRadiusProbesTable";

// const MeshBillButton = lazy(() => import("../Items/Button/Index"));
// const ViewRadiusProbesTable = lazy(
//   () => import("../Tables/ViewRadiusProbesTable")
// );
// const CreateNewRadiusProbeModalComponent = lazy(
//   () => import("../Modals/CreateNewRadiusProbe")
// );

const ViewRadiusProbesComponent = () => {
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("radiusProbes/getRadiusProbes");
  const [probes, setProbes] = useState<RadiusProbe[]>([]);
  const [showCreate, setShowCreate] = useState<boolean>(false);
  useEffect(() => {
    postApiData({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      console.log({ data });
      setProbes(data || []);
    }
  }, [apiResponse]);
  const handleClose = (isReload: boolean) => {
    setShowCreate(false);
    if (isReload) {
      postApiData({});
    }
  };

  return (
    <>
      {showCreate === true && (
        <CreateNewRadiusProbeModalComponent
          show={showCreate}
          handleClose={handleClose}
        />
      )}

      <Row className="full-width-row">
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
          }}
          span={24}
        >
          <MeshBillButton
            backgroundColor={ButtonProps.background.STANDARD}
            height={ButtonProps.height.STANDARD}
            width={ButtonProps.width.STANDARD}
            textColor={ButtonProps.color.STANDARD}
            onClick={() => {
              setShowCreate(true);
            }}
            text="Create Probe"
            icon={<FiPlusCircle />}
          />
        </Col>
        <Col span={24}>
          <ViewRadiusProbesTable isLoading={apiLoading} probes={probes} />
        </Col>
      </Row>
    </>
  );
};
export default ViewRadiusProbesComponent;
