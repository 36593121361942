import React, { useState } from "react";
import { Col, Dropdown, Row, Table, Tag, Typography, Input } from "antd";
import type { MenuProps, TableProps } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import "./../../styles/tables.css";

import { useNavigate } from "react-router-dom";
import {
  formatDate,
  formatDateForJustMonthDayAndYear,
} from "../../helpers/date";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";

// const TailSpinLoader = lazy(() => import("../Items/Loaders/TailSpinLoader"));

const ReportTableForDealerLedger: React.FC<DealerLedgerReportsTableProp> = ({
  reports,
  isLoading,
  handleViewEndUserDetails,
}) => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: reports.length, // Total number of items
  });

  // Handle page size change
  const handlePageSizeChange = (value: number) => {
    setPagination({
      ...pagination,
      pageSize: value,
      current: 1, // Reset to first page when page size changes
    });
  };

  // Handle page change
  const handleTableChange = (pagination: any) => {
    setPagination(pagination);
  };
  const columns: TableProps<DealerLedgerReportRow>["columns"] = [
    {
      title: "Transaction Date",
      render: (row) => <span>{formatDate(row.dealerTransactionCreated)}</span>,
    },
    {
      title: "Activity",
      render: (row) => (
        <span>{row.topUpAmount !== 0 ? "Payment" : "Invoice"}</span>
      ),
    },
    {
      title: "Transaction ID",
      render: (row) => <span>{row.intPkDealerTransactionID}</span>,
    },
    {
      title: "Invoice ID",
      render: (row) => (
        <span>
          {row.intPkEndUserInvoiceID ? row.intPkEndUserInvoiceID : ""}
        </span>
      ),
    },
    {
      title: "Customer UserName",
      render: (row) => (
        <span
          style={{ color: "#3992ff" }}
          onClick={(e) => {
            if (row.username) {
              handleViewEndUserDetails(row);
            }
          }}
        >
          {row.username ? row.username : ""}
        </span>
      ),
    },
    {
      title: "Package Name",
      render: (row) => <span>{row.packageName ? row.packageName : ""}</span>,
    },
    {
      title: "Amount",
      render: (row) => (
        <span>
          {row.topUpAmount !== 0
            ? row.topUpAmount.toLocaleString()
            : `-${row.consumedAmount.toLocaleString()}`}
        </span>
      ),
    },
    {
      title: "Balance",
      render: (row) => <span>{row.balance.toLocaleString()}</span>,
    },
  ];

  return (
    <div className="general-table-container">
      {isLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row>
          <Col span={24}>
            <Typography className="general-table-heading">
              Dealer Ledger Reports
            </Typography>
            <Table
              className=" striped-table"
              columns={columns}
              dataSource={reports}
              scroll={{ y: 700, x: 1300 }}
              pagination={{
                ...pagination,
                showSizeChanger: true, // Show page size changer
                pageSizeOptions: ["5", "10", "20", "50", "100"], // Options for page sizes
                onChange: (page, pageSize) =>
                  handleTableChange({ ...pagination, current: page, pageSize }),
                onShowSizeChange: (current, size) => handlePageSizeChange(size),
              }}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};
export default ReportTableForDealerLedger;
