export const convertBytesToKMGBits = (bytes: number) => {
  if (bytes <= 0) return 0;

  const kilobits = (bytes * 8) / 1000;
  const megabits = kilobits / 1000;
  const gigabits = megabits / 1000;

  //   if (gigabits >= 1) return gigabits.toFixed(2) + ' Gbps';
  //   if (megabits >= 1) return megabits.toFixed(2) + ' Mbps';

  // return megabits.toFixed(2) + " Mbps";
  return megabits.toFixed(2);
};
export const convertBytesToKMGBytes = (bytes: number) => {
  if (bytes <= 0) return "0 KB";

  const kilobytes = bytes / 1024;
  const megabytes = kilobytes / 1024;
  const gigabytes = megabytes / 1024;

  if (gigabytes >= 1) return gigabytes.toFixed(2) + " GB";
  if (megabytes >= 1) return megabytes.toFixed(2) + " MB";
  if (kilobytes >= 1) return kilobytes.toFixed(2) + " KB";
  return bytes.toFixed(2) + " Bytes";
};
