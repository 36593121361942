import React, { useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Row,
  Table,
  Tag,
  Typography,
  Input,
} from "antd";
import type { MenuProps, TableProps } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import "./../../styles/tables.css";

import { useNavigate } from "react-router-dom";
import { formatDate } from "../../helpers/date";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";
import { SearchProps } from "antd/es/input";
const { Search } = Input;

const APIConnectedPPPoEUsersTable: React.FC<
  ApiConnectedPPPoEUsersTableProp
> = ({
  users,
  isLoading,
  handleViewGraph,
  handleDisconnectUser,
  handleSearch,
}) => {
  const [activeRow, setActiveRow] = useState<ApiConnectedUser | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const navigate = useNavigate();

  const columns: TableProps<ApiConnectedUser>["columns"] = [
    {
      title: "UserName",
      render: (row) => <span>{`${row.username}`}</span>,
    },
    {
      title: "Mac Address",
      render: (row) => <span>{`${row.macAddress}`}</span>,
    },
    {
      title: "IP Address",
      render: (row) => <span>{`${row.ipAddress}`}</span>,
    },
    {
      title: "Up time",
      render: (row) => <span>{`${row.uptime}`}</span>,
    },

    {
      title: "Vlan",
      render: (row) => <span>{`${row.interfaceObj.layer2Interface}`}</span>,
    },
    {
      title: "Upload",
      render: (row) => (
        <span>{`${row.interfaceObj.extendedInterfaceObj.uploadSpeedMbs} `}</span>
      ),
    },
    {
      title: "Download",
      render: (row) => (
        <span>{`${row.interfaceObj.extendedInterfaceObj.downloadSpeedMbs} `}</span>
      ),
    },
    {
      title: "Auth",
      render: (row) => (
        <span>{`${row.radius === true ? "Radius" : "Local"} `}</span>
      ),
    },
    {
      title: "View Graph",
      render: (row) => (
        <Button
          type="primary"
          onClick={(e) => {
            handleViewGraph(row);
          }}
        >
          View Graph
        </Button>
      ),
    },
    {
      title: "Disconnect",
      render: (row) => (
        <Button
          type="primary"
          danger
          onClick={(e) => {
            handleDisconnectUser(row);
          }}
        >
          Disconnect
        </Button>
      ),
    },
  ];
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    handleSearch(e.target.value);
  };
  const onSearch: SearchProps["onSearch"] = (value, _e, info) => {
    console.log(info?.source, value);

    handleSearch(value);
  };
  return (
    <div className="general-table-container">
      {isLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row>
          <Col span={24}>
            <div className="users-search-container">
              <Typography className="general-table-heading">
                API Connected Users
              </Typography>
              <Search
                placeholder=" Username / IP / MAC"
                onSearch={onSearch}
                value={searchTerm}
                allowClear
                onChange={handleChange}
                style={{ width: 300 }}
              />
            </div>
            <Table
              className="striped-table"
              columns={columns}
              dataSource={users}
              pagination={false}
              scroll={{ y: 700, x: 1300 }}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};
export default APIConnectedPPPoEUsersTable;
