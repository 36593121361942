import React, { useState } from "react";
import { Col, Dropdown, Row, Table, Tag, Typography } from "antd";
import type { MenuProps, TableProps } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import "./../../styles/tables.css";

import { useNavigate } from "react-router-dom";
import { formatDate } from "../../helpers/date";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";

const InvoiceSOATable: React.FC<InvoiceSOATableProp> = ({
  inovice,
  isLoading,
}) => {
  const [activeRow, setActiveRow] = useState<Soa | null>(null);

  const columns: TableProps<Soa>["columns"] = [
    {
      title: "Amount",
      render: (row) => <strong>{`${row.amount}`}</strong>,
    },
    {
      title: "Staff",
      render: (row) => (
        <strong>{`${row.firstName || ""}  ${row.lastName || ""} `}</strong>
      ),
    },
    {
      title: "Created",
      dataIndex: "dateCreated",
      render: (text) => <span>{formatDate(new Date(text))}</span>,
    },
  ];

  return (
    <div className="general-table-container">
      {isLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row>
          <Col span={24}>
            <Typography className="general-table-heading">
              Invoice SOA Transactions
            </Typography>
            <Table
              className=" striped-table"
              columns={columns}
              dataSource={inovice.soa}
              onRow={(record, rowIndex) => {
                return {};
              }}
              pagination={false}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};
export default InvoiceSOATable;
