import { useEffect, useState } from "react";
import { Col, Row, Form, Input, DatePicker } from "antd";
import { ButtonProps } from "../../../helpers/constants";
import { FiPlusCircle } from "react-icons/fi";
import moment from "moment";
import dayjs from "dayjs";
import useMultiNotification from "../../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
import useAxiosPost from "../../../hooks/useAxiosPost";
import TailSpinLoader from "../../Items/Loaders/TailSpinLoader";
import ViewEndUserAAALogsTable from "../../Tables/ViewEndUserAAALogsTable";

const ViewUserAAALogsComponent: React.FC<EndUserProfileComponent> = ({
  intPkEndUserID,
}) => {
  const { openNotification, contextHolder } = useMultiNotification();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<SingleEndUserProfileApiProp>("endUsers/getMyEndUserAAALogs");
  const [logs, setLogs] = useState<EndUserAAALog[]>([]);
  const [pagination, setPagination] = useState<PaginationProp>({
    totalPages: 0,
    currentPage: 1,
    previousPage: null,
    nextPage: null,
    totalCount: 0,
    pageSize: 50,
  });

  useEffect(() => {
    postApiData({ intPkEndUserID, page: 1 });
  }, []);

  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      const {
        currentPage,
        nextPage,
        pageSize,
        previousPage,
        totalCount,
        totalPages,
        logs,
      } = data;
      setPagination({
        currentPage,
        totalPages,
        nextPage,
        previousPage,
        totalCount,
        pageSize,
      });
      setLogs(logs || []);
    }
  }, [apiResponse]);
  if (apiLoading) {
    return <TailSpinLoader />;
  }
  const handlePageSwitch = (pageNumber: number) => {
    postApiData({ page: pageNumber, intPkEndUserID });
  };

  const handleClose = (isReload: boolean) => {
    if (isReload) {
      postApiData({ intPkEndUserID, page: pagination.currentPage });
    }
  };
  return (
    <>
      {contextHolder}

      <Col span={24} style={{ marginTop: "20px" }}>
        {" "}
      </Col>
      <ViewEndUserAAALogsTable
        isLoading={apiLoading}
        handlePageSwitch={handlePageSwitch}
        pagination={pagination}
        logs={logs}
      />
    </>
  );
};
export default ViewUserAAALogsComponent;
