import { useEffect, useState } from "react";
import { Row, Col, Typography, Form, Input, Button, Checkbox } from "antd";
import "./../auth/styles/Login.css";
// import LogoImage from "./../../assets/pngs/logo/LogoBlue.png";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
const LoginComponentToDeleteAccount = () => {
  const navigate = useNavigate();

  const { openNotification, contextHolder } = useMultiNotification();

  const [
    postLoginData,
    loginResponseData,
    loginError,
    loginLoading,
    loginMessage,
    loginErrorCount,
  ] = useAxiosPost<LoginType>("auth/login");
  const onFinish = (values: any) => {
    // Handle form submission, values.email will contain the entered email address
    const { username, password, code } = values;
    const dataToSend: LoginType = {
      username: username,
      password: password,
      code: code,
    };
    postLoginData(dataToSend);
  };

  const onFinishFailed = (errorInfo: any) => {};
  useEffect(() => {
    if (loginError !== null && loginErrorCount !== 0) {
      openNotification(`error`, "Login Failed", loginError);
    }
  }, [loginError, loginErrorCount]);
  useEffect(() => {
    if (loginResponseData !== null) {
      const responseData: any = loginResponseData.responseData;
      const { token, role, isProfileCompleted } = responseData;
      localStorage.setItem("authTokenToDeleteAccount", token || "");
      window.location.href = "/delete-account";
    }
  }, [loginResponseData]);
  return (
    <>
      {contextHolder}
      <div className="login-page-background">
        <div className="login-container">
          <Row gutter={[16, 1]} style={{ height: "100%" }}>
            <Col span={24}>
              <div className="logo-login-form">
                {/* <img
                  style={{ width: "300px", height: "100px" }}
                  src={LogoImage}
                  alt="Logo Image"
                /> */}
                <Typography className="meshbill-basic-login-logo">
                  MeshBill Basic
                </Typography>
              </div>

              <Form
                className="login-form"
                name="emailForm"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                requiredMark={false}
              >
                <Form.Item
                  label={
                    <span className="create-user-form-label">UserName</span>
                  }
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Username",
                    },
                  ]}
                  style={{ marginBottom: "8px" }}
                >
                  <Input
                    style={{ height: "60px" }}
                    placeholder="Enter your Username"
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <span className="create-user-form-label">Password</span>
                  }
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Password",
                    },
                    {
                      min: 6,
                      message: "Minimum Characters for password are 6",
                    },
                  ]}
                  style={{ marginBottom: "8px" }}
                >
                  <Input.Password
                    style={{ height: "60px" }}
                    className="login-form-input-item"
                    placeholder="Enter your Password"
                  />
                </Form.Item>
                <Row>
                  <Col
                    style={{ display: "flex", justifyContent: "center" }}
                    span={24}
                  >
                    <Form.Item>
                      <NetwalaButton
                        htmlType="submit"
                        backgroundColor={ButtonProps.background.STANDARD}
                        height={ButtonProps.height.STANDARD}
                        width={ButtonProps.width.STANDARD}
                        textColor={ButtonProps.color.STANDARD}
                        onClick={() => {}}
                        text="Login"
                        disabled={loginLoading}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default LoginComponentToDeleteAccount;
