import { useEffect, useState } from "react";
import useAxiosPost from "../../hooks/useAxiosPost";
import { Col, Row } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import ViewStaffAuditLogsTable from "../Tables/ViewStaffAuditLogsTable";
import ViewPackagesAuditLogsTable from "../Tables/ViewPackagesAuditLogsTable";
import ViewEndUsersAuditLogsTable from "../Tables/ViewEndUsersAuditLogsTable";
import MeshBillSelect from "../Items/Select/Index";

const ViewAuditLogsComponent = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const _activeTab = (searchParams.get("activeTab") || "STAFF").toUpperCase();
  console.log({ _activeTab });
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<GetAuditLogsApiProp>("logs/getCompanyAuditLogs");
  const [auditLogs, setAuditLogs] = useState<any[]>([]);
  const [pagination, setPagination] = useState<PaginationProp>({
    totalPages: 0,
    currentPage: 1,
    previousPage: null,
    nextPage: null,
    totalCount: 0,
    pageSize: 50,
  });

  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      console.log({ data });
      const {
        currentPage,
        nextPage,
        pageSize,
        previousPage,
        totalCount,
        totalPages,
        logs,
      } = data;
      setPagination({
        currentPage,
        totalPages,
        nextPage,
        previousPage,
        totalCount,
        pageSize,
      });
      setAuditLogs(logs || []);
    }
  }, [apiResponse]);
  const handlePageSwitch = (pageNumber: number) => {
    postApiData({ groupID: _activeTab, page: pageNumber });
  };
  useEffect(() => {
    if (_activeTab) {
      postApiData({ groupID: _activeTab, page: 1 });
    }
  }, [_activeTab]);

  const getActiveComponent = (activeTab: string) => {
    switch (activeTab) {
      case "PACKAGES":
        return (
          <ViewPackagesAuditLogsTable
            isLoading={apiLoading}
            logs={auditLogs}
            handlePageSwitch={handlePageSwitch}
            pagination={pagination}
          />
        );
      case "STAFF":
        return (
          <ViewStaffAuditLogsTable
            isLoading={apiLoading}
            logs={auditLogs}
            handlePageSwitch={handlePageSwitch}
            pagination={pagination}
          />
        );
      case "END_USER":
        return (
          <ViewEndUsersAuditLogsTable
            isLoading={apiLoading}
            logs={auditLogs}
            handlePageSwitch={handlePageSwitch}
            pagination={pagination}
          />
        );
      default:
        return (
          <ViewStaffAuditLogsTable
            isLoading={apiLoading}
            logs={auditLogs}
            handlePageSwitch={handlePageSwitch}
            pagination={pagination}
          />
        );
    }
  };
  return (
    <>
      <Row className="full-width-row">
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: "20px",
          }}
          lg={6}
          xl={6}
          xxl={6}
          md={12}
          sm={24}
          span={18}
        >
          <MeshBillSelect
            size="small"
            height="45px"
            value={_activeTab}
            onChange={(e) => {
              console.log(e);
              navigate(`/audit-logs?activeTab=${e}`);
            }}
            options={[
              { label: "Staff Logs", value: "STAFF" },
              { label: "Packages Logs", value: "PACKAGES" },
              { label: "End Users Logs", value: "END_USER" },
            ]}
          />
        </Col>
        {/* <Col span={24}>
          <ViewStaffAuditLogsTable
            isLoading={apiLoading}
            logs={auditLogs}
            handlePageSwitch={handlePageSwitch}
            pagination={pagination}
          />
        </Col> */}
        <Col span={24}>{getActiveComponent(_activeTab)}</Col>
      </Row>
    </>
  );
};
export default ViewAuditLogsComponent;
