import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ImportSecretsComponent from "../components/importUsers/Index";
// const ImportSecretsComponent = lazy(
//   () => import("../components/importUsers/Index")
// );

const SyncSecretsPage = () => {
  const { setTitle } = useContext(TitleContext);
  useEffect(() => {
    setTitle("Import PPPoE Users");
  }, [setTitle]);
  return (
    <>
      <ImportSecretsComponent />
    </>
  );
};
export default SyncSecretsPage;
