import React, { useEffect, useState } from "react";

import { FaUserSlash } from "react-icons/fa";
import useAxiosPost from "../../../hooks/useAxiosPost";
import SingleCard from "./SingleCard";

const DisabledUsersCard = ({}) => {
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>(
    "reports/dashboard/getDisabledUsersCountByOwner"
  );
  const [value, setValue] = useState<string>("0");
  useEffect(() => {
    postApiData({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      const disabledUsersCount = data.disabledUsersCount;
      console.log({ disabledUsersCount });
      setValue(disabledUsersCount);
    }
  }, [apiResponse]);
  return (
    <SingleCard
      label="Disabled Users"
      value={value}
      CardIcon={FaUserSlash}
      iconColor="#A9A9A9"
      isLoading={apiLoading}
      isLink
      route="/pppoe-users?activeTab=disabled"
    />
  );
};

export default DisabledUsersCard;
