import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import { getUserType } from "../helpers/userDetails";
import DashboardComponent from "../components/dashboard/Index";

const HomePage = () => {
  const { setTitle } = useContext(TitleContext);
  const role = getUserType();

  useEffect(() => {
    setTitle("Home");
  }, [setTitle]);

  return (
    <>{role === "ADMIN" ? <DashboardComponent /> : <p>Working On IT</p>}</>
  );
};
export default HomePage;
