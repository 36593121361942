import { useContext, useEffect, lazy } from "react";
import TitleContext from "../context/TitleContext";
import ViewFailedAuthLogsComponent from "../components/FailedAuthLogs/ViewFailedAuthLogs";
// const ViewFailedAuthLogsComponent = lazy(
//   () => import("../components/FailedAuthLogs/ViewFailedAuthLogs")
// );
const ViewFailedAuthLogsPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Failed Auth Logs");
  }, [setTitle]);
  return (
    <>
      <ViewFailedAuthLogsComponent />
    </>
  );
};
export default ViewFailedAuthLogsPage;
