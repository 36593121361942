import React, { useState } from "react";
import { Col, Dropdown, Row, Table, Tag, Typography } from "antd";
import type { MenuProps, TableProps } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import "./../../styles/tables.css";

import { useNavigate } from "react-router-dom";
import {
  formatDate,
  formatDateForJustMonthDayAndYear,
} from "../../helpers/date";
import { getUserType } from "../../helpers/userDetails";
import {
  getAmountClass,
  getAmountSign,
  getStaffFullName,
} from "../../helpers/constants";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";

const ViewStaffTransactionsTable: React.FC<StaffTransactionsTable> = ({
  transactions,
  isLoading,
  tableTitle,
  role,
}) => {
  const navigate = useNavigate();
  const columns: TableProps<Transaction>["columns"] = [
    {
      title: "Transaction Type",
      dataIndex: "row_enum",
      key: "row_enum",
    },
    {
      title: "Amount",

      render: (row) => (
        <span className={`${getAmountClass(role, row)}`}>
          {" "}
          {getAmountSign(role, row)}
          {row.amount}
        </span>
      ),
    },
    {
      title: "Transaction Date",
      render: (row) => <span>{formatDate(row.dateCreated)}</span>,
    },
    {
      title: "Customer Name",
      render: (row) => (
        <span>
          {row.endUserFirstName && row.endUserLastName
            ? row.endUserFirstName + " " + row.endUserLastName
            : "---"}
        </span>
      ),
    },
    {
      title: "Customer userName",
      render: (row) => (
        <span>
          {" "}
          <span>{row.username ? row.username : "---"}</span>
        </span>
      ),
    },
    {
      title: "Staff",
      render: (row) => (
        <span>
          {" "}
          <span>{getStaffFullName(role, row)}</span>
        </span>
      ),
    },
  ];

  return (
    <div className="general-table-container">
      {isLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row>
          <Col span={24}>
            <Typography className="general-table-heading">
              {tableTitle}
            </Typography>
            <Table
              className="striped-table"
              columns={columns}
              dataSource={transactions}
              onRow={(record, rowIndex) => {
                return {};
              }}
              pagination={false}
            />
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
              marginBottom: "20px",
            }}
            span={24}
          ></Col>
        </Row>
      )}
    </div>
  );
};
export default ViewStaffTransactionsTable;
