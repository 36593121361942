import React, { useEffect, useState } from "react";

import { FaUserTimes } from "react-icons/fa"; // for expired
import useAxiosPost from "../../../hooks/useAxiosPost";
import SingleCard from "./SingleCard";

const ExpiredUsersCard = ({}) => {
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>(
    "reports/dashboard/getExpiredUsersCountByOwner"
  );
  const [value, setValue] = useState<string>("0");
  useEffect(() => {
    postApiData({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      const totalExpiredUsers = data.totalExpiredUsers;
      console.log({ totalExpiredUsers });
      setValue(totalExpiredUsers);
    }
  }, [apiResponse]);
  return (
    <SingleCard
      label="Total Expired Users"
      value={value}
      CardIcon={FaUserTimes}
      iconColor="#b22222"
      isLoading={apiLoading}
      isLink
      route="/pppoe-users?activeTab=expired"
    />
  );
};

export default ExpiredUsersCard;
