import { useEffect, useState } from "react";
import "./../../styles/reports.css";
import useAxiosPost from "../../hooks/useAxiosPost";
import { Col, Row } from "antd";
import { ButtonProps } from "../../helpers/constants";
import { DatePicker, Space } from "antd";
import dayjs, { Dayjs } from "dayjs";
import MeshBillSelect from "../Items/Select/Index";
import MeshBillButton from "../Items/Button/Index";
import ReportTableForDealerPayment from "../Tables/ReportTableForDealerPayment";
const { RangePicker } = DatePicker;

// const MeshBillButton = lazy(() => import("../Items/Button/Index"));
// const ReportTableForDealerPayment = lazy(
//   () => import("../Tables/ReportTableForDealerPayment")
// );
const DealerPaymentsReport = () => {
  const [filters, setFilters] = useState<DealerPaymentReportFilter>({
    payer: "",
    receivedBy: "",
    transactionID: "",
    amount: "",
  });
  const [dates, setDates] = useState<[Dayjs | null, Dayjs | null]>([
    dayjs().subtract(1, "week"),
    dayjs(),
  ]);

  // Handle date range change
  const handleDateChange = (
    dates: [Dayjs | null, Dayjs | null] | null,
    dateStrings: [string, string]
  ) => {
    if (dates) {
      setDates(dates);
    }
  };

  const [dealersApi, dealersApiResponse] = useAxiosPost<ResponseProp>(
    "dealers/getMyDealers"
  );

  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<DealersPaymentApiProp>("reports/getMyDealerPaymentsReport");
  const [dealers, setDealers] = useState<MeshBillSelectOptions[]>([]);
  const [reports, setReports] = useState<DealerPaymentReportRow[]>([]);
  const [filteredReports, setFilteredReports] = useState<
    DealerPaymentReportRow[]
  >([]);
  const [activeDealer, setActiveDealer] = useState<any>("");
  useEffect(() => {
    dealersApi({});
    const dataToSend: DealersPaymentApiProp = {
      dealerID: activeDealer ? Number(activeDealer) : null,
      startDate: dates[0] ? dates[0].format("YYYY-MM-DD") : "",
      endDate: dates[1] ? dates[1].format("YYYY-MM-DD") : "",
    };
    postApiData(dataToSend);
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      console.log({ data });
      data.unshift({
        intPkDealerTransactionID: 0,
        staffGiven: 0,
        staffReceived: 0,
        topUpAmount: 0,
        consumedAmount: 0,
        dealerTransactionCreated: "",
        firstName: "",
        lastName: "",
        staffRole: "",
        staffUserName: "",
        receivedByUserName: "",
        isFilterRow: true,
      });
      setReports(data || []);
      setFilteredReports(data || []);
    }
  }, [apiResponse]);
  useEffect(() => {
    if (dealersApiResponse !== null) {
      const data: any = dealersApiResponse.responseData;
      console.log({ data });
      const dealers: MeshBillSelectOptions[] = [
        {
          label: "All",
          value: "",
        },
      ];
      for (const item of data) {
        dealers.push({
          label: `${item.firstName} ${item.lastName} (${item.staffUserName})`,
          value: item.intPkHeadID,
        });
      }
      setDealers(dealers);
    }
  }, [dealersApiResponse]);
  const handleGetReports = () => {
    const dataToSend: DealersPaymentApiProp = {
      dealerID: activeDealer ? Number(activeDealer) : null,
      startDate: dates[0] ? dates[0].format("YYYY-MM-DD") : "",
      endDate: dates[1] ? dates[1].format("YYYY-MM-DD") : "",
    };
    postApiData(dataToSend);
  };
  const handleFilterChange = (filterKey: string, value: string) => {
    setFilters({
      ...filters,
      [filterKey]: value,
    });
  };
  useEffect(() => {
    handleFilterData(filters, reports);
  }, [filters]);
  const handleFilterData = (
    _filters: DealerPaymentReportFilter,
    _reports: DealerPaymentReportRow[]
  ) => {
    if (_reports.length < 1) {
      return;
    }
    const { receivedBy, payer, transactionID, amount } = _filters;
    if (!receivedBy && !payer && !transactionID && !amount) {
      setFilteredReports(_reports);
      return;
    }
    const filtersRow = _reports[0];

    if (receivedBy) {
      _reports = _reports.filter((item) =>
        item.receivedByUserName.toLowerCase().includes(receivedBy.toLowerCase())
      );
    }
    if (payer) {
      _reports = _reports.filter((item) =>
        item.staffUserName.toLowerCase().includes(payer.toLowerCase())
      );
    }
    if (transactionID) {
      _reports = _reports.filter(
        (item) =>
          Number(item.intPkDealerTransactionID) === Number(transactionID)
      );
    }
    if (amount) {
      _reports = _reports.filter(
        (item) => Number(item.topUpAmount) === Number(amount)
      );
    }
    _reports.unshift(filtersRow);
    setFilteredReports(_reports);
  };
  return (
    <>
      <Row className="full-width-row">
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
          span={24}
        >
          <Space direction="vertical" size={12}>
            <div className="filter-item-and-control-container">
              <label className="filter-item-label">Choose Date:</label>
              <RangePicker
                value={dates}
                onChange={handleDateChange}
                format="YYYY-MM-DD"
                style={{ minHeight: "45px", width: "300px" }}
              />
            </div>
          </Space>
        </Col>

        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
          span={24}
        >
          <Space direction="vertical" size={12}>
            <div className="filter-item-and-control-container">
              <label className="filter-item-label">Choose Dealer:</label>
              <MeshBillSelect
                size="large"
                height="45px"
                value={activeDealer}
                onChange={(e) => {
                  console.log(e);
                  setActiveDealer(e);
                }}
                width="300px"
                options={dealers}
              />
            </div>
          </Space>
        </Col>

        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
          span={24}
        >
          <Space direction="vertical" size={12}>
            <MeshBillButton
              htmlType="submit"
              backgroundColor={ButtonProps.background.STANDARD}
              height={ButtonProps.height.STANDARD}
              width={ButtonProps.width.STANDARD}
              textColor={ButtonProps.color.STANDARD}
              onClick={() => {
                console.log("clicked");
                handleGetReports();
              }}
              text="View Report"
              disabled={apiLoading}
            />
          </Space>
        </Col>
        <Col span={24}>
          <ReportTableForDealerPayment
            reports={filteredReports}
            isLoading={apiLoading}
            filters={filters}
            handleFilterChange={handleFilterChange}
          />
        </Col>
      </Row>
    </>
  );
};
export default DealerPaymentsReport;
