import { useEffect, useState } from "react";
import "./../../styles/reports.css";
import useAxiosPost from "../../hooks/useAxiosPost";
import { Col, Row } from "antd";
import { ButtonProps } from "../../helpers/constants";
import { DatePicker, Space } from "antd";
import dayjs, { Dayjs } from "dayjs";
import MeshBillSelect from "../Items/Select/Index";
import ViewEndUserDetailsOfDealerLedgerModal from "../Modals/ViewEndUserDetailsOfDealerLedgerModal";
import ReportTableForDealerLedger from "../Tables/ReportTableForDealerLedger";
import MeshBillButton from "../Items/Button/Index";
const { RangePicker } = DatePicker;

// const MeshBillButton = lazy(() => import("../Items/Button/Index"));
// const ReportTableForDealerLedger = lazy(
//   () => import("../Tables/ReportTableForDealerLedger")
// );
const DealerLedgerReport = () => {
  const [filters, setFilters] = useState<DealerPaymentReportFilter>({
    payer: "",
    receivedBy: "",
    transactionID: "",
    amount: "",
  });
  const [dates, setDates] = useState<[Dayjs | null, Dayjs | null]>([
    dayjs().subtract(1, "week"),
    dayjs(),
  ]);

  // Handle date range change
  const handleDateChange = (
    dates: [Dayjs | null, Dayjs | null] | null,
    dateStrings: [string, string]
  ) => {
    if (dates) {
      setDates(dates);
    }
  };

  const [dealersApi, dealersApiResponse] = useAxiosPost<ResponseProp>(
    "dealers/getMyDealers"
  );

  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<DealersPaymentApiProp>("reports/getMyDealerLegerReport");
  const [dealers, setDealers] = useState<MeshBillSelectOptions[]>([]);
  const [reports, setReports] = useState<DealerLedgerReportRow[]>([]);
  const [activeRow, setActiveRow] = useState<DealerLedgerReportRow | null>(
    null
  );
  const [showEndUserDetails, setShowEndUserDetails] = useState<boolean>(false);
  const [filteredReports, setFilteredReports] = useState<
    DealerLedgerReportRow[]
  >([]);
  const [activeDealer, setActiveDealer] = useState<any>("");
  useEffect(() => {
    dealersApi({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      console.log({ data });
      setReports(data || []);
      setFilteredReports(data || []);
    }
  }, [apiResponse]);
  useEffect(() => {
    if (dealersApiResponse !== null) {
      const data: any = dealersApiResponse.responseData;
      console.log({ data });
      const dealers: MeshBillSelectOptions[] = [];
      for (const item of data) {
        dealers.push({
          label: `${item.firstName} ${item.lastName} (${item.staffUserName})`,
          value: item.intPkHeadID,
        });
      }
      setDealers(dealers);
    }
  }, [dealersApiResponse]);
  const handleGetReports = () => {
    const dataToSend: DealersPaymentApiProp = {
      dealerID: activeDealer ? Number(activeDealer) : null,
      startDate: dates[0] ? dates[0].format("YYYY-MM-DD") : "",
      endDate: dates[1] ? dates[1].format("YYYY-MM-DD") : "",
    };
    postApiData(dataToSend);
  };
  const handleViewEndUserDetails = (activeRow: DealerLedgerReportRow) => {
    if (activeRow.username) {
      setActiveRow(activeRow);
      setShowEndUserDetails(true);
    }
  };
  const handleClose = (isReload: boolean) => {
    setShowEndUserDetails(false);
  };
  return (
    <>
      {showEndUserDetails === true && activeRow !== null && (
        <ViewEndUserDetailsOfDealerLedgerModal
          show={showEndUserDetails}
          handleClose={handleClose}
          report={activeRow}
        />
      )}
      <Row className="full-width-row">
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
          span={24}
        >
          <Space direction="vertical" size={12}>
            <div className="filter-item-and-control-container">
              <label className="filter-item-label">Choose Date:</label>
              <RangePicker
                value={dates}
                onChange={handleDateChange}
                format="YYYY-MM-DD"
                style={{ minHeight: "45px", width: "300px" }}
              />
            </div>
          </Space>
        </Col>

        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
          span={24}
        >
          <Space direction="vertical" size={12}>
            <div className="filter-item-and-control-container">
              <label className="filter-item-label">Choose Dealer:</label>
              <MeshBillSelect
                size="large"
                height="45px"
                value={activeDealer}
                onChange={(e) => {
                  console.log(e);
                  setActiveDealer(e);
                }}
                width="300px"
                options={dealers}
              />
            </div>
          </Space>
        </Col>

        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
          span={24}
        >
          <Space direction="vertical" size={12}>
            <MeshBillButton
              htmlType="submit"
              backgroundColor={ButtonProps.background.STANDARD}
              height={ButtonProps.height.STANDARD}
              width={ButtonProps.width.STANDARD}
              textColor={ButtonProps.color.STANDARD}
              onClick={() => {
                console.log("clicked");
                if (!activeDealer) {
                  return;
                }
                handleGetReports();
              }}
              text="View Report"
              disabled={apiLoading || !activeDealer}
            />
          </Space>
        </Col>
        <Col span={24}>
          <ReportTableForDealerLedger
            reports={filteredReports}
            isLoading={apiLoading}
            handleViewEndUserDetails={handleViewEndUserDetails}
          />
        </Col>
      </Row>
    </>
  );
};
export default DealerLedgerReport;
