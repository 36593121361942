import { Col, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import useAxiosPost from "../../../hooks/useAxiosPost";
import TopActivePackagesChart from "../../Charts/TopActivePackagesChart";

function TopActivePackagesStats() {
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>(
    "reports/dashboard/topActivePackagesStatsByOwner"
  );

  const [data, setData] = useState<TopActivePackagesRecord[]>([]);

  useEffect(() => {
    postApiData({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      console.log({ data });
      setData(data || []);
    }
  }, [apiResponse]);
  return (
    <Row className="chart-container">
      <div className="chart-container-header">
        <Typography className="chart-container-heading">
          Top Active Packages
        </Typography>
      </div>
      <Col span={24}>
        <TopActivePackagesChart data={data} />{" "}
      </Col>
    </Row>
  );
}

export default TopActivePackagesStats;
