import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input, Checkbox } from "antd";
import { ButtonProps } from "../../helpers/constants";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import "./../../styles/modal.css";
import { FiPlusCircle } from "react-icons/fi";
import MeshBillModal from "../Items/Modal/Index";
import MeshBillSelect from "../Items/Select/Index";
import MeshBillButton from "../Items/Button/Index";
// const MeshBillSelect = lazy(() => import("../Items/Select/Index"));
// const MeshBillModal = lazy(() => import("../Items/Modal/Index"));
// const MeshBillButton = lazy(() => import("../Items/Button/Index"));

const CreatePackageModalComponent: React.FC<GeneralModalProps> = ({
  show,
  handleClose,
}) => {
  const { openNotification, contextHolder } = useMultiNotification();
  const [form] = Form.useForm();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<CreateNewPackageApiProp>("packages/createNewPackage", true);
  const [checked, setChecked] = useState<boolean>(false);
  const [isCreateDynamicQueueChecked, setIsCreateDynamicQueueChecked] =
    useState<boolean>(true);
  const onChange = (e: any) => {
    setChecked(e.target.checked);
  };
  const onChangeQueue = (e: any) => {
    setIsCreateDynamicQueueChecked(e.target.checked);
  };

  const onFinish = (values: any) => {
    const {
      speed,
      poolName,
      speedAbbreviation,
      salePrice,
      packageName,
      packageDuration,
      profileName,
    } = values;
    if (!profileName && !poolName) {
      openNotification(
        "error",
        "Error",
        `Either Choose PoolName or Profile Name`
      );
      return;
    }

    const dataToSend: CreateNewPackageApiProp = {
      isVariableRechargeAllowed: checked === true ? 1 : 0,
      packageDuration,
      packageName,
      salePrice,
      speedAbbreviation: speedAbbreviation ? speedAbbreviation : null,
      speedDown: speed ? speed : 0,
      speedUp: speed ? speed : 0,
      poolName: poolName ? poolName : null,
      profileName: profileName ? profileName : null,
      isCreateDynamicQueue: isCreateDynamicQueueChecked === true ? 1 : 0,
    };
    postApiData(dataToSend);
  };
  useEffect(() => {
    if (apiResponse !== null) {
      const { message } = apiResponse;
      if (message) {
        openNotification("success", "Success", message);
        setTimeout(() => {
          handleClose(true);
        }, 2500);
      }
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification("error", "Error", apiError);
    }
  }, [apiError, apiErrorCount]);
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <MeshBillModal
        title="Create Package"
        headerStyle={{
          backgroundColor: "var(--primary-background-color)",
          color: "var(--primary-text-color)",
          fontSize: "16px",
          fontWeight: "var(--fw-700)",
          height: "50px",
        }}
        open={show}
        onCancel={() => {
          if (apiLoading) {
            return;
          }
          handleClose(false);
        }}
      >
        {contextHolder}

        <Row>
          <Col
            span={24}
            className="meshbill-create-record-modal-form-container"
          >
            <Form
              //   requiredMark={false}
              style={{ width: "100%", marginTop: "50px" }}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              name="createNewPackageForm"
              initialValues={{ nasDeviceApiPort: 8728 }}
              form={form}
            >
              <Form.Item
                label={
                  <span className="create-user-form-label">Package Name</span>
                }
                name="packageName"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Package Name",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="City-5MB"
                />
              </Form.Item>

              <Form.Item
                label={
                  <span className="create-user-form-label">Pool Name</span>
                }
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Enter Pool Name",
                //   },
                // ]}
                name="poolName"
              >
                <Input
                  className="user-input-general-height"
                  placeholder="Pool-1"
                />
              </Form.Item>

              <Form.Item
                label={
                  <span className="create-user-form-label">Profile Name</span>
                }
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Enter Profile Name",
                //   },
                // ]}
                name="profileName"
              >
                <Input
                  className="user-input-general-height"
                  placeholder="Pool-1"
                />
              </Form.Item>

              <Form.Item name="isCreateDynamicQueue">
                <Checkbox
                  checked={isCreateDynamicQueueChecked}
                  onChange={onChangeQueue}
                >
                  <span className="create-user-form-label">
                    Create Dynamic Que
                  </span>
                </Checkbox>
              </Form.Item>

              <Form.Item
                label={<span className="create-user-form-label">Speed</span>}
                name="speed"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Speed",
                  },
                  {
                    validator: (_, value) =>
                      value > 0
                        ? Promise.resolve()
                        : Promise.reject(new Error("Invalid Speed")),
                  },
                ]}
              >
                <Input className="user-input-general-height" placeholder="5" />
              </Form.Item>

              <Form.Item
                label={
                  <span className="create-user-form-label">Speed Unit</span>
                }
                rules={[
                  {
                    required: true,
                    message: "Please Enter Speed Unit",
                  },
                ]}
                name="speedAbbreviation"
              >
                <MeshBillSelect
                  size="large"
                  height="45px"
                  options={[
                    { label: "Kb", value: "K" },
                    { label: "Mb", value: "M" },
                    { label: "Gb", value: "G" },
                  ]}
                />
              </Form.Item>

              <Form.Item
                label={
                  <span className="create-user-form-label">Package Type</span>
                }
                rules={[
                  {
                    required: true,
                    message: "Please Enter Package Type",
                  },
                ]}
                name="packageDuration"
              >
                <MeshBillSelect
                  size="large"
                  height="45px"
                  options={[
                    { value: "MONTHLY", label: "Monthly" },
                    { value: "THIRTY_DAYS", label: "Thirty Days" },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label={
                  <span className="create-user-form-label">Package Price</span>
                }
                name="salePrice"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Package Price",
                  },
                  {
                    validator: (_, value) =>
                      value > 0
                        ? Promise.resolve()
                        : Promise.reject(new Error("Invalid Price")),
                  },
                ]}
              >
                <Input
                  type="number"
                  className="user-input-general-height"
                  placeholder="1000"
                />
              </Form.Item>

              <Form.Item name="isVariableRechargeAllowed">
                <Checkbox checked={checked} onChange={onChange}>
                  <span className="create-user-form-label">
                    Variable Recharge Allowed?
                  </span>
                </Checkbox>
              </Form.Item>
              <Form.Item>
                <MeshBillButton
                  backgroundColor={ButtonProps.background.STANDARD}
                  height={ButtonProps.height.STANDARD}
                  width={`100%`}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {}}
                  htmlType="submit"
                  text="Create New Package"
                  disabled={apiLoading}
                  icon={<FiPlusCircle />}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </MeshBillModal>
    </>
  );
};
export default CreatePackageModalComponent;
