import React, { useEffect, useState } from "react";
import { Col, Dropdown, Row, Table, Tag, Typography } from "antd";
import type { MenuProps, TableProps } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import "./../../styles/tables.css";

import { useNavigate } from "react-router-dom";
import {
  formatDate,
  formatDateForJustMonthDayAndYear,
} from "../../helpers/date";
import {
  formatEndUserLogs,
  getAuditLogShortTitle,
} from "../../helpers/constants";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";
import MeshBillPagination from "../Items/Pagination/MeshbillPagination";

// const TailSpinLoader = lazy(() => import("../Items/Loaders/TailSpinLoader"));
// const MeshBillPagination = lazy(
//   () => import("../Items/Pagination/MeshbillPagination")
// );
const ViewEndUsersAuditLogsTable: React.FC<StaffAuditLogsTableProp> = ({
  logs,
  isLoading,
  pagination,
  handlePageSwitch,
}) => {
  const [formattedLogs, setFormattedLogs] = useState<any[]>([]);
  const [activeRow, setActiveRow] = useState<any | null>(null);
  const navigate = useNavigate();
  useEffect(() => {
    const formattedLogs = formatEndUserLogs(logs);
    setFormattedLogs(formattedLogs);
  }, [logs]);
  const columns: TableProps<any>["columns"] = [
    {
      title: "Type",
      render: (row) => (
        <strong>{`${getAuditLogShortTitle(row.actionType)}`}</strong>
      ),
    },
    {
      title: "Full Name",
      render: (row) => (
        <span>{`${
          row?.endUserDetails?.firstName + " " + row?.endUserDetails?.lastName
        }`}</span>
      ),
    },
    {
      title: "User Name",
      render: (row) => <span>{`${row?.endUserDetails?.username || ""}`}</span>,
    },
    {
      title: "Password",
      render: (row) => <span>{`${row?.endUserDetails?.password || ""}`}</span>,
    },
    {
      title: "Phone Number",
      render: (row) => (
        <span>{`${row?.endUserDetails?.phoneNumber || ""}`}</span>
      ),
    },
    {
      title: "Address",
      render: (row) => <span>{`${row?.endUserDetails?.address || ""}`}</span>,
    },

    {
      title: "Expiry",
      render: (row) => (
        <span>{`${
          row?.endUserDetails?.expiryDate
            ? formatDateForJustMonthDayAndYear(row?.endUserDetails?.expiryDate)
            : ""
        }`}</span>
      ),
    },

    {
      title: "Invoice Amount",
      render: (row) => (
        <span>{`${row?.endUserTransactionLog?.invoiceTotalAmount || ""}`}</span>
      ),
    },
    {
      title: "Discount Amount",
      render: (row) => (
        <span>{`${
          row?.endUserTransactionLog?.invoiceDiscountAmount || ""
        }`}</span>
      ),
    },
    {
      title: "Paid Amount",
      render: (row) => (
        <span>{`${row?.endUserTransactionLog?.invoicePaidAmount || ""}`}</span>
      ),
    },

    {
      title: "Fixed Discount?",
      render: (row) => (
        <strong>{`${row?.discountObj?.fixedDiscountAmount || ""}`}</strong>
      ),
    },
    {
      title: "Updated ONT ID",
      render: (row) => <span>{`${row?.ontObj?.ontID || ""}`}</span>,
    },

    {
      title: "Action By",
      render: (row) => (
        <span>{`${
          row?.updatedBy?.firstName + " " + row?.updatedBy?.lastName
        }`}</span>
      ),
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      render: (text) => <span>{formatDate(new Date(text))}</span>,
    },
  ];

  return (
    <div className="general-table-container">
      {isLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row>
          <Col span={24}>
            <Typography className="general-table-heading">
              End User Audit Logs
            </Typography>
            <div>
              <Table
                className="striped-table"
                columns={columns}
                dataSource={formattedLogs}
                onRow={(record, rowIndex) => {
                  return {};
                }}
                pagination={false}
              />
            </div>
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
              marginBottom: "20px",
            }}
            span={24}
          >
            <MeshBillPagination
              pagination={pagination}
              handlePageSwitch={handlePageSwitch}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};
export default ViewEndUsersAuditLogsTable;
