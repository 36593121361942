import React, { useEffect, useState } from "react";
import { FaUsers } from "react-icons/fa";
import SingleCard from "./SingleCard";
import useAxiosPost from "../../../hooks/useAxiosPost";

const TotalUsersCard = ({}) => {
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("reports/dashboard/getTotalUsersCountByOwner");
  const [value, setValue] = useState<string>("0");
  useEffect(() => {
    postApiData({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      const totalUsers = data.totalUsers;
      console.log({ totalUsers });
      setValue(totalUsers);
    }
  }, [apiResponse]);
  return (
    <SingleCard
      label="Total Users"
      value={value}
      CardIcon={FaUsers}
      iconColor="#1890ff"
      isLoading={apiLoading}
      isLink
      route="/pppoe-users?activeTab=all"
    />
  );
};

export default TotalUsersCard;
