import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ImportPackagesComponent from "../components/importPackages/ImportPackagesFromMikrotik";

const ImportPackagesPage = () => {
  const { setTitle } = useContext(TitleContext);
  useEffect(() => {
    setTitle("Import Packages");
  }, [setTitle]);
  return (
    <>
      <ImportPackagesComponent />
    </>
  );
};
export default ImportPackagesPage;
