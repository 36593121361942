import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import CreateEndUserComponent from "../components/PPPoEUsers/CreateEndUserComponent";
// const CreateEndUserComponent = lazy(
//   () => import("../components/PPPoEUsers/CreateEndUserComponent")
// );

const CreatePPPoEUsersPage = () => {
  const { setTitle } = useContext(TitleContext);
  useEffect(() => {
    setTitle("Create PPPoE User");
  }, [setTitle]);
  return (
    <>
      <CreateEndUserComponent />
    </>
  );
};
export default CreatePPPoEUsersPage;
