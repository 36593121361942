import { Col, Row, Skeleton, Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

interface DashboardStatsSingleCardProp {
  CardIcon: any;
  label: string;
  value: string;
  iconColor: string;
  isLoading?: boolean;
  isLink?: boolean;
  route?: string;
}
const SingleCard: React.FC<DashboardStatsSingleCardProp> = ({
  CardIcon,
  label,
  value,
  iconColor,
  isLoading,
  isLink = false,
  route = "/",
}) => {
  const navigate = useNavigate();
  const iconStyle = {
    width: "30px", // Set the width
    height: "30px", // Set the height
    color: iconColor, // Set the color (green in this case)
  };
  if (isLoading) {
    return (
      <Row className="single-card-container" style={{ flexDirection: "row" }}>
        <Skeleton
          active
          paragraph={{ rows: 1 }}
          title={{ width: "30%" }}
          style={{ width: "66%" }}
        />
        <Skeleton.Avatar active size="large" shape="square" />
      </Row>
    );
  }
  return (
    <Row
      onClick={(e) => {
        if (isLink) {
          navigate(route);
        }
      }}
      className={`single-card-container ${
        isLink === true ? "cursor-pointer" : ""
      }`}
    >
      <div>
        <Typography className="label-item">{label}</Typography>
        <Typography className="label-value">{value}</Typography>
      </div>
      <div>
        <CardIcon style={iconStyle} />
      </div>
    </Row>
  );
};

export default SingleCard;
