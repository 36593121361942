import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { DatePicker, Space } from "antd";
import dayjs, { Dayjs } from "dayjs";
import useAxiosPost from "../../../hooks/useAxiosPost";
import MeshBillButton from "../../Items/Button/Index";
import { ButtonProps } from "../../../helpers/constants";
import SalesUnitsChart from "../../Charts/RetailSaleChartAllPackages";
const { RangePicker } = DatePicker;

const AllPackagesRetailReports = () => {
  const [dates, setDates] = useState<[Dayjs | null, Dayjs | null]>([
    dayjs().subtract(1, "week"),
    dayjs(),
  ]);
  const [data, setData] = useState<CompanyRetailSaleAllPackagesRecord[]>([]);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  // Handle date range change
  const handleDateChange = (
    dates: [Dayjs | null, Dayjs | null] | null,
    dateStrings: [string, string]
  ) => {
    if (dates) {
      setDates(dates);
    }
  };

  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<CompanyRetailAllPackagesApiProp>(
    "reports/getCompanyRetailChartsAllPackages"
  );
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      console.log({ data });
      setData(data || []);
      setIsDataLoaded(true);
    }
  }, [apiResponse]);

  const handleGetReports = () => {
    const dataToSend: CompanyRetailAllPackagesApiProp = {
      startDate: dates[0] ? dates[0].format("YYYY-MM-DD") : "",
      endDate: dates[1] ? dates[1].format("YYYY-MM-DD") : "",
    };
    postApiData(dataToSend);
  };

  return (
    <>
      <Row className="full-width-row">
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
          span={24}
        >
          <Space direction="vertical" size={12}>
            <div className="filter-item-and-control-container">
              <label className="filter-item-label">Choose Date:</label>
              <RangePicker
                value={dates}
                onChange={handleDateChange}
                format="YYYY-MM-DD"
                style={{ minHeight: "45px", width: "300px" }}
              />
            </div>
          </Space>
        </Col>

        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
          span={24}
        >
          <Space direction="vertical" size={12}>
            <MeshBillButton
              htmlType="submit"
              backgroundColor={ButtonProps.background.STANDARD}
              height={ButtonProps.height.STANDARD}
              width={ButtonProps.width.STANDARD}
              textColor={ButtonProps.color.STANDARD}
              onClick={() => {
                console.log("clicked");
                handleGetReports();
              }}
              text="View Report"
              disabled={apiLoading}
            />
          </Space>
        </Col>
        <Col span={24}>
          {isDataLoaded === true && <SalesUnitsChart packages={data} />}
        </Col>
      </Row>
    </>
  );
};
export default AllPackagesRetailReports;
