import React, { useEffect, useState } from "react";
import { Col, Dropdown, Row, Table, Tag, Typography } from "antd";
import type { MenuProps, TableProps } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import "./../../styles/tables.css";

import { useNavigate } from "react-router-dom";
import { formatDate } from "../../helpers/date";
import {
  formatStaffLogs,
  getAuditLogShortTitle,
} from "../../helpers/constants";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";
import MeshBillPagination from "../Items/Pagination/MeshbillPagination";

// const TailSpinLoader = lazy(() => import("../Items/Loaders/TailSpinLoader"));

const ViewStaffAuditLogsTable: React.FC<StaffAuditLogsTableProp> = ({
  logs,
  isLoading,
  pagination,
  handlePageSwitch,
}) => {
  const [formattedLogs, setFormattedLogs] = useState<any[]>([]);
  const [activeRow, setActiveRow] = useState<any | null>(null);
  const navigate = useNavigate();
  useEffect(() => {
    const formattedLogs = formatStaffLogs(logs);
    setFormattedLogs(formattedLogs);
  }, [logs]);
  const columns: TableProps<any>["columns"] = [
    {
      title: "Type",
      render: (row) => (
        <strong>{`${getAuditLogShortTitle(row.actionType)}`}</strong>
      ),
    },
    {
      title: "Full Name",
      render: (row) => (
        <span>{`${
          row?.userDetails?.firstName + " " + row?.userDetails?.lastName
        }`}</span>
      ),
    },
    {
      title: "User Name",
      render: (row) => (
        <span>{`${
          row?.userDetails?.staffUserName ||
          row?.beforeStaffDetails?.staffUserName
        }`}</span>
      ),
    },
    {
      title: "Email",
      render: (row) => (
        <span>{`${
          row?.userDetails?.email || row?.beforeStaffDetails?.email
        }`}</span>
      ),
    },
    {
      title: "Phone Number",
      render: (row) => (
        <span>{`${
          row?.userDetails?.phoneNumber || row?.beforeStaffDetails?.phoneNumber
        }`}</span>
      ),
    },
    {
      title: "Status",
      render: (row) => (
        <strong>{`${
          row?.enableDisableObj
            ? row?.enableDisableObj?.enable === 1
              ? "Enabled"
              : "Disabled"
            : ""
        }`}</strong>
      ),
    },
    {
      title: "Wallet Updated?",
      render: (row) => (
        <strong>{`${
          row?.walletDetails
            ? `${
                row?.walletDetails?.walletDays
              } Days , ${row?.walletDetails?.walletLimit?.toLocaleString()} PKR`
            : ""
        }`}</strong>
      ),
    },
    {
      title: "Wallet Days Rest?",
      render: (row) => (
        <strong>{`${row?.isWalletReset === true ? "Yes" : ""}`}</strong>
      ),
    },
    {
      title: "Amount Collected?",
      render: (row) => (
        <strong>{`${
          row?.userDetails?.amountCollected
            ? row?.userDetails?.amountCollected?.toLocaleString()
            : ""
        }`}</strong>
      ),
    },
    {
      title: "Action By",
      render: (row) => (
        <span>{`${
          row?.updatedBy?.firstName + " " + row?.updatedBy?.lastName
        }`}</span>
      ),
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      render: (text) => <span>{formatDate(new Date(text))}</span>,
    },
  ];

  return (
    <div className="general-table-container">
      {isLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row>
          <Col span={24}>
            <Typography className="general-table-heading">
              Staff Audit Logs
            </Typography>
            <Table
              className="striped-table"
              columns={columns}
              dataSource={formattedLogs}
              onRow={(record, rowIndex) => {
                return {};
              }}
              pagination={false}
            />
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
              marginBottom: "20px",
            }}
            span={24}
          >
            <MeshBillPagination
              pagination={pagination}
              handlePageSwitch={handlePageSwitch}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};
export default ViewStaffAuditLogsTable;
