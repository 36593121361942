import React, { useEffect, useState } from "react";

import { FaUserCheck } from "react-icons/fa";
import useAxiosPost from "../../../hooks/useAxiosPost";
import SingleCard from "./SingleCard";

const OnlineUsersCard = ({}) => {
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>(
    "reports/dashboard/getOnlineUsersCountFromRadiusByOwner"
  );
  const [value, setValue] = useState<string>("0");
  useEffect(() => {
    postApiData({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      const onlineUsersCount = data.onlineUsersCount;
      console.log({ onlineUsersCount });
      setValue(onlineUsersCount);
    }
  }, [apiResponse]);
  return (
    <SingleCard
      label="Online Users"
      value={value}
      CardIcon={FaUserCheck}
      iconColor="#28a745"
      isLoading={apiLoading}
      isLink
      route="/pppoe-users/connected-users?activeTab=1"
    />
  );
};

export default OnlineUsersCard;
