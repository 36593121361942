import React, { useEffect, useState } from "react";

import { AiOutlineClockCircle } from "react-icons/ai";

import useAxiosPost from "../../../hooks/useAxiosPost";
import SingleCard from "./SingleCard";
import { Col } from "antd";

const ExpiringUsersCard = ({}) => {
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>(
    "reports/dashboard/calculateTheCountOfExpiringUsersByOwnerWithingWeek"
  );
  const [threeDays, setThreeDays] = useState<string>("0");
  const [fiveDays, setFiveDays] = useState<string>("0");
  const [sevenDays, setSevenDays] = useState<string>("0");
  useEffect(() => {
    postApiData({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      const {
        expiring_in_3_days = "0",
        expiring_in_5_days = "0",
        expiring_in_7_days = "0",
      } = data;
      setThreeDays(expiring_in_3_days);
      setFiveDays(expiring_in_5_days);
      setSevenDays(expiring_in_7_days);
    }
  }, [apiResponse]);
  return (
    <>
      <Col md={6} lg={4} xl={4} xxl={4} sm={24} xs={24}>
        <SingleCard
          label="Expiring in 3 Days"
          value={threeDays}
          CardIcon={AiOutlineClockCircle}
          iconColor="#FF5733"
          isLoading={apiLoading}
          isLink
          route="/pppoe-users?activeTab=expiring"
        />
      </Col>
      <Col md={6} lg={4} xl={4} xxl={4} sm={24} xs={24}>
        <SingleCard
          label="Expiring in 5 Days"
          value={fiveDays}
          CardIcon={AiOutlineClockCircle}
          iconColor="#FF5733"
          isLoading={apiLoading}
          isLink
          route="/pppoe-users?activeTab=expiring"
        />
      </Col>
      <Col md={6} lg={4} xl={4} xxl={4} sm={24} xs={24}>
        <SingleCard
          label="Expiring in 7 Days"
          value={sevenDays}
          CardIcon={AiOutlineClockCircle}
          iconColor="#FF5733"
          isLoading={apiLoading}
          isLink
          route="/pppoe-users?activeTab=expiring"
        />
      </Col>
    </>
  );
};

export default ExpiringUsersCard;
