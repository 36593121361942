import { useEffect, useState } from "react";
import "./../../styles/uploader.css";
import useAxiosPost, { authTokenString } from "../../hooks/useAxiosPost";
import { Upload, Button, Row } from "antd";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import { usePapaParse } from "react-papaparse";
import type { UploadFile } from "antd/es/upload/interface";

import useMultiNotification from "../../hooks/useNotification";
import { socket } from "../../socketService";

import { useNavigate } from "react-router-dom";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";
import ImportUsersThroughFileTable from "../Tables/ImportUsersThroughFileTable";
import moment from "moment";

const ImportUsersThroughFile = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<UsersCsvData[]>([]);
  const { readString } = usePapaParse();
  const [staff, setStaffProfile] = useState<StaffListType | null>(null);

  const { openNotification, contextHolder } = useMultiNotification();
  const [getPackagesApi, packagesApiResponse] = useAxiosPost<ResponseProp>(
    "packages/getPackagesByOwner"
  );
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("auth/getMyProfile");
  const [packages, setPackages] = useState<SinglePackage[]>([]);
  const [objectifiedPackages, setObjectifiedPackages] = useState<any>({});
  const [packageOptions, setPackageOptions] = useState<MeshBillSelectOptions[]>(
    []
  );
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isUploadedFromFile, setIsUploadedFromFile] = useState<boolean>(false);
  useEffect(() => {
    if (apiResponse !== null) {
      // userType, email
      const data: any = apiResponse.responseData;
      setStaffProfile(data);
    }
  }, [apiResponse]);
  useEffect(() => {
    getPackagesApi({});
    postApiData({});
    socket.emit(
      "join",
      {
        userName: localStorage.getItem("username") || "client",
        client: "web",
      },
      () => {}
    );
  }, []);

  useEffect(() => {
    socket.on(`errorUploadingUsers`, (data) => {
      console.log({ data });
      openNotification(
        `error`,
        "Operation Failed",
        data.message || "Error While Uploading"
      );
      setIsUploading(false);
    });
    socket.on(`bulkUserUploaded`, (data) => {
      openNotification(
        `success`,
        "Success",
        data.message || "Users Uploaded Successfully"
      );
      setTimeout(() => {
        navigate("/");
      }, 2500);
    });
  }, []);
  const splitFullName = (fullName: string) => {
    let firstName = "";
    let lastName = "";

    if (!fullName.trim()) {
      return { firstName, lastName };
    }

    const [first, ...rest] = fullName.split(" ");
    firstName = first;
    lastName = rest.join(" ").trim();

    if (!lastName) {
      lastName = firstName;
    }

    return { firstName, lastName };
  };
  const handleUpload = (file: UploadFile) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const csvData = e.target?.result?.toString() || "";
      readString(csvData, {
        header: true,
        complete: (results) => {
          let isError: boolean = false;
          if (!staff) {
            return;
          }
          const formattedData = results.data.map((row: any, index: number) => {
            const { firstName, lastName } = splitFullName(row.fullName || "");
            const selectedPackage = objectifiedPackages[row.packageName];
            if (
              staff.staffUserName.toLowerCase() === row?.dealer?.toLowerCase()
            ) {
              isError = true;
            }
            return {
              key: index,
              firstName: firstName || "",
              lastName: lastName || "",
              username: row.username || "",
              password: row.password || "",
              phoneNumber: row.phoneNumber || "",
              address: row.address || "",
              ontID: row.ontID || "",
              enable: row.enable || "",
              expiryDateToDisplay: row.expiryDate || "",
              expiryDate: row.expiryDate
                ? moment(row.expiryDate, "DD MMM YYYY HH:mm:ss").format(
                    "YYYY-MM-DD"
                  )
                : "",
              dealer: row.dealer || "",
              remainingBalance: parseInt(row.remainingBalance, 10) || 0,
              advanceBalance: parseInt(row.advanceBalance, 10) || 0,
              intPkPackagePriceID: selectedPackage
                ? selectedPackage.intPkPackagePriceID
                : "",
              nicNumber: row.nicNumber || "",
            };
          });
          if (isError) {
            openNotification(
              `error`,
              "Operation Failed",
              `You Have A Dealer UserName That matched Admin Please empty that!!!`
            );
          } else {
            setData(formattedData);
            setIsUploadedFromFile(true);
          }
        },
        error: (error) => {},
      });
    };
    reader.readAsText(file as unknown as Blob);
    return false;
  };

  useEffect(() => {
    if (packagesApiResponse !== null) {
      const data: any = packagesApiResponse.responseData;
      const objectifiedPackages: any = {};
      const packageOptions: MeshBillSelectOptions[] = data.map(
        (item: any): MeshBillSelectOptions => {
          objectifiedPackages[item.packageName] = item;
          return {
            value: item.intPkPackagePriceID,
            label: item.packageName,
          };
        }
      );

      console.log({ data });
      setPackages(data || []);
      setPackageOptions(packageOptions);
      setObjectifiedPackages(objectifiedPackages);
    }
  }, [packagesApiResponse]);
  const handleUploadUsers = (users: UsersCsvData[]) => {
    console.log({ users });
    setIsUploading(true);
    socket.emit("uploadUsersThroughFile", {
      users: users,
      authToken: authTokenString,
    });
  };
  const handleDownloadSample = () => {
    const link = document.createElement("a");
    link.href = "/sample-users.csv";
    link.setAttribute("download", "sample-users.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (isUploading) {
    return <TailSpinLoader />;
  }
  return (
    <>
      {contextHolder}

      <Row
        className="full-width-row mb-20"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="csv-uploader-container">
          <Upload
            beforeUpload={handleUpload}
            showUploadList={false}
            accept=".csv"
          >
            <Button
              disabled={isUploadedFromFile}
              className="csv-upload-button"
              icon={<UploadOutlined />}
            >
              Upload CSV
            </Button>
          </Upload>

          <Button
            className="csv-download-button"
            icon={<DownloadOutlined />}
            onClick={handleDownloadSample}
            style={{ marginTop: "16px" }}
          >
            Download Sample File
          </Button>
        </div>
      </Row>

      <Row className="full-width-row ">
        {isUploadedFromFile === true && (
          <ImportUsersThroughFileTable
            users={data}
            isUploading={isUploading}
            packageOptions={packageOptions}
            // handleUserSkip={handleUserSkip}
            handleUploadUsers={handleUploadUsers}
          />
        )}
      </Row>
    </>
  );
};
export default ImportUsersThroughFile;
