import { useEffect, useState } from "react";
import useAxiosPost from "../../hooks/useAxiosPost";
import { Col, Row } from "antd";
import { ButtonProps } from "../../helpers/constants";
import { FiPlusCircle } from "react-icons/fi";
import { getUserType } from "../../helpers/userDetails";
import CreatePackageModalComponent from "../Modals/CreateNewPackageModal";
import UpdatePackageSalePriceModalComponent from "../Modals/UpdatePackageSalePriceModal";
import MeshBillButton from "../Items/Button/Index";
import ViewPackagesTable from "../Tables/ViewPackagesTable";
import UpdatePackageModalComponent from "../Modals/UpdatePackageModal";

const ViewPackagesComponent = () => {
  const role = getUserType();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("packages/getPackagesByOwner");
  const [packages, setPackages] = useState<SinglePackage[]>([]);
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [showUpdatePrice, setShowUpdatePrice] = useState<boolean>(false);
  const [showUpdateDetails, setShowUpdateDetails] = useState<boolean>(false);
  const [activeRow, setActiveRow] = useState<SinglePackage | null>(null);
  useEffect(() => {
    postApiData({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      console.log({ data });
      setPackages(data || []);
    }
  }, [apiResponse]);
  const handleClose = (isReload: boolean) => {
    setShowCreate(false);
    setShowUpdatePrice(false);
    setShowUpdateDetails(false);
    setActiveRow(null);
    if (isReload) {
      postApiData({});
    }
  };
  const handleUpdatePriceModal = (activePackage: SinglePackage) => {
    setActiveRow(activePackage);
    setShowUpdatePrice(true);
  };
  const handleUpdateDetailsModal = (activePackage: SinglePackage) => {
    setActiveRow(activePackage);
    setShowUpdateDetails(true);
  };
  return (
    <>
      {showCreate === true && (
        <CreatePackageModalComponent
          show={showCreate}
          handleClose={handleClose}
        />
      )}

      {showUpdatePrice === true && activeRow !== null && (
        <UpdatePackageSalePriceModalComponent
          show={showUpdatePrice}
          handleClose={handleClose}
          activePackage={activeRow}
        />
      )}
      {showUpdateDetails === true && activeRow !== null && (
        <UpdatePackageModalComponent
          show={showUpdateDetails}
          handleClose={handleClose}
          activePackage={activeRow}
        />
      )}
      <Row className="full-width-row">
        {role === "ADMIN" && (
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "20px",
            }}
            span={24}
          >
            <MeshBillButton
              backgroundColor={ButtonProps.background.STANDARD}
              height={ButtonProps.height.STANDARD}
              width={ButtonProps.width.STANDARD}
              textColor={ButtonProps.color.STANDARD}
              onClick={() => {
                setShowCreate(true);
              }}
              text="Create Package"
              icon={<FiPlusCircle />}
            />
          </Col>
        )}
        <Col span={24}>
          <ViewPackagesTable
            handleUpdatePriceModal={handleUpdatePriceModal}
            isLoading={apiLoading}
            packages={packages}
            handleUpdateDetailsModal={handleUpdateDetailsModal}
          />
        </Col>
      </Row>
    </>
  );
};
export default ViewPackagesComponent;
