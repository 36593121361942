import { useContext, useEffect, lazy } from "react";
import TitleContext from "../context/TitleContext";
import LoginSuperAdminComponent from "../components/auth/LoginSuperAdminComponent";
// const LoginSuperAdminComponent = lazy(
//   () => import("../components/auth/LoginSuperAdminComponent")
// );
const LoginSuperAdminPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Login");
  }, [setTitle]);
  return (
    <>
      <LoginSuperAdminComponent />
    </>
  );
};
export default LoginSuperAdminPage;
