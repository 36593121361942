import { useEffect, useState } from "react";
import useAxiosPost from "../../hooks/useAxiosPost";
import { Col, Row } from "antd";
import ViewPPPoEUsersTable from "../Tables/PPPoEUsersTable";
const ViewActivePPPoEUsersComponent = () => {
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("endUsers/getActiveUsersByOwner");
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [
    postSearchAPi,
    searchApiResponse,
    searchApiError,
    searchApiLoading,
    searchApiMessage,
    searchApiErrorCount,
  ] = useAxiosPost<UserSearchApiProp>("endUsers/searchUsersByOwner");
  const [endUsers, setEndUsers] = useState<EndUser[]>([]);
  const [searchedUsers, setSearchedUsers] = useState<EndUser[]>([]);

  const [pagination, setPagination] = useState<PaginationProp>({
    totalPages: 0,
    currentPage: 1,
    previousPage: null,
    nextPage: null,
    totalCount: 0,
    pageSize: 50,
  });
  const [searchPagination, setSearchPagination] = useState<PaginationProp>({
    totalPages: 0,
    currentPage: 1,
    previousPage: null,
    nextPage: null,
    totalCount: 0,
    pageSize: 100,
  });
  useEffect(() => {
    postApiData({ page: 1 });
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      const {
        currentPage,
        nextPage,
        pageSize,
        previousPage,
        totalCount,
        totalPages,
        endUsers,
      } = data;
      setPagination({
        currentPage,
        totalPages,
        nextPage,
        previousPage,
        totalCount,
        pageSize,
      });
      setEndUsers(endUsers || []);
    }
  }, [apiResponse]);
  const handleAllUsersPageSwitch = (pageNumber: number) => {
    postApiData({ page: pageNumber });
  };
  const handleSearch = (search: string) => {
    if (search.trim() === "") {
      setIsSearch(false);
      return;
    }
    if (search.length < 3) {
      return;
    }
    setIsSearch(true);
    postSearchAPi({ search });
  };

  useEffect(() => {
    if (searchApiResponse !== null) {
      const data: any = searchApiResponse.responseData;

      setSearchedUsers(data || []);
    }
  }, [searchApiResponse]);

  return (
    <>
      <Row className="full-width-row">
        <Col span={24}>
          <ViewPPPoEUsersTable
            isLoading={apiLoading}
            users={isSearch === true ? searchedUsers : endUsers}
            pageTitle="Active Users"
            pagination={isSearch === true ? searchPagination : pagination}
            handlePageSwitch={handleAllUsersPageSwitch}
            handleSearch={handleSearch}
          />
        </Col>
      </Row>
    </>
  );
};
export default ViewActivePPPoEUsersComponent;
