import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input, Checkbox } from "antd";
import { ButtonProps } from "../../helpers/constants";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import "./../../styles/modal.css";
import Typography from "antd/es/typography/Typography";
import { MdEdit } from "react-icons/md";
import { calculateNextExpiryDate } from "../../helpers/date";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";
import MeshBillModal from "../Items/Modal/Index";
import MeshBillButton from "../Items/Button/Index";
// const MeshBillSelect = lazy(() => import("../Items/Select/Index"));
// const MeshBillModal = lazy(() => import("../Items/Modal/Index"));
// const MeshBillButton = lazy(() => import("../Items/Button/Index"));

const RechargeModalComponent: React.FC<RechargeModalProps> = ({
  show,
  handleClose,
  endUser,
  amountAfterDiscount,
  discountAmount,
}) => {
  const { openNotification, contextHolder } = useMultiNotification();
  const [form] = Form.useForm();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<RechargeEndUserApiProp>("endUsers/rechargeEndUser", true);
  const nextExpiryDate = calculateNextExpiryDate(
    new Date(endUser.expiryDate),
    endUser.packageDuration
  );
  const [
    getAmountToBePaidApi,
    amountToBePaidApiResponse,
    amountToBePaidApiError,
    amountToBePaidApiLoading,
  ] = useAxiosPost<GetAmountToBePaidAtPackageChangeApiProp>(
    "endUsers/checkAmountToBePaidAtEndUserRecharge"
  );
  const [isInputDisabled, setIsInputDisabled] = useState<boolean>(false);
  const [creditAmount, setCreditAmount] = useState<number>(0);
  const [salePrice, setSalePrice] = useState<number>(amountAfterDiscount);

  console.log({ nextExpiryDate });
  const [showInput, setShowInput] = useState<boolean>(false);
  const onFinish = (values: any) => {
    const { amount } = values;
    console.log({ values });
    const dataToSend: RechargeEndUserApiProp = {
      amountPaid: showInput === true ? Number(amount) : salePrice,
      intPkEndUserID: endUser.intPkEndUserID,
      expiryDate: nextExpiryDate,
    };
    postApiData(dataToSend);
  };
  useEffect(() => {
    if (apiResponse !== null) {
      const { message } = apiResponse;
      if (message) {
        openNotification("success", "Success", message);
        setTimeout(() => {
          handleClose(true);
        }, 2500);
      }
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification("error", "Error", apiError);
    }
  }, [apiError, apiErrorCount]);
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  useEffect(() => {
    getAmountToBePaidApi({
      intPkEndUserID: endUser.intPkEndUserID,
      intPkPackagePriceID: endUser.intPkPackagePriceID,
    });
  }, []);
  useEffect(() => {
    if (amountToBePaidApiResponse !== null) {
      const responseData: any = amountToBePaidApiResponse.responseData;
      const { data, packageData } = responseData;
      const totalAmount = data.totalAmount || 0;
      const days = data.days || 0;
      const salePrice = endUser.salePrice || 0;
      setCreditAmount(totalAmount);
      const AmountToBePaid =
        Number(salePrice) - Number(discountAmount) - Number(totalAmount);
      console.log({ AmountToBePaid });
      if (AmountToBePaid < 0) {
        form.setFieldsValue({ amount: 0 });
        setShowInput(true);
        setIsInputDisabled(true);
        setSalePrice(0);
      } else {
        setShowInput(true);
        form.setFieldsValue({ amount: AmountToBePaid });
        setIsInputDisabled(false);
        setSalePrice(AmountToBePaid);
      }
    }
  }, [amountToBePaidApiResponse]);
  return (
    <>
      <MeshBillModal
        title="Recharge User"
        headerStyle={{
          backgroundColor: "var(--primary-background-color)",
          color: "var(--primary-text-color)",
          fontSize: "16px",
          fontWeight: "var(--fw-700)",
          height: "50px",
        }}
        open={show}
        onCancel={() => {
          if (apiLoading) {
            return;
          }
          handleClose(false);
        }}
      >
        {contextHolder}

        {amountToBePaidApiLoading === true ? (
          <TailSpinLoader />
        ) : (
          <Row>
            <Col
              span={24}
              className="meshbill-create-record-modal-form-container"
            >
              <div className="rechargeable-user-info">
                <Typography className="modal-user-name-label">
                  UserName: {endUser.username}
                </Typography>
                <Typography className="modal-user-name-label">
                  Package: {endUser.packageName}
                </Typography>
                <Typography className="modal-user-phone-label">
                  Phone Number : {endUser.phoneNumber}
                </Typography>
                <Typography className="modal-user-address-label">
                  {endUser.address}
                </Typography>
              </div>

              <div className="rechargeable-user-info mt-20 mb-20">
                <Typography className="">
                  <span className="modal-user-address-label">
                    Next Expiry :{" "}
                  </span>
                  <span className="balance-minus-label">{nextExpiryDate}</span>
                </Typography>
              </div>

              <div className="rechargeable-user-info mt-20 mb-20">
                <Typography>
                  <span className="balance-available-label">
                    Sale Price: {endUser.salePrice}/-
                  </span>
                </Typography>
              </div>
              <div className="rechargeable-user-info mt-20 mb-20">
                <Typography>
                  <span className="balance-minus-label">
                    Discount: {discountAmount}/-
                  </span>
                </Typography>
              </div>
              <div className="rechargeable-user-info mt-20 mb-20">
                <Typography>
                  <span className="balance-available-label">
                    Total ReceiveAble Amount:{" "}
                    {endUser.salePrice - discountAmount}/-
                  </span>
                </Typography>
              </div>
              <div className="rechargeable-user-info mt-20 mb-20">
                <Typography>
                  <span className="balance-available-label">
                    Available Credit Balance: {creditAmount}/-
                  </span>
                </Typography>
              </div>

              <div className="rechargeable-user-info mt-20 mb-20">
                <Typography>
                  <span className="balance-available-label">
                    Remaining ReceiveAble Amount: {salePrice}/-
                  </span>
                  <MdEdit
                    style={{ marginLeft: "10px", fontSize: "20px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowInput(true);
                    }}
                    className="cursor-pointer"
                  />
                </Typography>
              </div>

              {/* <div className="rechargeable-user-info mt-20 mb-20">
                <Typography>
                  <span className="balance-available-label">
                    {amountAfterDiscount}/-
                  </span>
                  <MdEdit
                    style={{ marginLeft: "10px", fontSize: "20px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowInput(true);
                    }}
                    className="cursor-pointer"
                  />
                </Typography>
              </div> */}
              <Form
                //   requiredMark={false}
                style={{ width: "100%", marginTop: "50px" }}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                name="rechargeUserForm"
                initialValues={{
                  amount: amountAfterDiscount,
                }}
                form={form}
              >
                {showInput === true && (
                  <Form.Item
                    name="amount"
                    rules={[
                      { required: true, message: "Please Enter Amount" },
                      {
                        validator: (_, value) =>
                          value > -1
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error("Amount must be greater than 0")
                              ),
                      },
                      {
                        validator: (_, value) =>
                          value > amountAfterDiscount
                            ? Promise.reject(
                                new Error(
                                  `Amount must not be greater than ${amountAfterDiscount}`
                                )
                              )
                            : Promise.resolve(),
                      },
                    ]}
                    label={
                      <span className="create-user-form-label">
                        Received Amount
                      </span>
                    }
                  >
                    <Input
                      type="number"
                      className="user-input-general-height"
                      placeholder="10"
                      disabled={isInputDisabled}
                    />
                  </Form.Item>
                )}

                <Form.Item>
                  <MeshBillButton
                    backgroundColor={ButtonProps.background.STANDARD}
                    height={ButtonProps.height.STANDARD}
                    width={`100%`}
                    textColor={ButtonProps.color.STANDARD}
                    onClick={() => {}}
                    htmlType="submit"
                    text="Recharge Now"
                    disabled={apiLoading}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        )}
      </MeshBillModal>
    </>
  );
};
export default RechargeModalComponent;
