import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input } from "antd";
import { ButtonProps } from "../../helpers/constants";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import "./../../styles/modal.css";
import MeshBillModal from "../Items/Modal/Index";
import MeshBillSelect from "../Items/Select/Index";
import MeshBillButton from "../Items/Button/Index";

// const MeshBillModal = lazy(() => import("../Items/Modal/Index"));
// const MeshBillButton = lazy(() => import("../Items/Button/Index"));
// const MeshBillSelect = lazy(() => import("../Items/Select/Index"));

const UpdateRouterRadiusPortsModal: React.FC<
  UpdateRouterRadiusProbesModalProp
> = ({
  show,
  handleClose,
  intPkMeshBillRadiusProbeID,
  intPkNasDeviceID,
  childProbeAccountingID,
  childProbeAuthID,
}) => {
  const { openNotification, contextHolder } = useMultiNotification();
  const [form] = Form.useForm();
  const [selectedProbe, setSelectedProbe] = useState(
    intPkMeshBillRadiusProbeID
  );
  const [probes, setProbes] = useState<MeshBillSelectOptions[]>([]);
  const [authPorts, setAuthPorts] = useState<MeshBillSelectOptions[]>([]);
  const [acctPorts, setAcctPorts] = useState<MeshBillSelectOptions[]>([]);
  const [getPortsApi, portsApiResponse] =
    useAxiosPost<GetChildProbesByParentApiProp>(
      "radiusProbes/getChildProbesByRadiusProbe"
    );
  const [radiusProbesApi, radiusProbesResponse] = useAxiosPost<ResponseProp>(
    "radiusProbes/getRadiusProbes"
  );
  useEffect(() => {
    if (radiusProbesResponse !== null) {
      const data: any = radiusProbesResponse.responseData;
      const areas = data.map((item: any): MeshBillSelectOptions => {
        return {
          value: item.intPkMeshBillRadiusProbeID,
          label: item.radiusProbeIP,
        };
      });
      form.setFieldsValue({ intPkMeshBillRadiusProbeID });
      if (Number(selectedProbe) === Number(intPkMeshBillRadiusProbeID)) {
        form.setFieldsValue({ childProbeAccountingID, childProbeAuthID });
      }
      setProbes(areas);
    }
  }, [radiusProbesResponse]);
  useEffect(() => {
    if (portsApiResponse !== null) {
      const data: any = portsApiResponse.responseData;
      const _accPorts: MeshBillSelectOptions[] = [];
      const _authPorts: MeshBillSelectOptions[] = [];
      for (const item of data) {
        if (item.portType === "AUTH") {
          _authPorts.push({
            label: item.port,
            value: item.intPkMeshBillRadiusProbeChildID,
          });
        } else {
          _accPorts.push({
            label: item.port,
            value: item.intPkMeshBillRadiusProbeChildID,
          });
        }
      }
      setAuthPorts(_authPorts);
      setAcctPorts(_accPorts);
    }
  }, [portsApiResponse]);
  useEffect(() => {
    radiusProbesApi({});
    if (intPkMeshBillRadiusProbeID) {
      getPortsApi({ intPkMeshBillRadiusProbeID });
    }
  }, []);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<UpdateRadiusProbesApiProp>(
    "routers/updateRadiusProbes",
    true
  );

  const onFinish = (values: any) => {
    postApiData({
      intPkNasDeviceID,
      childProbeAccountingID: Number(values.childProbeAccountingID),
      childProbeAuthID: Number(values.childProbeAuthID),
    });
  };
  useEffect(() => {
    if (apiResponse !== null) {
      const { message } = apiResponse;
      if (message) {
        openNotification("success", "Success", message);
        setTimeout(() => {
          handleClose(true);
        }, 2500);
      }
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification("error", "Error", apiError);
    }
  }, [apiError, apiErrorCount]);
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleRadiusProbeChange = (value: string) => {
    form.setFieldsValue({
      childProbeAccountingID: undefined,
      childProbeAuthID: undefined,
    });

    getPortsApi({ intPkMeshBillRadiusProbeID: Number(value) });
  };

  return (
    <>
      <MeshBillModal
        title="Update Router Radius Probes"
        headerStyle={{
          backgroundColor: "var(--primary-background-color)",
          color: "var(--primary-text-color)",
          fontSize: "16px",
          fontWeight: "var(--fw-700)",
          height: "50px",
        }}
        open={show}
        onCancel={() => {
          if (apiLoading) {
            return;
          }
          handleClose(false);
        }}
      >
        {contextHolder}

        <Row>
          <Col
            span={24}
            className="meshbill-create-record-modal-form-container"
          >
            <Form
              //   requiredMark={false}
              style={{ width: "100%", marginTop: "50px" }}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              name="updateRouterRadiusProbes"
              form={form}
            >
              <Form.Item
                label={
                  <span className="create-user-form-label">Radius Probe</span>
                }
                name="intPkMeshBillRadiusProbeID"
                rules={[
                  {
                    required: true,
                    message: "Please Select Probe",
                  },
                ]}
              >
                <MeshBillSelect
                  value={selectedProbe}
                  onChange={handleRadiusProbeChange}
                  size="large"
                  height="45px"
                  options={probes}
                />
              </Form.Item>

              <Form.Item
                label={
                  <span className="create-user-form-label">
                    Choose Accounting Port
                  </span>
                }
                name="childProbeAccountingID"
                rules={[
                  {
                    required: true,
                    message: "Please Select Port",
                  },
                ]}
              >
                <MeshBillSelect
                  // value={selectedProbe}
                  // onChange={handleRadiusProbeChange}
                  size="large"
                  height="45px"
                  options={acctPorts}
                />
              </Form.Item>

              <Form.Item
                label={
                  <span className="create-user-form-label">
                    Choose Auth Port
                  </span>
                }
                name="childProbeAuthID"
                rules={[
                  {
                    required: true,
                    message: "Please Select Port",
                  },
                ]}
              >
                <MeshBillSelect
                  // value={selectedProbe}
                  // onChange={handleRadiusProbeChange}
                  size="large"
                  height="45px"
                  options={authPorts}
                />
              </Form.Item>

              <Form.Item>
                <MeshBillButton
                  backgroundColor={ButtonProps.background.STANDARD}
                  height={ButtonProps.height.STANDARD}
                  width={`100%`}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {}}
                  htmlType="submit"
                  text="Submit"
                  disabled={apiLoading}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </MeshBillModal>
    </>
  );
};
export default UpdateRouterRadiusPortsModal;
