import React, { useState } from "react";
import { Button, Row, Col, Typography, message, Popconfirm } from "antd";
import Axios from "axios";
import useMultiNotification from "../../hooks/useNotification";
import { baseURL } from "../../helpers/baseUrl";
function DeleteAccountPage() {
  const { openNotification, contextHolder } = useMultiNotification();

  const deleteAccount = async () => {
    try {
      const dataToSend: any = {
        authTokenString:
          localStorage.getItem("authTokenToDeleteAccount") || null,
      };
      const res = await Axios.post(
        `${baseURL}/auth/deleteMyAccount`,
        dataToSend
      );
      console.log({ res });
      localStorage.removeItem("authTokenToDeleteAccount");
      window.location.href = "/login";
    } catch (error: any) {
      console.log({ error: error.response.data.message });
      notifyError(error.response.data.message || `Some Error Occurs`);
    }
  };
  const notifyError = (message: string) => {
    openNotification(`error`, "Operation Failed", message);

    // dispatch(noError(""));
  };

  const confirm = (e: any) => {
    console.log(e);
    deleteAccount();
  };

  const cancel = (e: any) => {
    console.log("you clicked cancel");
  };

  return (
    <>
      {contextHolder}

      <Row>
        <Col style={{ padding: "50px" }} span={12} offset={6}>
          <div>
            <h1>Delete Your Account</h1>
            <Typography>
              We're sorry to see you go. Are you sure you want to delete your
              account? This action is irreversible, and you will lose all of
              your data. If you're sure, please click the button below to delete
              your account.
            </Typography>

            <Popconfirm
              title="Delete the task"
              description="Are you sure to delete your account?"
              onConfirm={confirm}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" danger>
                Delete Account
              </Button>
            </Popconfirm>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default DeleteAccountPage;
