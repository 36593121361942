import React from "react";
import ApexCharts from "react-apexcharts";
import { ApexOptions } from "apexcharts";

// interface DataPoint {
//   downloadMbs: number;
//   uploadMbs: number;
// }

// interface SplineChartProps {
//   data: DataPoint[];
// }

const SplineChart: React.FC<SplineChartProps> = ({ data }) => {
  // Prepare data for the chart
  const labels = data.map((_, index) => index.toString());
  const downloadData = data.map((point) => point.downloadMbs);
  const uploadData = data.map((point) => point.uploadMbs);

  const chartOptions: ApexOptions = {
    chart: {
      type: "area",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: labels,
      title: {
        text: "Entries",
      },
      labels: {
        show: false, // Hide x-axis labels
      },
    },
    yaxis: {
      title: {
        text: "Speed (MB/s)",
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    fill: {
      opacity: 0.5, // Adjust the opacity for the area fill
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
  };

  const chartSeries = [
    {
      name: "Download Speed",
      data: downloadData,
    },
    {
      name: "Upload Speed",
      data: uploadData,
    },
  ];

  return (
    <div>
      <ApexCharts
        options={chartOptions}
        series={chartSeries}
        type="area"
        height={350}
      />
    </div>
  );
};

export default SplineChart;
