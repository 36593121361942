import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ViewConnectedPPPoeUsersComponent from "../components/PPPoEUsers/ConnectedUsers";
// const ConnectedUsersComponent = lazy(
//   () => import("../components/PPPoEUsers/ConnectedUsers")
// );
const ViewConnectedUsersPage = () => {
  const { setTitle } = useContext(TitleContext);
  useEffect(() => {
    setTitle("Connected PPPoE Users");
  }, [setTitle]);

  return (
    <>
      <ViewConnectedPPPoeUsersComponent />
    </>
  );
};
export default ViewConnectedUsersPage;
