import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ViewRoutersComponent from "../components/routers/ViewRouters";
// const ViewRoutersComponent = lazy(
//   () => import("../components/routers/ViewRouters")
// );
const ViewRoutersPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Routers");
  }, [setTitle]);

  return (
    <>
      <ViewRoutersComponent />
    </>
  );
};
export default ViewRoutersPage;
