import React from "react";
import { Col, Row, Table, Typography } from "antd";
import type { TableProps } from "antd";
import CopyToClipboard from "react-copy-to-clipboard";
import { MdContentCopy } from "react-icons/md";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";
import { formatDate } from "../../helpers/date";
import "./../../styles/tables.css";

const RedeemedVouchersTable: React.FC<RedeemedDiscountVouchersTableProp> = ({
  vouchers,
  isLoading,
}) => {
  const columns: TableProps<RedeemedDiscountVouchersProp>["columns"] = [
    {
      title: "Voucher Number",
      render: (row) => (
        <Typography
          style={{ display: "flex", alignContent: "center", gap: "10px" }}
        >
          {row.voucherNumber}
          <CopyToClipboard text={row.voucherNumber || ""} onCopy={() => {}}>
            <MdContentCopy
              onClick={(e) => {
                e.preventDefault();
              }}
              className="cursor-pointer "
            />
          </CopyToClipboard>
        </Typography>
      ),
    },
    {
      title: "Amount",
      dataIndex: "discountAmount",
      key: "discountAmount",
    },
    {
      title: "Type",
      dataIndex: "voucherType",
      key: "voucherType",
      render: (text) => (
        <span>{text === "ONCE" ? "One Time" : "Every Month"}</span>
      ),
    },
    {
      title: "Created",
      dataIndex: "dateCreated",
      key: "dateCreated",
      render: (text) => <span>{formatDate(new Date(text))}</span>,
    },
    {
      title: "Customer",
      render: (row) => <span>{`${row.firstName + " " + row.lastName}`}</span>,
    },
    {
      title: "Used By",
      render: (row) => <span>{`${row.staffUserName} (${row.staffRole})`}</span>,
    },
    {
      title: "Used On",
      dataIndex: "dateRedeemed",
      key: "dateRedeemed",
      render: (text) => (
        <span className="voucher-redeemed-date">
          {formatDate(new Date(text))}
        </span>
      ),
    },
  ];

  return (
    <>
      <div className="general-table-container">
        {isLoading === true ? (
          <TailSpinLoader />
        ) : (
          <Row>
            <Col span={24}>
              <Typography className="general-table-heading">
                Redeemed Vouchers
              </Typography>
              <Table
                className="striped-table"
                columns={columns}
                dataSource={vouchers}
                pagination={false}
              />
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
                marginBottom: "20px",
              }}
              span={24}
            ></Col>
          </Row>
        )}
      </div>
    </>
  );
};
export default RedeemedVouchersTable;
