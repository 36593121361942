import React, { useContext, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

import { Menu, Layout, Breadcrumb, Typography } from "antd";
import type { MenuProps, MenuTheme } from "antd/es/menu";
import "./layout.css";
import { AppRoutes } from "../../data";
import { useLocation, useNavigate, NavLink } from "react-router-dom";
import TopNavBar from "./TopNav";
import PageTitle from "./CurrentPageTitle";
import TitleContext from "../../context/TitleContext";
import HomePage from "../../pages/HomePage";
const { Sider, Header, Content } = Layout;
type MenuItem = Required<MenuProps>["items"][number];

const AppLayout = () => {
  const { title } = useContext(TitleContext);
  const isLoggedIn = localStorage.getItem("auth_token") || false;
  const [items, setItems] = useState<MenuItem[]>([]);
  const [companyName, setCompanyName] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const routes = AppRoutes();
    const _items = routes
      .filter((item: any) => item.show === true)
      .map((item: any) => {
        const { Icon, children } = item;
        const routeItem: any = {
          key: item.path,
          icon: (
            <Icon
              className={`custom-menu-icon ${
                location.pathname === item.path ? "" : "non-active-menu-icon"
              }`}
            />
          ),
          // label: <NavLink to={item.path}>{item.text}</NavLink>,
          label:
            children?.length > 0 ? (
              item.text
            ) : (
              <NavLink to={item.path}>{item.text}</NavLink>
            ),
        };
        if (children?.length > 0) {
          routeItem.children = children.map((_item: any) => {
            return {
              key: _item.path,
              icon: (
                <Icon
                  className={`custom-menu-icon ${
                    location.pathname === _item.path
                      ? ""
                      : "non-active-menu-icon"
                  }`}
                />
              ),
              label: <NavLink to={_item.path}>{_item.text}</NavLink>,
              // label: _item.text,
            };
          });
        }
        return routeItem;
      });
    console.log({ _items });
    setItems(_items);
  }, [location]);

  const [routes, setRoutes] = useState<any>([]);

  useEffect(() => {
    setRoutes(AppRoutes());
  }, []);
  const flattenRoutes = (routes: Route[]): Route[] => {
    let result: Route[] = [];

    const traverse = (routes: Route[]) => {
      routes.forEach((route) => {
        result.push(route);
        if (route.children && route.children.length > 0) {
          traverse(route.children);
        }
      });
    };

    traverse(routes);
    return result;
  };
  const renderRoutes = (routes: any[]) => {
    const flattedRoutes = flattenRoutes(routes);
    return flattedRoutes.map((route: any) => {
      const { path, Component } = route;
      return (
        <>
          <Route path={path} key={path} element={<Component />} />
        </>
      );
    });
  };
  const getMeCompanyName = (companyName: string | null) => {
    console.log({ companyName });
    setCompanyName(companyName);
  };
  return (
    <>
      <Layout>
        <Header
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0px",
            height: "120px",
            background: "white",
          }}
        >
          <div className="top-nav-bar-custom">
            <div className="logo ">
              <Typography className="meshbill-basic-dashboard-logo">
                MeshBill Basic
              </Typography>
              {companyName !== null && (
                <Typography className="">{companyName}</Typography>
              )}
            </div>
            <div className="top-nav-sub-item">
              <PageTitle title={title} />
              <TopNavBar getMeCompanyName={getMeCompanyName} />
            </div>
          </div>
        </Header>
        <Layout>
          <Sider
            className="side-bar-main"
            style={{ minHeight: "100vh" }}
            width={200}
          >
            <Menu
              mode="inline"
              selectedKeys={[location.pathname]}
              onClick={(e) => {
                navigate(e.key);
              }}
              defaultOpenKeys={["sub1"]}
              style={{ height: "100%", borderRight: 0, width: "inherit" }}
              defaultSelectedKeys={["1"]}
              items={items}
              className="side-bar-menu-items-container"
              // theme={theme}
            />
          </Sider>
          <Layout style={{ padding: "0 24px 24px", background: "#F3F6FF" }}>
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
              }}
            >
              <Routes>
                <Route path="/" element={<HomePage />} />
                {
                  isLoggedIn && renderRoutes(routes)
                  // routes.map((route: any) => {
                  //   return (
                  //     <Route
                  //       path={route.path}
                  //       key={Math.random()}
                  //       element={<route.Component />}
                  //     />
                  //   );
                  // })
                }
              </Routes>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default AppLayout;
