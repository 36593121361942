import { Col, Row, Typography, Radio } from "antd";
import React, { useEffect, useState } from "react";
import useAxiosPost from "../../../hooks/useAxiosPost";
import dayjs, { Dayjs } from "dayjs";
import MonthlyBilledAndPaymentsChart from "../../Charts/MonthlyBilledAndPaymentsChart";
import MonthlyUserCreationChart from "../../Charts/MonthlyUserCreationChart";
import DailyUserCreationChart from "../../Charts/DailyUserCreationChart";

function UserCreationStats() {
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<DashboardStatsForOwnerApiProp>(
    "reports/dashboard/getNewEndUsersReportsMonthlyByOwner"
  );

  const [
    postDayApiData,
    dayApiResponse,
    dayApiError,
    dayApiLoading,
    dayApiMessage,
    dayApiErrorCount,
  ] = useAxiosPost<DashboardStatsForOwnerApiProp>(
    "reports/dashboard/getNewEndUsersReportsDailyByOwner"
  );

  const [dates, setDates] = useState<[Dayjs | null, Dayjs | null]>([
    dayjs().subtract(1, "year"),
    dayjs(),
  ]);
  const [data, setData] = useState<UserCreationStatsRecord[]>([]);
  const [dailyData, setDailyData] = useState<UserCreationDailyStatRecord[]>([]);

  const [view, setView] = useState("monthly");

  const handleToggleChange = (e: any) => {
    setView(e.target.value);
  };

  useEffect(() => {
    const endDate = dayjs().format("YYYY-MM-DD");
    const startDate = dayjs().subtract(1, "year").format("YYYY-MM-DD");
    console.log({ startDate, endDate });
    postApiData({ startDate, endDate });
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      console.log({ data });
      setData(data || []);
    }
  }, [apiResponse]);

  useEffect(() => {
    if (dayApiResponse !== null) {
      const data: any = dayApiResponse.responseData;
      console.log({ data });
      setDailyData(data || []);
    }
  }, [dayApiResponse]);
  useEffect(() => {
    console.log({ view });
    if (view === "daily") {
      const endDate = dayjs().format("YYYY-MM-DD");
      const startDate = dayjs().subtract(1, "month").format("YYYY-MM-DD");
      postDayApiData({ startDate, endDate });
    } else if (view === "monthly") {
      const endDate = dayjs().format("YYYY-MM-DD");
      const startDate = dayjs().subtract(1, "year").format("YYYY-MM-DD");
      console.log({ startDate, endDate });
      postApiData({ startDate, endDate });
    }
  }, [view]);
  return (
    <Row className="chart-container">
      <div className="chart-container-header">
        <Typography className="chart-container-heading">
          {view === "monthly" ? "Monthly" : "Daily"} New User Creation Summary
        </Typography>
        <Radio.Group
          value={view}
          onChange={handleToggleChange}
          buttonStyle="solid"
        >
          <Radio.Button value="monthly">Monthly</Radio.Button>
          <Radio.Button value="daily">Daily</Radio.Button>
        </Radio.Group>
      </div>
      <Col span={24}>
        {view === "daily" ? (
          <DailyUserCreationChart data={dailyData} />
        ) : (
          <MonthlyUserCreationChart data={data} />
        )}{" "}
      </Col>
    </Row>
  );
}

export default UserCreationStats;
