import React, { useState } from "react";
import { Col, Dropdown, Row, Table, Tag, Typography, Input } from "antd";
import type { MenuProps, TableProps } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import "./../../styles/tables.css";

import { useNavigate } from "react-router-dom";
import {
  formatDate,
  formatDateForJustMonthDayAndYear,
} from "../../helpers/date";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";

// const TailSpinLoader = lazy(() => import("../Items/Loaders/TailSpinLoader"));

const ReportTableForDealerPayment: React.FC<DealerPaymentReportsTableProp> = ({
  reports,
  isLoading,
  filters,
  handleFilterChange,
}) => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: reports.length, // Total number of items
  });

  // Handle page size change
  const handlePageSizeChange = (value: number) => {
    setPagination({
      ...pagination,
      pageSize: value,
      current: 1, // Reset to first page when page size changes
    });
  };

  // Handle page change
  const handleTableChange = (pagination: any) => {
    setPagination(pagination);
  };
  const columns: TableProps<DealerPaymentReportRow>["columns"] = [
    {
      title: "Transaction Date",
      render: (row) =>
        row.isFilterRow === true ? (
          <span></span>
        ) : (
          <span>{formatDate(row.dealerTransactionCreated)}</span>
        ),
    },
    {
      title: "Received By",
      render: (row) =>
        row.isFilterRow === true ? (
          <Input
            value={filters.receivedBy}
            onChange={(e) => handleFilterChange("receivedBy", e.target.value)}
          />
        ) : (
          <span>{row.receivedByUserName}</span>
        ),
    },
    {
      title: "Payer",
      render: (row) =>
        row.isFilterRow === true ? (
          <Input
            value={filters.payer}
            onChange={(e) => handleFilterChange("payer", e.target.value)}
          />
        ) : (
          <span>{row.staffUserName}</span>
        ),
    },
    {
      title: "Transaction ID",
      render: (row) =>
        row.isFilterRow === true ? (
          <Input
            value={filters.transactionID}
            onChange={(e) =>
              handleFilterChange("transactionID", e.target.value)
            }
          />
        ) : (
          <span>{row.intPkDealerTransactionID}</span>
        ),
    },
    {
      title: "Method",
      render: (row) =>
        row.isFilterRow === true ? <></> : <span>{`Top Up`}</span>,
    },
    {
      title: "Amount",
      render: (row) =>
        row.isFilterRow === true ? (
          <Input
            value={filters.amount}
            onChange={(e) => handleFilterChange("amount", e.target.value)}
          />
        ) : (
          <span>{row.topUpAmount}</span>
        ),
    },
  ];

  return (
    <div className="general-table-container">
      {isLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row>
          <Col span={24}>
            <Typography className="general-table-heading">
              Dealer Payment Reports
            </Typography>
            <Table
              className=" striped-table"
              columns={columns}
              dataSource={reports}
              pagination={{
                ...pagination,
                showSizeChanger: true, // Show page size changer
                pageSizeOptions: ["5", "10", "20", "50", "100"], // Options for page sizes
                onChange: (page, pageSize) =>
                  handleTableChange({ ...pagination, current: page, pageSize }),
                onShowSizeChange: (current, size) => handlePageSizeChange(size),
              }}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};
export default ReportTableForDealerPayment;
