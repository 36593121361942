import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ViewStaffProfileComponent from "../components/staff/ViewStaffProfile";
// const ViewStaffProfileComponent = lazy(
//   () => import("../components/staff/ViewStaffProfile")
// );

const StaffProfilePage = () => {
  const { setTitle } = useContext(TitleContext);
  useEffect(() => {
    setTitle("Staff Profile");
  }, [setTitle]);
  return (
    <>
      <ViewStaffProfileComponent />
    </>
  );
};
export default StaffProfilePage;
