import { useContext, useEffect, lazy } from "react";
import TitleContext from "../context/TitleContext";
import ViewProfileComponent from "../components/auth/ViewProfile";
// const ViewProfileComponent = lazy(
//   () => import("../components/auth/ViewProfile")
// );
const ViewMyProfilePage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("My Profile");
  }, [setTitle]);
  return (
    <>
      <ViewProfileComponent />
    </>
  );
};
export default ViewMyProfilePage;
