import {
  Col,
  Dropdown,
  MenuProps,
  Row,
  Typography,
  Switch,
  Button,
} from "antd";
import { MdEdit } from "react-icons/md";

import { formatDateForJustMonthDayAndYear } from "../../helpers/date";
import { MenuOutlined } from "@ant-design/icons";
interface StaffSingleRowSimpleProp {
  label: string;
  value: string;
  isBorderBottom: boolean;
}
const SingleRowSimple: React.FC<StaffSingleRowSimpleProp> = ({
  label,
  value,
  isBorderBottom = true,
}) => {
  return (
    <Col
      span={24}
      className={`profile-card-single-row ${
        isBorderBottom === true ? "border-bottom-1px" : ""
      }`}
    >
      <div className="values-container">
        <Typography className="user-label-primary">{label}</Typography>
        <Typography className="user-value"> {value}</Typography>
      </div>
    </Col>
  );
};
const ViewStaffDetailsCard: React.FC<StaffProfileCardProp> = ({
  staff,
  handleStaffProfileUpdate,
  handleStaffPasswordUpdate,
  handleStatusUpdate,
  handleWalletSDaysUpdate,
  handleWalletLimitUpdate,
  handleWalletDaysReset,
}) => {
  const staffOptions: MenuProps["items"] = [
    {
      key: "UPDATE_PROFILE",
      label: (
        <Typography className="general-label-class">Update Profile</Typography>
      ),
    },
    {
      key: "UPDATE_PASSWORD",
      label: (
        <Typography className="general-label-class">Update Password</Typography>
      ),
    },
  ];

  const handleMenuClick = (e: any) => {
    switch (e.key) {
      case "UPDATE_PROFILE":
        handleStaffProfileUpdate();
        break;
      case "UPDATE_PASSWORD":
        handleStaffPasswordUpdate();
        break;
    }
  };
  const onChange = (checked: boolean) => {
    console.log(`switch to ${checked}`);
    handleStatusUpdate(checked);
  };
  return (
    <>
      <Row gutter={[16, 16]} className="full-width-row">
        {/* LEFT CARD */}
        <Col lg={12} xl={12} xxl={12} sm={24} md={12}>
          <Row className="staff-profile-card-container">
            <Col
              span={24}
              className="staff-details-title-label-container border-bottom-1px"
            >
              <Typography className="staff-profile-card-title">
                Staff Profile
              </Typography>
              <Dropdown
                menu={{
                  items: staffOptions,
                  onClick: handleMenuClick,
                }}
                placement="bottomRight"
              >
                <MenuOutlined className="cursor-pointer" />
              </Dropdown>
            </Col>

            <SingleRowSimple
              label="Full Name"
              value={`${staff.firstName} ${staff.lastName}`}
              isBorderBottom={true}
            />
            <SingleRowSimple
              label="Address"
              value={`${staff.address}`}
              isBorderBottom={true}
            />

            {/* <SingleRowSimple
              label="Status"
              value={staff.enable === 1 ? "Enabled" : "Disabled"}
              isBorderBottom={true}
            /> */}

            {/* Enable/Disable */}

            <SingleRowSimple
              label="Created"
              value={formatDateForJustMonthDayAndYear(
                new Date(staff.dateCreated)
              )}
              isBorderBottom={true}
            />
            {/* 
            <SingleRowSimple
              label="Last Deposit"
              value={
                staff.lastDepositDate
                  ? formatDateForJustMonthDayAndYear(
                      new Date(staff.lastDepositDate)
                    )
                  : "-"
              }
              isBorderBottom={true}
            /> */}
            <Col
              span={24}
              className={`profile-card-single-row border-bottom-1px`}
            >
              <div className="values-container">
                <Typography className="user-label-primary">
                  Last Deposit
                </Typography>
                <Typography className="user-value">
                  {staff.lastDepositDate
                    ? formatDateForJustMonthDayAndYear(
                        new Date(staff.lastDepositDate)
                      )
                    : "-"}
                </Typography>
              </div>
            </Col>

            <Col span={24} className={`profile-card-single-row `}>
              <div className="values-container">
                <Typography className="user-label-primary">Status</Typography>
                <Typography
                  className={
                    staff.enable === 1
                      ? "balance-available-label"
                      : "balance-minus-label"
                  }
                >
                  <Switch value={Boolean(staff.enable)} onChange={onChange} />{" "}
                  {staff.enable === 1 ? "Enabled" : "Disabled"}
                </Typography>
              </div>
            </Col>
          </Row>
        </Col>
        {/* Right Card */}
        <Col lg={12} xl={12} xxl={12} sm={24} md={12}>
          <Row className="staff-profile-card-container">
            <SingleRowSimple
              label="UserName"
              value={`${staff.staffUserName}`}
              isBorderBottom={true}
            />
            <SingleRowSimple
              label="Email"
              value={`${staff.email ? staff.email : "--"}`}
              isBorderBottom={true}
            />
            <SingleRowSimple
              label="Phone Number"
              value={`${staff.phoneNumber ? staff.phoneNumber : "--"}`}
              isBorderBottom={true}
            />
            {/* <SingleRowSimple
              label="Wallet Limit"
              value={`${staff.walletLimit} PKR`}
              isBorderBottom={true}
            /> */}
            {/* <SingleRowSimple
              label="Wallet Days"
              value={`${staff.walletDays} Days`}
              isBorderBottom={true}
            /> */}

            <Col
              span={24}
              className={`profile-card-single-row border-bottom-1px`}
            >
              <div className="values-container">
                <Typography className="user-label-primary">
                  Wallet Limit
                </Typography>
                <Typography className="user-value">
                  {staff.walletLimit.toLocaleString()} PKR{" "}
                  <MdEdit
                    onClick={(e) => {
                      e.preventDefault();
                      handleWalletLimitUpdate();
                    }}
                    className="cursor-pointer"
                  />
                </Typography>
              </div>
            </Col>

            <Col
              span={24}
              className={`profile-card-single-row border-bottom-1px`}
            >
              <div className="values-container">
                <Typography className="user-label-primary">
                  Wallet Days
                </Typography>
                <Typography className="user-value">
                  {staff.walletDays} Days{" "}
                  <MdEdit
                    onClick={(e) => {
                      e.preventDefault();
                      handleWalletSDaysUpdate();
                    }}
                    className="cursor-pointer"
                  />
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleWalletDaysReset();
                    }}
                    style={{ marginLeft: "10px" }}
                    type="primary"
                    danger
                  >
                    Reset Days Limit
                  </Button>
                </Typography>
              </div>
            </Col>

            <Col span={24} className={`profile-card-single-row `}>
              <div className="values-container">
                <Typography className="user-label-primary">
                  Available Balance
                </Typography>
                <Typography className="balance-available-label">
                  {staff?.userBalance?.cashAvailable?.toLocaleString() || 0} PKR
                </Typography>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default ViewStaffDetailsCard;
