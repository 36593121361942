import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { ButtonProps } from "../../helpers/constants";
import { FiPlusCircle } from "react-icons/fi";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ViewAllPPPoEUsersComponent from "./All";
import ViewExpiredUsersComponent from "./Expired";
import MeshBillSelect from "../Items/Select/Index";
import MeshBillButton from "../Items/Button/Index";
import ViewOfflineUsersComponent from "./Offline";
import ViewExpiringUsersComponent from "./Expiring";
import ViewActivePPPoEUsersComponent from "./Active";
import ViewDisabledPPPoEUsersComponent from "./Disabled";
const tabsOptions = {
  all: "all",
  expiring: "expiring",
  expired: "expired",
  offline: "offline",
  active: "active",
  disabled: "disabled",
};
const ViewPPPoEUsersComponent = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const _activeTab = (searchParams.get("activeTab") || "ALL").toLowerCase();
  console.log({ _activeTab });

  const getActiveComponent = (activeTab: string) => {
    switch (activeTab) {
      case tabsOptions.all:
        return <ViewAllPPPoEUsersComponent />;
      case tabsOptions.expired:
        return <ViewExpiredUsersComponent />;
      case tabsOptions.expiring:
        return <ViewExpiringUsersComponent />;
      case tabsOptions.offline:
        return <ViewOfflineUsersComponent />;
      case tabsOptions.active:
        return <ViewActivePPPoEUsersComponent />;
      case tabsOptions.disabled:
        return <ViewDisabledPPPoEUsersComponent />;
      default:
        return <ViewAllPPPoEUsersComponent />;
    }
  };
  return (
    <>
      <Row className="full-width-row">
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: "20px",
          }}
          lg={6}
          xl={6}
          xxl={6}
          md={12}
          sm={24}
          span={18}
        >
          <MeshBillSelect
            size="small"
            height="45px"
            value={_activeTab}
            onChange={(e) => {
              console.log(e);
              navigate(`/pppoe-users?activeTab=${e}`);
            }}
            options={[
              { label: "All Users", value: "all" },
              { label: "Active Users", value: "active" },
              { label: "Expiring Users", value: "expiring" },
              { label: "Expired Users", value: "expired" },
              { label: "Offline Users", value: "offline" },
              { label: "Disabled Users", value: "disabled" },
            ]}
          />
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
          }}
          lg={18}
          xl={18}
          xxl={18}
          md={12}
          sm={24}
          span={18}
        >
          <Link to={`/create-pppoe-user`}>
            <MeshBillButton
              backgroundColor={ButtonProps.background.STANDARD}
              height={ButtonProps.height.STANDARD}
              width={ButtonProps.width.STANDARD}
              textColor={ButtonProps.color.STANDARD}
              onClick={() => {}}
              text="Create User"
              icon={<FiPlusCircle />}
            />
          </Link>
        </Col>
        <Col span={24}>{getActiveComponent(_activeTab)}</Col>
      </Row>
    </>
  );
};
export default ViewPPPoEUsersComponent;
