import React, { useState } from "react";
import { Col, Dropdown, Row, Table, Tag, Typography } from "antd";
import type { MenuProps, TableProps } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import "./../../styles/tables.css";

import { useNavigate } from "react-router-dom";
import { formatDateForJustMonthDayAndYear } from "../../helpers/date";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";

// const TailSpinLoader = lazy(() => import("../Items/Loaders/TailSpinLoader"));

const ViewDealersTable: React.FC<DealersTableProp> = ({
  dealers,
  isLoading,
  handleStaffProfileUpdate,
  handleStaffPasswordUpdate,
}) => {
  const [activeRow, setActiveRow] = useState<Dealer | null>(null);

  const staffOptions: MenuProps["items"] = [
    {
      key: "VIEW_PROFILE",
      label: (
        <Typography className="general-label-class">View Details</Typography>
      ),
    },
    {
      key: "UPDATE_PROFILE",
      label: (
        <Typography className="general-label-class">Update Profile</Typography>
      ),
    },
    {
      key: "UPDATE_PASSWORD",
      label: (
        <Typography className="general-label-class">Update Password</Typography>
      ),
    },
  ];

  const handleMenuClick = (e: any) => {
    if (!activeRow) {
      return;
    }
    const fullName = activeRow.firstName + " " + activeRow.lastName;
    switch (e.key) {
      case "VIEW_PROFILE":
        return navigate(
          `/dealer-profile/${activeRow.intPkHeadID}?activeTab=1&fullName=${fullName}`
        );
        break;
      case "UPDATE_PROFILE":
        handleStaffProfileUpdate(activeRow);
        break;
      case "UPDATE_PASSWORD":
        handleStaffPasswordUpdate(activeRow);
        break;
    }
  };

  const navigate = useNavigate();
  const columns: TableProps<Dealer>["columns"] = [
    {
      title: "Name",
      render: (row) => <span>{`${row.firstName} ${row.lastName}`}</span>,
    },
    {
      title: "UserName",
      dataIndex: "staffUserName",
      key: "staffUserName",
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (text) => <span>{text ? text : "---"}</span>,
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      render: (text) => <span>{text ? text : "---"}</span>,
    },

    {
      title: "Credit Allowed",
      render: (row) => (
        <span
          className={`${
            Number(row.isCreditAllowed) === 1
              ? "balance-available-label"
              : "balance-minus-label"
          }`}
        >{`${Number(row.isCreditAllowed) === 1 ? "Yes" : "No"}`}</span>
      ),
    },
    {
      title: "Credit Limit",
      render: (row) => <span>{`${row.creditLimit.toLocaleString()}`}</span>,
    },

    {
      title: "Created",
      dataIndex: "dateCreated",
      render: (text) => (
        <span>{formatDateForJustMonthDayAndYear(new Date(text))}</span>
      ),
    },

    {
      title: "Action",
      render: (row) => (
        <Dropdown
          menu={{
            items: staffOptions,
            onClick: handleMenuClick,
          }}
          placement="bottomRight"
        >
          <MenuOutlined
            onMouseEnter={(e) => {
              setActiveRow(row);
            }}
            className="cursor-pointer"
          />
        </Dropdown>
      ),
    },
  ];

  return (
    <div className="general-table-container">
      {isLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row>
          <Col span={24}>
            <Typography className="general-table-heading">
              Staff List
            </Typography>
            <Table
              className=" striped-table"
              columns={columns}
              dataSource={dealers}
              onRow={(record, rowIndex) => {
                return {};
              }}
              pagination={false}
            />
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
              marginBottom: "20px",
            }}
            span={24}
          ></Col>
        </Row>
      )}
    </div>
  );
};
export default ViewDealersTable;
