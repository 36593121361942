import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ViewRadiusProbesComponent from "../components/probes/ViewRadiusProbes";
// const ViewRadiusProbes = lazy(
//   () => import("../components/probes/ViewRadiusProbes")
// );
const ViewRadiusProbesPage = () => {
  const { setTitle } = useContext(TitleContext);
  useEffect(() => {
    setTitle("Radius Probes");
  }, [setTitle]);
  return (
    <>
      <ViewRadiusProbesComponent />
    </>
  );
};
export default ViewRadiusProbesPage;
