import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const SalesUnitsGeneralChart: React.FC<CompanyPackageWiseSalesChartProp> = ({
  packages,
}) => {
  const categories = packages.map((pkg) => pkg.month_name);
  const totalUnits = packages.map((pkg) => pkg.total_units);
  const totalSales = packages.map((pkg) => pkg.total_sales);

  const chartOptions: ApexOptions = {
    chart: {
      type: "line",
      height: 350,
    },
    plotOptions: {
      bar: {
        columnWidth: "55%",
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      show: true,
      width: [0, 2],
      curve: "smooth",
    },
    xaxis: {
      categories,
      labels: {
        rotate: -75, // Rotate the labels to display them vertically
        rotateAlways: true, // Ensure rotation is applied

        style: {
          fontSize: "12px",
        },
      },
    },
    yaxis: [
      {
        title: {
          text: "Total Units",
        },
      },
      {
        opposite: true,
        title: {
          text: "Total Sales (PKR)",
        },
      },
    ],
    tooltip: {
      shared: true,
      intersect: false,
      y: [
        {
          formatter: (val: number) => `${val} units`,
        },
        {
          formatter: (val: number) => ` ${val}`,
        },
      ],
    },
  };

  const series = [
    {
      name: "Total Units",
      type: "column",
      data: totalUnits,
    },
    {
      name: "Total Sales",
      type: "line",
      data: totalSales,
    },
  ];

  return (
    <div>
      <ReactApexChart
        options={chartOptions}
        series={series}
        type="line"
        height={650}
      />
    </div>
  );
};

export default SalesUnitsGeneralChart;
