import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table, Tag, Typography } from "antd";
import "./../../styles/tables.css";
import MeshBillSelect from "../Items/Select/Index";
import MeshBillButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import useMultiNotification from "../../hooks/useNotification";
import { render } from "@testing-library/react";
const ImportUsersThroughFileTable: React.FC<
  ImportUsersThroughFileTableProp
> = ({
  users,
  packageOptions,
  isUploading,
  // handleUserSkip,
  handleUploadUsers,
}) => {
  const { openNotification, contextHolder } = useMultiNotification();

  const [activeRow, setActiveRow] = useState<UsersCsvData | null>(null);
  const [data, setData] = useState<UsersCsvData[]>([]);
  useEffect(() => {
    setData(users);
  }, [users]);

  const handlePackageChange = (value: string, key: number) => {
    const updatedData = data.map((item) =>
      item.key === key ? { ...item, intPkPackagePriceID: value } : item
    );
    setData(updatedData);
  };
  const columns = [
    { title: "First Name", dataIndex: "firstName", key: "firstName" },
    { title: "Last Name", dataIndex: "lastName", key: "lastName" },
    { title: "Username", dataIndex: "username", key: "username" },
    { title: "Password", dataIndex: "password", key: "password" },
    { title: "Phone Number", dataIndex: "phoneNumber", key: "phoneNumber" },
    { title: "Address", dataIndex: "address", key: "address" },
    { title: "ONT ID", dataIndex: "ontID", key: "ontID" },
    { title: "NIC", dataIndex: "nicNumber", key: "nicNumber" },
    {
      title: "Enable",
      dataIndex: "enable",
      key: "enable",
      render: (text: string) => (text === "1" ? "Enabled" : "Disabled"),
    },
    {
      title: "Expiry Date",
      dataIndex: "expiryDateToDisplay",
      key: "expiryDateToDisplay",
    },
    {
      title: "Select Package",
      key: "intPkPackagePriceID",
      width: "150px",
      render: (record: UsersCsvData) => (
        <MeshBillSelect
          value={record.intPkPackagePriceID}
          onChange={(value) => {
            handlePackageChange(value, record.key);
          }}
          options={packageOptions}
          width="130px"
        />
      ),
    },
    {
      title: "Dealer",
      width: "150px",
      render: (record: UsersCsvData) => (
        <>
          {record.dealer ? (
            record.dealer
          ) : (
            <Tag color="success">Company Owned</Tag>
          )}{" "}
        </>
      ),
    },
    {
      title: "Remaining Balance",
      dataIndex: "remainingBalance",
      key: "remainingBalance",
    },
    {
      title: "Advance Balance",
      dataIndex: "advanceBalance",
      key: "advanceBalance",
    },
    {
      title: "Action",
      key: "action",
      render: (record: UsersCsvData) => (
        <Button
          type="primary"
          danger
          onClick={() => handleUserSkip(record.username)}
        >
          Skip
        </Button>
      ),
    },
  ];
  const validateData = (data: UsersCsvData[]): UsersCsvData[] => {
    // Return users who do not have a selected package
    return data.filter((row) => row.intPkPackagePriceID === "");
  };
  const handleUpload = () => {
    const invalidData = validateData(data);
    console.log({ invalidData, data });
    if (invalidData.length > 0) {
      openNotification(
        `error`,
        "Operation Failed",
        "Please fill in all required fields for each user."
      );
    } else {
      // Proceed with upload
      handleUploadUsers(data);
    }
  };
  const handleUserSkip = (username: string) => {
    const updatedData = data.filter((user) => user.username !== username);
    setData(updatedData);
  };
  return (
    <div className="general-table-container">
      {contextHolder}
      <Row>
        <Col span={24}>
          <Typography className="general-table-heading">Users</Typography>
          <Table
            columns={columns}
            dataSource={data}
            pagination={{ pageSize: 100 }}
          />
        </Col>
        <Col
          span={24}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "20px",
          }}
          className="mt-20 mb-20"
        >
          {data.length > 0 && (
            <MeshBillButton
              htmlType="button"
              backgroundColor={ButtonProps.background.STANDARD}
              height={ButtonProps.height.STANDARD}
              width={ButtonProps.width.STANDARD}
              textColor={ButtonProps.color.STANDARD}
              onClick={handleUpload}
              text="Upload Users"
              disabled={isUploading}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};
export default ImportUsersThroughFileTable;
