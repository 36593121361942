import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ViewDealerPackagesComponent from "../components/packages/ViewDealerPackages";
// const ViewDealerPackagesComponent = lazy(
//   () => import("../components/packages/ViewDealerPackages")
// );
const ViewPackagesPageForDealer = () => {
  const { setTitle } = useContext(TitleContext);
  useEffect(() => {
    setTitle("Packages");
  }, [setTitle]);
  return (
    <>
      <ViewDealerPackagesComponent />
    </>
  );
};
export default ViewPackagesPageForDealer;
