import React, { useEffect, useState } from "react";
import { Col, Dropdown, Row, Table, Tag, Typography, Input } from "antd";
import type { MenuProps, TableProps } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import type { SearchProps } from "antd/es/input/Search";

const { Search } = Input;

import "./../../styles/tables.css";

import { useNavigate } from "react-router-dom";
import {
  formatDate,
  formatDateForJustMonthDayAndYear,
} from "../../helpers/date";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";
import MeshBillPagination from "../Items/Pagination/MeshbillPagination";
function getStatusSpan(inputDate: Date): JSX.Element {
  const currentDate = new Date();

  // Normalize both dates to remove the time portion
  const currentDateOnly = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );
  const inputDateOnly = new Date(
    inputDate.getFullYear(),
    inputDate.getMonth(),
    inputDate.getDate()
  );

  const timeDifference = inputDateOnly.getTime() - currentDateOnly.getTime();
  const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
  if (daysDifference < 0) {
    return <Tag color="error">Expired</Tag>;
  } else if (daysDifference <= 7) {
    return <Tag color="warning">Expiring</Tag>;
  } else {
    return <Tag color="success">Active</Tag>;
  }
}
const ViewPPPoEUsersTable: React.FC<PPPoEUsersTable> = ({
  users,
  isLoading,
  pageTitle,
  pagination,
  handlePageSwitch,
  handleSearch,
  isOfflineView = false,
}) => {
  const [activeRow, setActiveRow] = useState<EndUser | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const navigate = useNavigate();

  const calculateDiscount = (row: EndUser) => {
    const { fixedDiscountAmount, onceDiscountAmount, onceDiscountExpiry } = row;
    let discount = 0;
    if (fixedDiscountAmount) {
      discount = fixedDiscountAmount;
    }
    if (
      onceDiscountAmount &&
      onceDiscountExpiry &&
      new Date(onceDiscountExpiry) > new Date()
    ) {
      discount = onceDiscountAmount;
    }
    return discount;
  };
  const columns: TableProps<EndUser>["columns"] = [
    {
      title: "Name",
      render: (row) => (
        <span>
          {" "}
          <span
            className={
              Number(row.isUserOnline) === 1 ? "online-user" : "offline-user"
            }
          ></span>{" "}
          {`${row.firstName} ${row.lastName}`}
        </span>
      ),
    },
    {
      title: "UserName",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Package",
      dataIndex: "packageName",
      key: "packageName",
    },
    {
      title: "Sale Price",
      dataIndex: "salePrice",
      key: "salePrice",
    },
    {
      title: "Discount",
      render: (row) => (
        <span className="balance-minus-label">{calculateDiscount(row)}</span>
      ),
    },
    // {
    //   title: "Address",
    //   dataIndex: "address",
    //   key: "address",
    // },
    {
      title: "Expiry",
      dataIndex: "expiryDate",
      render: (text) => (
        <span>{formatDateForJustMonthDayAndYear(new Date(text))}</span>
      ),
    },
    {
      title: "Open Invoices",
      dataIndex: "open_invoice_count",
      render: (text) => <>{text}</>,
    },
    {
      title: "Status",
      dataIndex: "expiryDate",
      render: (text) => <>{getStatusSpan(new Date(text))}</>,
    },
  ];
  if (isOfflineView) {
    columns.push({
      title: "Offline Time",
      dataIndex: "lastLogoutTime",
      render: (text) => <span>{text ? formatDate(new Date(text)) : ""}</span>,
    });
  }
  const onSearch: SearchProps["onSearch"] = (value, _e, info) => {
    console.log(info?.source, value);
    if (value.length < 3) {
      return;
    }
    handleSearch(value);
  };

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      handleSearch(searchTerm);
    }, 500);

    return () => clearTimeout(delaySearch); // Cleanup the timeout on component unmount
  }, [searchTerm]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="general-table-container">
      {isLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row>
          <Col span={24}>
            <div className="users-search-container">
              <Typography className="general-table-heading">
                {pageTitle}
              </Typography>
              <Search
                placeholder="Name / Username / phone / Address"
                onSearch={onSearch}
                value={searchTerm}
                allowClear
                onChange={handleChange}
                style={{ width: 300 }}
              />
            </div>
            <Table
              className="striped-table"
              columns={columns}
              dataSource={users}
              onRow={(record, rowIndex) => {
                const fullName = record.firstName + " " + record.lastName;
                return {
                  onClick: (event) => {
                    navigate(
                      `/pppoe-user-details/${record.intPkEndUserID}?activeTab=1&fullName=${fullName}`
                    );
                  }, // click row
                };
              }}
              pagination={false}
            />
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
              marginBottom: "20px",
            }}
            span={24}
          >
            <MeshBillPagination
              pagination={pagination}
              handlePageSwitch={handlePageSwitch}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};
export default ViewPPPoEUsersTable;
