import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import TopActivePackagesStats from "./TopActivePackagesStats";
import UserCreationStats from "./UserCreationStats";
import BilledAndPaymentsStats from "./BilledAndPaymentsStats";
import AverageRevenuePerUserStats from "./AverageRevenuePerUserStats";

const DashboardStatsWrapper = () => {
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <TopActivePackagesStats />
        </Col>
        <Col span={24}>
          <UserCreationStats />
        </Col>
        <Col span={24}>
          <BilledAndPaymentsStats />
        </Col>
        <Col span={24}>
          <AverageRevenuePerUserStats />
        </Col>
      </Row>
    </>
  );
};
export default DashboardStatsWrapper;
