import React, { useEffect, useState } from "react";
import { Col, Dropdown, Row, Table, Tag, Typography } from "antd";
import type { MenuProps, TableProps } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import "./../../styles/tables.css";

import { useNavigate } from "react-router-dom";
import { formatDate } from "../../helpers/date";
import {
  formatPackagesLogs,
  getAuditLogShortTitle,
} from "../../helpers/constants";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";
import MeshBillPagination from "../Items/Pagination/MeshbillPagination";

// const TailSpinLoader = lazy(() => import("../Items/Loaders/TailSpinLoader"));
// const MeshBillPagination = lazy(
//   () => import("../Items/Pagination/MeshbillPagination")
// );
const ViewPackagesAuditLogsTable: React.FC<StaffAuditLogsTableProp> = ({
  logs,
  isLoading,
  pagination,
  handlePageSwitch,
}) => {
  const [formattedLogs, setFormattedLogs] = useState<any[]>([]);
  const [activeRow, setActiveRow] = useState<any | null>(null);
  const navigate = useNavigate();
  useEffect(() => {
    const formattedLogs = formatPackagesLogs(logs);
    setFormattedLogs(formattedLogs);
  }, [logs]);
  const columns: TableProps<any>["columns"] = [
    {
      title: "Type",
      render: (row) => (
        <strong>{`${getAuditLogShortTitle(row.actionType)}`}</strong>
      ),
    },
    {
      title: "Package Name",
      render: (row) => <span>{`${row?.packageDetails?.packageName}`}</span>,
    },
    {
      title: "Pool",
      render: (row) => <span>{`${row?.packageDetails?.poolName}`}</span>,
    },
    {
      title: "Profile",
      render: (row) => <span>{`${row?.packageDetails?.profileName}`}</span>,
    },
    {
      title: "Speed",
      render: (row) => (
        <span>
          {row?.packageDetails?.speedUp &&
          row?.packageDetails?.speedDown &&
          row?.packageDetails?.speedAbbreviation
            ? `${row?.packageDetails?.speedUp}/${row?.packageDetails?.speedDown} ${row?.packageDetails?.speedAbbreviation}`
            : "-"}
        </span>
      ),
    },
    {
      title: "Variable Recharge?",
      render: (row) => (
        <span>
          {Number(row?.packageDetails?.isVariableRechargeAllowed) === 1
            ? `YES`
            : "NO"}
        </span>
      ),
    },
    {
      title: "Price Updated?",
      render: (row) => (
        <span>
          {row?.isPriceUpdated === true
            ? row?.packageDetails?.salePrice
            : "---"}
        </span>
      ),
    },
    {
      title: "Action By",
      render: (row) => (
        <span>{`${
          row?.updatedBy?.firstName + " " + row?.updatedBy?.lastName
        }`}</span>
      ),
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      render: (text) => <span>{formatDate(new Date(text))}</span>,
    },
  ];

  return (
    <div className="general-table-container">
      {isLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row>
          <Col span={24}>
            <Typography className="general-table-heading">
              Packages Audit Logs
            </Typography>
            <Table
              className="striped-table"
              columns={columns}
              dataSource={formattedLogs}
              onRow={(record, rowIndex) => {
                return {};
              }}
              pagination={false}
            />
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
              marginBottom: "20px",
            }}
            span={24}
          >
            <MeshBillPagination
              pagination={pagination}
              handlePageSwitch={handlePageSwitch}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};
export default ViewPackagesAuditLogsTable;
