import React, { useEffect, useState } from "react";

import { FaRegMoneyBillAlt } from "react-icons/fa";

import useAxiosPost from "../../../hooks/useAxiosPost";
import SingleCard from "./SingleCard";
import { Col } from "antd";

const CashRecoveryCard = ({}) => {
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>(
    "reports/dashboard/calculateTheCashRecoveryByOwnerWithingWeek"
  );
  const [threeDays, setThreeDays] = useState<string>("0");
  const [fiveDays, setFiveDays] = useState<string>("0");
  const [sevenDays, setSevenDays] = useState<string>("0");
  useEffect(() => {
    postApiData({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      const {
        expected_recovery_in_3_days = "0",
        expected_recovery_in_5_days = "0",
        expected_recovery_in_7_days = "0",
      } = data;
      setThreeDays(expected_recovery_in_3_days);
      setFiveDays(expected_recovery_in_5_days);
      setSevenDays(expected_recovery_in_7_days);
    }
  }, [apiResponse]);
  return (
    <>
      <Col md={6} lg={4} xl={4} xxl={4} sm={24} xs={24}>
        <SingleCard
          label="Recovery in 3 Days"
          value={threeDays.toLocaleString()}
          CardIcon={FaRegMoneyBillAlt}
          iconColor="#28a745"
          isLoading={apiLoading}
        />
      </Col>
      <Col md={6} lg={4} xl={4} xxl={4} sm={24} xs={24}>
        <SingleCard
          label="Recovery in 5 Days"
          value={fiveDays.toLocaleString()}
          CardIcon={FaRegMoneyBillAlt}
          iconColor="#28a745"
          isLoading={apiLoading}
        />
      </Col>
      <Col md={6} lg={4} xl={4} xxl={4} sm={24} xs={24}>
        <SingleCard
          label="Recovery in 7 Days"
          value={sevenDays.toLocaleString()}
          CardIcon={FaRegMoneyBillAlt}
          iconColor="#28a745"
          isLoading={apiLoading}
        />
      </Col>
    </>
  );
};

export default CashRecoveryCard;
