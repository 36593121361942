import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input } from "antd";
import { ButtonProps } from "../../helpers/constants";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import "./../../styles/modal.css";
import MeshBillModal from "../Items/Modal/Index";
import MeshBillSelect from "../Items/Select/Index";
import MeshBillButton from "../Items/Button/Index";

const AssignNewPackageToDealerModalComponent: React.FC<
  AssignPackageToDealerModalProps
> = ({ show, handleClose, intPkHeadID }) => {
  const { openNotification, contextHolder } = useMultiNotification();
  const [form] = Form.useForm();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<AssignPackageToDealerApiProps>(
    "dealers/assignPackageToDealer",
    true
  );

  const [getPackagesApi, packagesApiResponse] =
    useAxiosPost<SingleDealerProfileApiProp>(
      "dealers/getPackagesToAssignToDealer"
    );
  const [packages, setPackages] = useState<MeshBillSelectOptions[]>([]);

  const onFinish = (values: any) => {
    const { packagePrice, intPkPackageID } = values;

    const dataToSend: AssignPackageToDealerApiProps = {
      packagePrice: Number(packagePrice),
      intPkHeadID: intPkHeadID,
      intPkPackageID,
    };
    postApiData(dataToSend);
  };
  useEffect(() => {
    if (apiResponse !== null) {
      const { message } = apiResponse;
      if (message) {
        openNotification("success", "Success", message);
        setTimeout(() => {
          handleClose(true);
        }, 2500);
      }
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification("error", "Error", apiError);
    }
  }, [apiError, apiErrorCount]);
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  useEffect(() => {
    getPackagesApi({ intPkHeadID: intPkHeadID });
  }, []);
  useEffect(() => {
    if (packagesApiResponse !== null) {
      const data: any = packagesApiResponse.responseData;
      const packages: MeshBillSelectOptions[] = data.map(
        (item: any): MeshBillSelectOptions => {
          return {
            value: item.intPkPackageID,
            label: item.packageName,
          };
        }
      );
      setPackages(packages);
    }
  }, [packagesApiResponse]);

  return (
    <>
      <MeshBillModal
        title="Assign New Package To Dealer"
        headerStyle={{
          backgroundColor: "var(--primary-background-color)",
          color: "var(--primary-text-color)",
          fontSize: "16px",
          fontWeight: "var(--fw-700)",
          height: "50px",
        }}
        open={show}
        onCancel={() => {
          if (apiLoading) {
            return;
          }
          handleClose(false);
        }}
      >
        {contextHolder}

        <Row>
          <Col
            span={24}
            className="meshbill-create-record-modal-form-container"
          >
            <Form
              //   requiredMark={false}
              style={{ width: "100%", marginTop: "50px" }}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              name="topUpDealerForm"
              form={form}
            >
              <Form.Item
                label={
                  <span className="create-user-form-label">Choose Package</span>
                }
                name="intPkPackageID"
                rules={[
                  {
                    required: true,
                    message: "Please Choose A Package",
                  },
                ]}
              >
                <MeshBillSelect size="large" height="45px" options={packages} />
              </Form.Item>
              <Form.Item
                label={
                  <span className="create-user-form-label">Package Price</span>
                }
                name="packagePrice"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Package Price",
                  },
                  {
                    validator: (_, value) =>
                      value > 0
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error("Package Price must be greater than 0")
                          ),
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="100"
                />
              </Form.Item>

              <Form.Item>
                <MeshBillButton
                  backgroundColor={ButtonProps.background.STANDARD}
                  height={ButtonProps.height.STANDARD}
                  width={`100%`}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {}}
                  htmlType="submit"
                  text="Submit"
                  disabled={apiLoading}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </MeshBillModal>
    </>
  );
};
export default AssignNewPackageToDealerModalComponent;
