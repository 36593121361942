import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const TopActivePackagesChart: React.FC<TopActivePackagesChartProp> = ({
  data,
}) => {
  const categories = data.map((item) => item.packageName);
  const seriesData = data.map((item) => item.total_users);
  const options: ApexOptions = {
    series: [
      {
        name: "Total Users",
        data: seriesData,
      },
    ],
    chart: {
      height: 350,
      type: "bar", // Change from "line" to "bar" for column chart
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false, // Set to true if you want a horizontal bar chart
        columnWidth: "55%", // Adjust the width of the columns
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // alternating row colors
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: categories,
      labels: {
        rotate: -75, // Rotate the labels to display them vertically
        rotateAlways: true, // Ensure rotation is applied
        style: {
          fontSize: "12px",
        },
      },
    },
  };

  return (
    <div>
      <ReactApexChart
        options={options}
        series={options.series}
        type="bar"
        height={400}
      />
    </div>
  );
};

export default TopActivePackagesChart;
