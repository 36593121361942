import React, { useEffect, useState } from "react";

import { MdSignalWifiStatusbarConnectedNoInternet3 } from "react-icons/md";

import useAxiosPost from "../../../hooks/useAxiosPost";
import SingleCard from "./SingleCard";

const OfflineUsersCard = ({}) => {
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>(
    "reports/dashboard/getOfflineUsersCountByOwner"
  );
  const [value, setValue] = useState<string>("0");
  useEffect(() => {
    postApiData({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      const totalOfflineEndUsers = data.totalOfflineEndUsers;
      console.log({ totalOfflineEndUsers });
      setValue(totalOfflineEndUsers);
    }
  }, [apiResponse]);
  return (
    <SingleCard
      label="Offline Users"
      value={value}
      CardIcon={MdSignalWifiStatusbarConnectedNoInternet3}
      iconColor="darkred"
      isLoading={apiLoading}
      isLink
      route="/pppoe-users?activeTab=offline"
    />
  );
};

export default OfflineUsersCard;
