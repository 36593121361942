import { useContext, useEffect, lazy } from "react";
import TitleContext from "../context/TitleContext";
import CompanyRetailsSalesChartsComponent from "../components/Reports/Retails/Index";

const CompanyRetailsSalesChartsPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Retails Sale Report");
  }, [setTitle]);
  return (
    <>
      <CompanyRetailsSalesChartsComponent />
    </>
  );
};
export default CompanyRetailsSalesChartsPage;
