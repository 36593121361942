import React, { useState } from "react";
import { Col, Dropdown, Row, Table, Tag, Typography } from "antd";
import humanizeDuration from "humanize-duration";
import type { MenuProps, TableProps } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import "./../../styles/tables.css";

import { useNavigate } from "react-router-dom";
import { formatDate } from "../../helpers/date";
import { convertBytesToKMGBits } from "../../helpers/unitConvertor";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";
import MeshBillPagination from "../Items/Pagination/MeshbillPagination";

// const TailSpinLoader = lazy(() => import("../Items/Loaders/TailSpinLoader"));
// const MeshBillPagination = lazy(
//   () => import("../Items/Pagination/MeshbillPagination")
// );
const ViewEndUserAAALogsTable: React.FC<EndUserAAALogsTableProp> = ({
  logs,
  isLoading,
  pagination,
  handlePageSwitch,
}) => {
  const navigate = useNavigate();
  const columns: TableProps<EndUserAAALog>["columns"] = [
    {
      title: "Session ID",
      render: (row) => <span>{`${row.acctSessionID}`}</span>,
    },
    {
      title: "MAC Address",
      render: (row) => <span>{`${row.cpeMAC}`}</span>,
    },
    {
      title: "IP  Address",
      render: (row) => (
        <span>{`${row.cpeIPAddress ? row.cpeIPAddress : "-"}`}</span>
      ),
    },
    {
      title: "Up Time",
      render: (row) => (
        <span>{`${
          row.sessionTotalUptimeSeconds
            ? humanizeDuration(row.sessionTotalUptimeSeconds * 1000)
            : "-"
        }`}</span>
      ),
    },
    {
      title: "Router",
      render: (row) => <span>{`${row.nasDeviceName}`}</span>,
    },
    {
      title: "Vlan",
      render: (row) => (
        <span>{`${
          row.routerInterfaceID ? row.routerInterfaceID : "---"
        }`}</span>
      ),
    },
    {
      title: "Uploaded",
      render: (row) => (
        <span>{`${
          row.sessionTotalUploadedBytes
            ? convertBytesToKMGBits(Number(row.sessionTotalUploadedBytes)) +
              "Mbs"
            : "---"
        }`}</span>
      ),
    },
    {
      title: "Downloaded",
      render: (row) => (
        <span>{`${
          row.sessionTotalDownloadedBytes
            ? convertBytesToKMGBits(Number(row.sessionTotalDownloadedBytes)) +
              "Mbs"
            : "---"
        }`}</span>
      ),
    },
    {
      title: "Session Started",
      render: (row) => (
        <span>
          {row.sessionStartedAt
            ? formatDate(new Date(row.sessionStartedAt))
            : "---"}
        </span>
      ),
    },
    {
      title: "Session Stopped",
      render: (row) => (
        <span>
          {row.sessionStoppedAt
            ? formatDate(new Date(row.sessionStoppedAt))
            : "---"}
        </span>
      ),
    },
  ];

  return (
    <div className="general-table-container">
      {isLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row>
          <Col span={24}>
            <Typography className="general-table-heading">
              User AAA Logs
            </Typography>
            <Table
              className=" striped-table"
              columns={columns}
              dataSource={logs}
              onRow={(record, rowIndex) => {
                return {};
              }}
              pagination={false}
            />
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
              marginBottom: "20px",
            }}
            span={24}
          >
            <MeshBillPagination
              pagination={pagination}
              handlePageSwitch={handlePageSwitch}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};
export default ViewEndUserAAALogsTable;
