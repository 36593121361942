import { Col, Row, Typography } from "antd";
import React from "react";
import "./../../../styles/tabs.css";
const MeshBillTabs: React.FC<MeshBillTabsComponentProp> = ({
  colSpan,
  tabs,
  handleTabSwitch,
}) => {
  return (
    <div className="meshbill-tabs-main">
      <Row className="meshbill-tabs-container">
        {tabs.map((item: MeshBillTabItemsProp) => {
          return item.isActive === true ? (
            <Col
              onClick={() => {
                handleTabSwitch(item.id);
              }}
              key={Math.random()}
              className="meshbill-tab-active-single"
              span={colSpan}
            >
              <Typography className="meshbill-tab-text">{item.text}</Typography>
            </Col>
          ) : (
            <Col
              key={Math.random()}
              className="meshbill-tab-single"
              span={colSpan}
              onClick={() => {
                handleTabSwitch(item.id);
              }}
            >
              <Typography className="meshbill-tab-text">{item.text}</Typography>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};
export default MeshBillTabs;
