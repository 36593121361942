import React from "react";
import "./styles.css";

const PolicyPage = () => {
  return (
    <div className="policy-page">
      <header className="privacy-policy-main-heading">
        <h1>MeshBill Privacy Policy</h1>
      </header>
      <section className="policy-section">
        <h2>Introduction</h2>
        <p>
          This Privacy Policy and Terms of Use ("Policy") apply to your access
          and use of the Net-Wala mobile application developed . This Policy
          describes the information we collect, use, and disclose in connection
          with your use of the App, and your rights associated with that
          information.
        </p>
      </section>
      <section className="policy-section">
        <h2>Information We Collect</h2>
        <p>
          Personal Information: This includes information that can be used to
          identify you, such as your name, email address, phone number, and
          username.
        </p>
      </section>
      <section className="policy-section">
        <h2>How We Use Your Information</h2>
        <p>To process your requests for payment recovery.</p>
        <p>To communicate with you about your use of the App.</p>
      </section>
      <section className="policy-section">
        <h2>Sharing Your Information</h2>
        <p>We don't share your information with the any third party</p>
      </section>

      <section className="policy-section">
        <h2>Data Security</h2>
        <p>
          We take reasonable steps to protect the information you provide to us
          from unauthorized access, disclosure, alteration, or destruction.
          However, no security measures are perfect, and we cannot guarantee the
          absolute security of your information.
        </p>
      </section>

      <section className="policy-section">
        <h2>Messaging Services</h2>
        <p>SMS send through your device's messaging service.</p>
        <p>SEND SMS: permission required to Send SMS.</p>
      </section>

      <section className="policy-section">
        <h2>Changes to This Policy</h2>
        <p>
          We may update this Policy from time to time. We will notify you of any
          changes by posting the new Policy on the App. You are advised to
          review this Policy periodically for any changes.
        </p>
      </section>
      <section className="policy-section">
        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Policy, please contact us at
          info@meshbill.app
        </p>
      </section>
      <footer className="privacy-policy-footer">
        <p>Last Updated: March 1, 2024</p>
      </footer>
    </div>
  );
};

export default PolicyPage;
