import { useEffect, useState } from "react";
import useAxiosPost from "../../hooks/useAxiosPost";
import { Col, Row } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import FailedAuthLogsTable from "../Tables/FailedAuthLogsTable";

// const MeshBillSelect = lazy(() => import("../Items/Select/Index"));
// const FailedAuthLogsTable = lazy(() => import("../Tables/FailedAuthLogsTable"));
const ViewFailedAuthLogsComponent = () => {
  const navigate = useNavigate();
  const [logs, setLogs] = useState<FailedAuthLog[]>([]);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("logs/getEndUsersFailedAuthLogs");
  useEffect(() => {
    postApiData({});
  }, []);
  const [pagination, setPagination] = useState<PaginationProp>({
    totalPages: 0,
    currentPage: 1,
    previousPage: null,
    nextPage: null,
    totalCount: 0,
    pageSize: 50,
  });

  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      console.log({ data });
      const {
        currentPage,
        nextPage,
        pageSize,
        previousPage,
        totalCount,
        totalPages,
        logs,
      } = data;
      setPagination({
        currentPage,
        totalPages,
        nextPage,
        previousPage,
        totalCount,
        pageSize,
      });
      setLogs(logs || []);
      console.log({ logs });
    }
  }, [apiResponse]);
  const handlePageSwitch = (pageNumber: number) => {
    postApiData({ page: pageNumber });
  };
  return (
    <>
      <Row className="full-width-row">
        <Col span={24}>
          <FailedAuthLogsTable
            isLoading={apiLoading}
            logs={logs}
            handlePageSwitch={handlePageSwitch}
            pagination={pagination}
          />
        </Col>
      </Row>
    </>
  );
};
export default ViewFailedAuthLogsComponent;
