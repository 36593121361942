import { useEffect, useState } from "react";
import { Col, Row } from "antd";

import { useNavigate } from "react-router-dom";
import useAxiosPost from "../../hooks/useAxiosPost";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";
import PendingVouchersTable from "../Tables/PendingVouchersTable";
// const PendingVouchersTable = lazy(
//   () => import("../Tables/PendingVouchersTable")
// );

const PendingVouchersComponent = () => {
  const navigate = useNavigate();
  const [pendingVouchers, setPendingVouchers] = useState<
    PendingDiscountVoucherProp[]
  >([]);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("discountVouchers/getPendingVouchersByOwner");

  useEffect(() => {
    postApiData({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      console.log({ data });
      setPendingVouchers(data || []);
    }
  }, [apiResponse]);

  return (
    <>
      {apiLoading === true ? (
        <TailSpinLoader />
      ) : (
        <>
          <Row gutter={[16, 16]}>
            <PendingVouchersTable
              vouchers={pendingVouchers}
              isLoading={apiLoading}
            />
          </Row>
        </>
      )}
    </>
  );
};
export default PendingVouchersComponent;
