import { useContext, useEffect, lazy } from "react";
import TitleContext from "../context/TitleContext";
import ViewRouterDetailsComponent from "../components/companies/ViewCompanyRouterDetails";
// const ViewCompanyRouterDetails = lazy(
//   () => import("../components/companies/ViewCompanyRouterDetails")
// );
const ViewNasDetailsPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Router Details");
  }, [setTitle]);
  return (
    <>
      <ViewRouterDetailsComponent />
    </>
  );
};
export default ViewNasDetailsPage;
