import React, { useEffect, lazy } from "react";
import { Col, Row, Form } from "antd";
import { ButtonProps } from "../../helpers/constants";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import "./../../styles/modal.css";
import MeshBillModal from "../Items/Modal/Index";
import MeshBillButton from "../Items/Button/Index";
// const MeshBillSelect = lazy(() => import("../Items/Select/Index"));
// const MeshBillModal = lazy(() => import("../Items/Modal/Index"));
// const MeshBillButton = lazy(() => import("../Items/Button/Index"));

const UpdateNasStatusModalComponent: React.FC<
  EnableDisableNasDeviceModalProp
> = ({ show, handleClose, data }) => {
  const { openNotification, contextHolder } = useMultiNotification();
  const [form] = Form.useForm();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<UpdateRouterStatusApiProp>(
    "superAdmin/updateNasDeviceEnableDisableStatus",
    true
  );

  const onFinish = (values: any) => {
    const dataToSend: UpdateRouterStatusApiProp = {
      intPkNasDeviceID: data.intPkNasDeviceID,
      enable: Number(data.enable) === 1 ? 0 : 1,
    };
    postApiData(dataToSend);
  };
  useEffect(() => {
    if (apiResponse !== null) {
      const { message } = apiResponse;
      if (message) {
        openNotification("success", "Success", message);
        setTimeout(() => {
          handleClose(true);
        }, 2500);
      }
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification("error", "Error", apiError);
    }
  }, [apiError, apiErrorCount]);
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <MeshBillModal
        title="Update Router Status"
        headerStyle={{
          backgroundColor: "var(--primary-background-color)",
          color: "var(--primary-text-color)",
          fontSize: "16px",
          fontWeight: "var(--fw-700)",
          height: "50px",
        }}
        open={show}
        onCancel={() => {
          if (apiLoading) {
            return;
          }
          handleClose(false);
        }}
      >
        {contextHolder}

        <Row>
          <Col
            span={24}
            className="meshbill-create-record-modal-form-container"
          >
            <Form
              //   requiredMark={false}
              style={{ width: "100%", marginTop: "50px" }}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              name="updateStaffStatusForm"
              form={form}
            >
              <div className="confirmation-message-container">
                <label className="modal-confirmation-message">
                  Do You want to {data.enable === 1 ? "Disable" : "Enable"} this
                  Router?
                </label>
              </div>
              <Form.Item>
                <MeshBillButton
                  backgroundColor={ButtonProps.background.STANDARD}
                  height={ButtonProps.height.STANDARD}
                  width={`100%`}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {}}
                  htmlType="submit"
                  text="Update Status"
                  disabled={apiLoading}
                  // icon={<FiPlusCircle />}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </MeshBillModal>
    </>
  );
};
export default UpdateNasStatusModalComponent;
