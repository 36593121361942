import { useEffect, useState } from "react";
import { Col, Row } from "antd";

import { useNavigate } from "react-router-dom";
import useAxiosPost from "../../hooks/useAxiosPost";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";
import RedeemedVouchersTable from "../Tables/RedeemedVouchersTable";

// const RedeemedVouchersTable = lazy(
//   () => import("../Tables/RedeemedVouchersTable")
// );
const RedeemedVouchersComponent = () => {
  const navigate = useNavigate();
  const [vouchers, setVouchers] = useState<RedeemedDiscountVouchersProp[]>([]);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("discountVouchers/getRedeemedVouchersByOwner");

  useEffect(() => {
    postApiData({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      console.log({ data });
      setVouchers(data || []);
    }
  }, [apiResponse]);

  return (
    <>
      {apiLoading === true ? (
        <TailSpinLoader />
      ) : (
        <>
          <Row gutter={[16, 16]}>
            <RedeemedVouchersTable vouchers={vouchers} isLoading={apiLoading} />
          </Row>
        </>
      )}
    </>
  );
};
export default RedeemedVouchersComponent;
