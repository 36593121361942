import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ViewDiscountVouchersComponent from "../components/discountVouchers/ViewDiscountVouchers";
// const ViewDiscountVouchersComponent = lazy(
//   () => import("../components/discountVouchers/ViewDiscountVouchers")
// );

const DiscountVouchersPage = () => {
  const { setTitle } = useContext(TitleContext);
  useEffect(() => {
    setTitle("Discount Vouchers");
  }, [setTitle]);
  return (
    <>
      <ViewDiscountVouchersComponent />
    </>
  );
};
export default DiscountVouchersPage;
