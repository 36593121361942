import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ViewDealersComponent from "../components/dealers/ViewAllDealers";
// const ViewAllDealersComponent = lazy(
//   () => import("../components/dealers/ViewAllDealers")
// );

const ViewAllDealersPage = () => {
  const { setTitle } = useContext(TitleContext);
  useEffect(() => {
    setTitle("Dealers");
  }, [setTitle]);
  return (
    <>
      <ViewDealersComponent />
    </>
  );
};
export default ViewAllDealersPage;
