import { useContext, useEffect, lazy } from "react";
import TitleContext from "../context/TitleContext";
import LoginComponent from "../components/auth/LoginComponent";
// const LoginComponent = lazy(() => import("../components/auth/LoginComponent"));
const LoginPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Login");
  }, [setTitle]);
  return (
    <>
      <LoginComponent />
    </>
  );
};
export default LoginPage;
