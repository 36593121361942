import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input, Checkbox } from "antd";
import { ButtonProps, getWalletDays } from "../../helpers/constants";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import "./../../styles/modal.css";
import { FiPlusCircle } from "react-icons/fi";
import Typography from "antd/es/typography/Typography";
import CopyToClipboard from "react-copy-to-clipboard";
import { formatDate } from "../../helpers/date";
import { MdContentCopy } from "react-icons/md";
import MeshBillModal from "../Items/Modal/Index";
// const MeshBillSelect = lazy(() => import("../Items/Select/Index"));
// const MeshBillModal = lazy(() => import("../Items/Modal/Index"));
// const MeshBillButton = lazy(() => import("../Items/Button/Index"));

const ViewEndUserDetailsOfDealerLedgerModal: React.FC<
  ViewEndUserDetailsOfDealerLedgerModalProp
> = ({ show, handleClose, report }) => {
  const { openNotification, contextHolder } = useMultiNotification();

  return (
    <>
      <MeshBillModal
        title="User Details"
        headerStyle={{
          backgroundColor: "var(--primary-background-color)",
          color: "var(--primary-text-color)",
          fontSize: "16px",
          fontWeight: "var(--fw-700)",
          height: "50px",
        }}
        open={show}
        onCancel={() => {
          handleClose(false);
        }}
      >
        {contextHolder}

        <Row>
          <Col
            span={24}
            className="meshbill-create-record-modal-form-container"
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "25px",
                marginBottom: "20px",
              }}
            >
              <Typography className="label-for-username-in-a-modal">
                FullName :{" "}
              </Typography>{" "}
              <Typography className="value-for-username-in-a-modal">
                {report.firstName} {report.lastName}
              </Typography>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "25px",
                marginBottom: "20px",
              }}
            >
              <Typography className="label-for-username-in-a-modal">
                UserName :{" "}
              </Typography>{" "}
              <Typography className="value-for-username-in-a-modal">
                {report.username}{" "}
                <CopyToClipboard text={report.username || ""} onCopy={() => {}}>
                  <MdContentCopy
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    className="cursor-pointer "
                  />
                </CopyToClipboard>
              </Typography>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "25px",
                marginBottom: "20px",
              }}
            >
              <Typography className="label-for-username-in-a-modal">
                Password :{" "}
              </Typography>{" "}
              <Typography className="value-for-username-in-a-modal">
                {report.password}

                <CopyToClipboard text={report.password || ""} onCopy={() => {}}>
                  <MdContentCopy
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    className="cursor-pointer "
                  />
                </CopyToClipboard>
              </Typography>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "25px",
                marginBottom: "20px",
              }}
            >
              <Typography className="label-for-username-in-a-modal">
                Address :{" "}
              </Typography>{" "}
              <Typography className="value-for-username-in-a-modal">
                {report.address}
              </Typography>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "25px",
                marginBottom: "20px",
              }}
            >
              <Typography className="label-for-username-in-a-modal">
                Phone :{" "}
              </Typography>{" "}
              <Typography className="value-for-username-in-a-modal">
                {report.phoneNumber}
              </Typography>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "25px",
                marginBottom: "20px",
              }}
            >
              <Typography className="label-for-username-in-a-modal">
                Expiry Date :{" "}
              </Typography>{" "}
              <Typography className="value-for-username-in-a-modal">
                {report.expiryDate
                  ? formatDate(new Date(report.expiryDate))
                  : ""}
              </Typography>
            </div>
          </Col>
        </Row>
      </MeshBillModal>
    </>
  );
};
export default ViewEndUserDetailsOfDealerLedgerModal;
