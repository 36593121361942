import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Form,
  Input,
  DatePicker,
  Typography,
  MenuProps,
  Dropdown,
} from "antd";
import "./../../../styles/staff/profile.css";

import { ButtonProps } from "../../../helpers/constants";
import { FiPlusCircle } from "react-icons/fi";
import moment from "moment";
import dayjs from "dayjs";
import useMultiNotification from "../../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
import useAxiosPost from "../../../hooks/useAxiosPost";
import {
  formatDate,
  formatDateForJustMonthDayAndYear,
} from "../../../helpers/date";
import { MdEdit } from "react-icons/md";
import { MenuOutlined } from "@ant-design/icons";
import TailSpinLoader from "../../Items/Loaders/TailSpinLoader";
import DealerTransactionsTable from "../../Tables/DealerTransactionsTable";
// const TailSpinLoader = lazy(() => import("../../Items/Loaders/TailSpinLoader"));
// const MeshBillSelect = lazy(() => import("../../Items/Select/Index"));
// const MeshBillButton = lazy(() => import("../../Items/Button/Index"));
// const DealerTransactionsTable = lazy(
//   () => import("../../Tables/DealerTransactionsTable")
// );

const ViewDealerTransactions: React.FC<DealerProfileComponent> = ({
  intPkHeadID,
}) => {
  const { openNotification, contextHolder } = useMultiNotification();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<SingleDealerProfileApiProp>(
    "dealers/getMyDealerTransactions"
  );
  const [transactions, setTransactions] = useState<DealerTransaction[]>([]);
  useEffect(() => {
    postApiData({ intPkHeadID });
  }, []);

  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setTransactions(data || []);
    }
  }, [apiResponse]);
  if (apiLoading) {
    return <TailSpinLoader />;
  }

  const handleClose = (isReload: boolean) => {
    if (isReload) {
      postApiData({ intPkHeadID });
    }
  };

  return (
    <>
      {contextHolder}
      <Row className="full-width-row">
        <Col className="mt-20" span={24}>
          <DealerTransactionsTable
            isLoading={apiLoading}
            transactions={transactions}
          />
        </Col>
      </Row>
    </>
  );
};
export default ViewDealerTransactions;
