import React from "react";
import { Col, Row, Table, Typography } from "antd";
import type { TableProps } from "antd";
import "./../../styles/tables.css";

import { useNavigate } from "react-router-dom";
import { formatDate } from "../../helpers/date";
import { getUserType } from "../../helpers/userDetails";

import TailSpinLoader from "../Items/Loaders/TailSpinLoader";

const DealerTransactionsTable: React.FC<DealerTransactionsTable> = ({
  transactions,
  isLoading,
}) => {
  const role = getUserType();

  const navigate = useNavigate();
  const columns: TableProps<DealerTransaction>["columns"] = [
    {
      title: "Type",
      render: (row) => (
        <span> {row.topUpAmount !== 0 ? "Recharge" : "Sale"}</span>
      ),
    },
    {
      title: "Amount",
      render: (row) => (
        <span
          className={`${
            row.topUpAmount !== 0
              ? "balance-available-label"
              : "balance-minus-label"
          }`}
        >
          {" "}
          {row.topUpAmount !== 0 ? "+" : "-"}{" "}
          {row.topUpAmount !== 0 ? row.topUpAmount : row.consumedAmount}
        </span>
      ),
    },
    {
      title: "Transaction Date",
      render: (row) => <span>{formatDate(row.dealerTransactionCreated)}</span>,
    },
  ];

  return (
    <div className="general-table-container">
      {isLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row>
          <Col span={24}>
            <Typography className="general-table-heading">
              Dealer Transactions
            </Typography>
            <Table
              className="striped-table"
              columns={columns}
              dataSource={transactions}
              onRow={(record, rowIndex) => {
                return {};
              }}
              pagination={false}
            />
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
              marginBottom: "20px",
            }}
            span={24}
          ></Col>
        </Row>
      )}
    </div>
  );
};
export default DealerTransactionsTable;
