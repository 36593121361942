import { logout } from "./logout";

export const getUserType = (): string => {
  const sessionType = sessionStorage.getItem("CompanyAdminType") || false;
  if (sessionType) {
    return sessionType;
  }
  const userType = localStorage.getItem("userType") || false;

  if (userType) {
    return userType;
  }
  return "";
};
export const handleLogout = async () => {
  const sessionToken = sessionStorage.getItem("CompanyAdminToken") || false;
  if (sessionToken) {
    sessionStorage.clear();
    await logout(sessionToken);
    return;
  }
  const token = localStorage.getItem("auth_token") || false;
  if (token) {
    const codes = localStorage.getItem("companyCodes");
    localStorage.clear();
    localStorage.setItem("companyCodes", codes || "");

    await logout(token);
    return;
  }
};
// export const getUserAuthToken = (): string => {
//   // const sessionAuthToken = sessionStorage.getItem("CompanyAdminToken") || false;
//   // if (sessionAuthToken) {
//   //   return sessionAuthToken;
//   // }
//   const auth_token = localStorage.getItem("auth_token") || false;

//   if (auth_token) {
//     return auth_token;
//   }
//   return "";
// };
export const getUserAuthenticationToken = (): string => {
  const auth_token = localStorage.getItem("auth_token") || false;
  if (auth_token) {
    return auth_token;
  }
  return "";
};
