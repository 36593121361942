import { useEffect, useState } from "react";
import useAxiosPost from "../../hooks/useAxiosPost";
import { Col, Row } from "antd";
import { ButtonProps } from "../../helpers/constants";
import { IoMdPersonAdd } from "react-icons/io";
import UpdateStaffPasswordModalComponent from "../Modals/UpdateStaffPasswordModal";
import MeshBillButton from "../Items/Button/Index";
import ViewStaffTable from "../Tables/ViewStaffTable";
import CreateStaffModalComponent from "../Modals/CreateNewStaffModal";
import UpdateStaffDetailsModalComponent from "../Modals/UpdateStaffDetailsModal";

// const ViewStaffTable = lazy(() => import("../Tables/ViewStaffTable"));
// const MeshBillButton = lazy(() => import("../Items/Button/Index"));
// const CreateNewStaffModalComponent = lazy(
//   () => import("../Modals/CreateNewStaffModal")
// );
// const UpdateStaffDetailsModalComponent = lazy(
//   () => import("../Modals/UpdateStaffDetailsModal")
// );
// const UpdateStaffPasswordModalComponent = lazy(
//   () => import("../Modals/UpdateStaffPasswordModal")
// );

const ViewStaffComponent = () => {
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("auth/getMyStaff");
  const [staff, setStaff] = useState<StaffListType[]>([]);
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [showUpdate, setShowUpdate] = useState<boolean>(false);
  const [showPasswordUpdate, setShowPasswordUpdate] = useState<boolean>(false);
  const [activeRow, setActiveRow] = useState<StaffListType | null>(null);

  useEffect(() => {
    postApiData({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      console.log({ data });
      setStaff(data || []);
    }
  }, [apiResponse]);
  const handleClose = (isReload: boolean) => {
    setShowCreate(false);
    setShowUpdate(false);
    setShowPasswordUpdate(false);
    setActiveRow(null);
    if (isReload) {
      postApiData({});
    }
  };
  const handleStaffProfileUpdate = (activeStaff: StaffListType) => {
    setActiveRow(activeStaff);
    setShowUpdate(true);
  };
  const handleStaffPasswordUpdate = (activeStaff: StaffListType) => {
    setActiveRow(activeStaff);
    setShowPasswordUpdate(true);
  };
  return (
    <>
      {showCreate === true && (
        <CreateStaffModalComponent
          show={showCreate}
          handleClose={handleClose}
        />
      )}
      {showUpdate === true && activeRow !== null && (
        <UpdateStaffDetailsModalComponent
          activeStaff={activeRow}
          show={showUpdate}
          handleClose={handleClose}
          apiUrl="updateMyStaffProfile"
        />
      )}
      {showPasswordUpdate === true && activeRow !== null && (
        <UpdateStaffPasswordModalComponent
          activeStaff={activeRow}
          show={showPasswordUpdate}
          handleClose={handleClose}
          apiUrl={null}
        />
      )}
      <Row className="full-width-row">
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
          }}
          span={24}
        >
          <MeshBillButton
            backgroundColor={ButtonProps.background.STANDARD}
            height={ButtonProps.height.STANDARD}
            width={ButtonProps.width.STANDARD}
            textColor={ButtonProps.color.STANDARD}
            onClick={() => {
              setShowCreate(true);
            }}
            text="Create Staff"
            icon={<IoMdPersonAdd />}
          />
        </Col>
        <Col span={24}>
          <ViewStaffTable
            handleStaffProfileUpdate={handleStaffProfileUpdate}
            handleStaffPasswordUpdate={handleStaffPasswordUpdate}
            isLoading={apiLoading}
            staff={staff}
          />
        </Col>
      </Row>
    </>
  );
};
export default ViewStaffComponent;
